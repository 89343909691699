import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import Textarea from '@/components/Textarea/Textarea'
import useExternalErrors from '@/hooks/useExternalErrors'
import type { FormError } from '@/types/form-error'

import styles from './TopicAndNotesForm.module.scss'
import {
  type TopicAndNotesFormPayload,
  topicAndNotesFormSchema
} from '../constants/lesson-payload'

type TopicAndNotesFormProps = {
  topic?: string
  notes?: string
  errors?: FormError<TopicAndNotesFormPayload>[]
  loading?: boolean
  onSubmit?: (payload: TopicAndNotesFormPayload) => void
  onCancel?: () => void
}
const TopicAndNotesForm = (props: TopicAndNotesFormProps) => {
  const { t } = useTranslation(['lessonDetails'])
  const form = useForm<TopicAndNotesFormPayload>({
    resolver: zodResolver(topicAndNotesFormSchema()),
    mode: 'all',
    defaultValues: {
      topic: props.topic || '',
      notes: props.notes || ''
    }
  })

  useExternalErrors(props.errors, form)

  return (
    <div className={styles.card}>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={form.handleSubmit(value => props?.onSubmit?.(value))}
          className={styles.form}
        >
          <FormField
            control={form.control}
            id="topic"
            label={t('label.topic')}
            name="topic"
            render={({ inputProps }) => (
              <Input
                placeholder={t('help.type-lesson-topic')}
                {...inputProps}
              />
            )}
          />
          <FormField
            control={form.control}
            id="notes"
            label={t('label.notes')}
            name="notes"
            render={({ inputProps }) => (
              <Textarea
                placeholder={t('help.type-lesson-notes')}
                numberOfLines={4}
                {...inputProps}
              />
            )}
          />

          <div className={styles.buttonWrapper}>
            <Button
              variant="tertiary"
              className={styles.button}
              onClick={() => {
                form.reset()
                props.onCancel?.()
              }}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type="submit"
              className={styles.button}
              loading={props.loading}
              disabled={props.loading}
            >
              {t('button.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default TopicAndNotesForm
