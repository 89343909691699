import { z } from 'zod'

import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { attendanceStatus, requiredString } from '@/utils/zod'

export const updateAbsenceSchema = () =>
  z.object({
    attachments: z.array(z.string()),
    comment: z.string().optional(),
    attendances: z.array(
      z.object({
        id: requiredString(),
        presence: attendanceStatus
      })
    )
  })

export type UpdateAbsence = z.infer<ReturnType<typeof updateAbsenceSchema>>

const useUpdateAbsence = ({
  onSuccess
}: {
  onSuccess: (payload: UpdateAbsence) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string; payload: UpdateAbsence }) =>
      httpService.panel.panelAbsencesUpdate(data.id, data.payload),
    onSuccess: (_, data) => onSuccess?.(data.payload)
  })

export default useUpdateAbsence
