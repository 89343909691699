import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

export const coursesFiltersSchema = z.object({
  ids: z.array(z.string()).optional(),
  teacherOrCoTeacherId: z.array(z.string()).optional(),
  studentsId: z.array(z.string()).optional(),
  classesId: z.array(z.string()).optional(),
  semester: z.string().optional(),
  coursesId: z.array(z.string()).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type CoursesFiltersKey = keyof z.infer<typeof coursesFiltersSchema>
