import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

import type { Group } from '../types'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    course: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    teacher: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    co_teacher_1: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    lesson_count: z.number(),
    students_count: z.number(),
    student_classes: z.array(
      z.object({
        id: requiredString(),
        name: requiredString()
      })
    )
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>): Group[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    course: {
      id: item.course.id,
      name: item.course.name
    },
    teacher: {
      id: item.teacher.id,
      name: item.teacher.name
    },
    coTeacher: item.co_teacher_1
      ? {
          id: item.co_teacher_1.id,
          name: item.co_teacher_1.name
        }
      : undefined,
    studentClasses: item.student_classes,
    lessonCount: item.lesson_count,
    studentsCount: item.students_count
  }))

type Params = {
  ids?: string[]
  studentsId?: string[]
  classesId?: string[]
  coursesId?: string[]
  semester?: string
  teacherOrCoTeacherId?: string[]
  tutorId?: string
  pageSize?: number | 'max'
  page?: number
}

const useCoursesGroups = (params: Params) =>
  useQuery({
    queryKey: ['coursesGroups', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelLessonsGroupsList({
        id: params.ids,
        student_id: params.studentsId,
        class_id: params.classesId,
        course_id: params.coursesId,
        semester_id: params.semester,
        teacher_or_co_teacher_id: params.teacherOrCoTeacherId,
        tutor_id: params.tutorId,
        ordering: 'course__name,name',
        page: params.page,
        page_size: params.pageSize,
        fetchKeys: {
          id: true,
          name: true,
          course: true,
          teacher: true,
          co_teacher_1: true,
          student_classes: true,
          students_count: true,
          lesson_count: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))

      return {
        list: parsed,
        count: response.count
      }
    },
    enabled: !!params.semester
  })

export default useCoursesGroups
