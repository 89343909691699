import { createFileRoute } from '@tanstack/react-router'

import { StudentsView, studentsFiltersSchema } from '@/modules/students'
import { StudentsAndClassesView } from '@/modules/studentsAndClasses'

export const Route = createFileRoute('/_auth/students-and-classes/students/')({
  validateSearch: studentsFiltersSchema.parse,
  component: () => (
    <StudentsAndClassesView>
      <StudentsView />
    </StudentsAndClassesView>
  )
})
