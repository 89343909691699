import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import LeftIcon from '@/assets/icons/chevron-small-left.svg?react'
import RightIcon from '@/assets/icons/chevron-small-right.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './Pagination.module.scss'

type PaginationProps = {
  hideBackToTopButton?: boolean
  count: number
  pageSize: number
  id: string
  page: number
  onPageChange?: (page: number) => void
}

const Pagination = (props: PaginationProps) => {
  const { t } = useTranslation(['common'])
  const { isMobile } = useScreenResolution()
  const totalPages = Math.ceil(props.count / props.pageSize) || 1

  const handlePageChange = (page: number) => {
    props.onPageChange?.(page)
  }

  if (totalPages === 1) return null

  return (
    <nav aria-label={t('label.page-navigation')} className={styles.pagination}>
      <ul>
        <li
          className={clsx(styles.pageItem, props.page === 1 && styles.disabled)}
        >
          <ButtonIcon
            variant="tertiary"
            size="small"
            onClick={() => handlePageChange(props.page - 1)}
            ariaLabel={t('label.previous-page')}
            disabled={props.page === 1}
            className={styles.pageItemLink}
          >
            <LeftIcon />
          </ButtonIcon>
        </li>

        {!isMobile ? (
          <PageItems
            page={props.page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        ) : null}

        <li
          className={clsx(
            styles.pageItem,
            props.page === totalPages && styles.disabled
          )}
        >
          <ButtonIcon
            variant="tertiary"
            size="small"
            onClick={() => handlePageChange(props.page + 1)}
            ariaLabel={t('label.next-page')}
            disabled={props.page === totalPages}
            className={styles.pageItemLink}
          >
            <RightIcon />
          </ButtonIcon>
        </li>
      </ul>
    </nav>
  )
}

type PageItemsProps = {
  page: number
  totalPages: number
  onPageChange?: (page: number) => void
}

const PageItems = (props: PageItemsProps) => {
  const { t } = useTranslation(['common'])

  const delta = 1
  const range: (number | string)[] = []

  for (
    let i = Math.max(2, props.page - delta);
    i <= Math.min(props.totalPages - 1, props.page + delta);
    i++
  ) {
    range.push(i)
  }

  if (props.page - delta > 2) {
    range.unshift('...')
  }

  if (props.page + delta < props.totalPages - 1) {
    range.push('...')
  }

  range.unshift(1)

  if (props.totalPages > 1) {
    range.push(props.totalPages)
  }

  return range.map((page, index) =>
    typeof page === 'string' ? (
      <li
        key={`ellipsis-${index}`}
        className={clsx(
          styles.pageItem,
          styles.disabled,
          styles.pageItemEllipsis
        )}
        aria-hidden
      >
        ...
      </li>
    ) : (
      <li
        key={page}
        className={clsx(styles.pageItem, props.page === page && styles.active)}
      >
        <ButtonIcon
          variant="tertiary"
          size="small"
          onClick={() => props.onPageChange?.(page)}
          ariaLabel={t('label.go-to-page', { PAGE: page })}
          className={styles.pageItemLink}
        >
          {page}
        </ButtonIcon>
      </li>
    )
  )
}

export default Pagination
