import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

import {
  parseResponseOptions,
  responseSchemaOptions
} from '../constants/options'

const useCoursesOptions = () =>
  useQuery({
    queryKey: ['panelLessonsOptionsCoursesList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const coursesOptions =
        await httpService.panel.panelLessonsOptionsCoursesList({
          page_size: 'max'
        })

      return parseResponseOptions(
        responseSchemaOptions.parse(coursesOptions.results)
      )
    }
  })

export default useCoursesOptions
