import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useCancelLesson = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string; comment: string }) =>
      httpService.panel.panelLessonsCancelCreate(data.id, {
        comment: data.comment
      }),
    onSuccess
  })

export default useCancelLesson
