import i18n from '@/i18n'
import { type Language } from '@/types/lanuage'

export const changeLanguage = (lang: Language) => {
  document.documentElement.setAttribute('lang', getHtmlLangAttribute(lang))
  window.localStorage.setItem('language', lang)
  i18n.changeLanguage(lang)
}

const getHtmlLangAttribute = (lang: Language) =>
  lang === 'en' ? 'en-GB' : lang

export const getLanguageName = (lang: Language) => {
  switch (lang) {
    case 'de':
      return i18n.t('label.german', { ns: 'common' })
    case 'en':
      return i18n.t('label.english', { ns: 'common' })
  }
}
