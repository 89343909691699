import { z } from 'zod'

import { attendanceStatus, requiredString } from '@/utils/zod'

export const formSchemaAbsence = () =>
  z.object({
    attachments: z.array(z.string()),
    comment: z.string().optional(),
    attendances: z.array(
      z.object({
        attendanceId: requiredString(),
        presence: attendanceStatus
      })
    )
  })

export type ManageAbsenceFormSchema = z.infer<
  ReturnType<typeof formSchemaAbsence>
>
