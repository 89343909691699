import { useTranslation } from 'react-i18next'

import { toast } from '@/hooks/useToast'
import useRemoveCoverLesson from '@/mutations/useRemoveCoverLesson'
import { type Lesson } from '@/types/lesson'
import { formatTime } from '@/utils/format-date'
import {
  getLessonFormattedTime,
  getLessonShortedDetails
} from '@/utils/lesson-details-format'

import ConfirmModal from '../ConfirmModal'

type RemoveCoverModalProps = {
  lesson: Lesson
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onSuccess?: () => void
}

const RemoveCoverModal = (props: RemoveCoverModalProps) => {
  const { t } = useTranslation('common')

  const { mutate: removeCoverLesson, isPending: isRemoveCoverLessonLoading } =
    useRemoveCoverLesson({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.successfully-cancelled-cover-lesson', {
            DATE: getLessonFormattedTime(props.lesson)
          })
        })
        props.onOpenChange(false)
        props.onSuccess?.()
      }
    })

  return (
    <ConfirmModal
      id="remove-cover-lesson-modal"
      header={t('header.remove-cover-lesson', {
        COURSE: props.lesson.course.name
      })}
      description={props.lesson ? getLessonShortedDetails(props.lesson) : ''}
      open={props.open}
      onOpenChange={props.onOpenChange}
      subheader={
        <div>
          {t('help.are-you-want-remove-cover-lesson', {
            COURSE: props.lesson.course.name,
            TIME: formatTime(props.lesson.startDate)
          })}
          <br />
          {t('help.restore-initial-teacher')}
        </div>
      }
      loading={isRemoveCoverLessonLoading}
      onConfirm={() => removeCoverLesson({ id: props.lesson.id })}
    />
  )
}

export default RemoveCoverModal
