import { useEffect, useId } from 'react'

import useStickyStore from '@/store/useStickyStore'

export const PRIORITY = {
  navbar: 10,
  tabs: 20,
  tableTopBar: 30,
  tableHeader: 40
} as const

export type Priority = (typeof PRIORITY)[keyof typeof PRIORITY]

type UseStickyParams = {
  priority: Priority
  elementRef: React.RefObject<HTMLElement>
}

const useStickyElement = ({ priority, elementRef }: UseStickyParams) => {
  const id = useId()
  const {
    registerElement,
    unregisterElement,
    updateElementHeight,
    calculateTopOffset
  } = useStickyStore()

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const height = elementRef.current.offsetHeight
        updateElementHeight(id, height)
      }
    }

    if (elementRef.current) {
      const height = elementRef.current.offsetHeight
      registerElement(id, priority, height)
    }

    window.addEventListener('resize', updateHeight)

    return () => {
      unregisterElement(id)
      window.removeEventListener('resize', updateHeight)
    }
  }, [
    priority,
    registerElement,
    unregisterElement,
    elementRef,
    id,
    updateElementHeight
  ])

  return calculateTopOffset(priority)
}

export default useStickyElement
