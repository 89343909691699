import React from 'react'
import { useTranslation } from 'react-i18next'

import Label from '@/components/Label'
import Table, { type Column } from '@/components/Table/Table'
import Tooltip from '@/components/Tooltip/Tooltip'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import type {
  ColumnGrade,
  Grade,
  StudentGrade,
  StudentsGrades
} from '@/types/grades'

import styles from './GradesTable.module.scss'
import NotApplicable from './NotApplicable/NotApplicable'
import LinkText from '../LinkText/LinkText'

type GradeColumnType = 'behaviour' | 'proposed-grade' | 'final-grade' | 'custom'

type GradeCellProps = {
  grade: Grade
  cellId: string
  columnType: GradeColumnType
}

type GradesTableProps = {
  studentsGrades?: StudentsGrades
  isLoading?: boolean
  noHoverEffect?: boolean
  withBorders?: boolean
  gradeCell: (props: GradeCellProps) => React.ReactNode
  customGradeColumnHeader: (grade: ColumnGrade) => React.ReactNode
}

const getGradeCellId = (gradeName: string, studentName: string) =>
  `${gradeName}-for-${studentName}`.toLowerCase().replaceAll(' ', '-')

const GRADE_CELL_SIZE = 128

const GradesTable = (props: GradesTableProps) => {
  const { t } = useTranslation(['common'])
  const { isMobile } = useScreenResolution()

  const studentsGrades = props.studentsGrades || { students: [], columns: [] }

  const tableColumns: Column<StudentGrade>[] = [
    {
      dataIndex: ['student', 'fullName'],
      key: 'student',
      title: t('header.student'),
      fixed: isMobile ? false : 'left',
      width: 200,
      render: (value: string, record) => (
        <LinkText
          className={styles.studentCell}
          dataTestId="student-name"
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: record.student.id
          }}
        >
          {value}
        </LinkText>
      )
    },
    ...(studentsGrades?.columns.map(gradeColumn => ({
      key: gradeColumn.id,
      render: (record: StudentGrade) => {
        const gradeItem = record.gradeColumns[gradeColumn.id]

        return gradeItem ? (
          <>
            <Label
              id={gradeItem.id}
              label={t('label.select-grade', {
                GRADE: gradeColumn.name,
                STUDENT: record.student.fullName
              })}
              hidden
            />
            {props.gradeCell({
              grade: gradeItem,
              columnType: 'custom',
              cellId: getGradeCellId(gradeColumn.name, record.student.fullName)
            })}
          </>
        ) : (
          <Tooltip
            renderTriggerAsChild
            trigger={
              <div tabIndex={0} role="button" className={styles.noneGrade}>
                <NotApplicable />
              </div>
            }
            text={t('help.not-assignment-assigned')}
          />
        )
      },
      title: props.customGradeColumnHeader(gradeColumn),
      width: GRADE_CELL_SIZE,
      filled: true
    })) || []),
    {
      dataIndex: 'attendance',
      render: (value: number) => `${Math.round(value * 100)}%`,
      key: 'attendance',
      title: (
        <FixedColumnHeader
          className={styles.attendanceHeaderCell}
          tooltip={t('help.attendance-description')}
        >
          {t('header.attendance')}
        </FixedColumnHeader>
      ),
      width: GRADE_CELL_SIZE,
      isHighlighted: true
    },
    {
      key: 'behaviour',
      title: (
        <FixedColumnHeader
          className={styles.headerCell}
          tooltip={t('help.behaviour-description')}
        >
          {t('header.behaviour')}
        </FixedColumnHeader>
      ),
      render: (record: StudentGrade) => (
        <>
          <Label
            id={record.behaviour.id}
            label={t('label.select-grade', {
              GRADE: t('header.behaviour'),
              STUDENT: record.student.fullName
            })}
            hidden
          />
          {props.gradeCell({
            grade: record.behaviour,
            columnType: 'behaviour',
            cellId: getGradeCellId(
              t('header.behaviour'),
              record.student.fullName
            )
          })}
        </>
      ),
      width: GRADE_CELL_SIZE,
      isHighlighted: true,
      filled: true
    },
    {
      key: 'proposed-grade',
      title: (
        <FixedColumnHeader
          className={styles.headerCell}
          tooltip={t('help.proposed-grade-description')}
        >
          {t('header.proposed-grade')}
        </FixedColumnHeader>
      ),
      render: (record: StudentGrade) => (
        <>
          <Label
            id={record.proposedGrade.id}
            label={t('label.select-grade', {
              GRADE: t('header.proposed-grade'),
              STUDENT: record.student.fullName
            })}
            hidden
          />
          {props.gradeCell({
            grade: record.proposedGrade,
            columnType: 'proposed-grade',
            cellId: getGradeCellId(
              t('header.proposed-grade'),
              record.student.fullName
            )
          })}
        </>
      ),
      width: GRADE_CELL_SIZE,
      isHighlighted: true,
      filled: true
    },
    {
      key: 'final-grade',
      title: (
        <FixedColumnHeader
          className={styles.headerCell}
          tooltip={t('help.final-grade-description')}
        >
          {t('header.final-grade')}
        </FixedColumnHeader>
      ),
      render: (record: StudentGrade) => (
        <>
          <Label
            id={record.finalGrade.id}
            label={t('label.select-grade', {
              GRADE: t('header.final-grade'),
              STUDENT: record.student.fullName
            })}
            hidden
          />
          {props.gradeCell({
            grade: record.finalGrade,
            columnType: 'final-grade',
            cellId: getGradeCellId(
              t('header.final-grade'),
              record.student.fullName
            )
          })}
        </>
      ),
      width: GRADE_CELL_SIZE,
      isHighlighted: true,
      filled: true
    }
  ]
  return (
    <div className={styles.container}>
      <Table
        withBorders={props.withBorders}
        noRowHoverEffect={props.noHoverEffect}
        isLoading={props.isLoading}
        id="grades-table"
        data={studentsGrades.students}
        columns={tableColumns}
      />
    </div>
  )
}

type FixedColumnHeaderProps = {
  tooltip: string
  children: React.ReactNode
  className?: string
}

const FixedColumnHeader = (props: FixedColumnHeaderProps) => (
  <Tooltip
    renderTriggerAsChild
    trigger={<div className={props.className}>{props.children}</div>}
    text={props.tooltip}
  />
)

export default GradesTable
