import { type SelectOption } from '@/components/Select/Select'
import i18n from '@/i18n'

export type Gender = 'Male' | 'Female'
const genderOptions: SelectOption<Gender>[] = [
  { label: i18n.t('common:text.male'), value: 'Male' },
  { label: i18n.t('common:text.female'), value: 'Female' }
]

export default genderOptions
