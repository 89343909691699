import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@/assets/icons/close.svg?react'
import Button from '@/components/Button/Button'
import Checkbox, { type CheckboxProps } from '@/components/Checkbox/Checkbox'
import DateRangePicker, {
  type DatePickerProps
} from '@/components/DateRangePicker/DateRangePicker'
import Input, { type InputProps } from '@/components/Input/Input'
import Label from '@/components/Label'
import Select, {
  type MultiSelectProps,
  type SingleSelectProps
} from '@/components/Select/Select'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './Filters.module.scss'
import FiltersModal from './FiltersModal'

type InputFilter = {
  variant: 'input'
  filterProps: InputProps
}

type DateRangeFilter = {
  variant: 'date-range'
  filterProps: DatePickerProps
}

type CheckboxFilter = {
  variant: 'checkbox'
  filterProps: CheckboxProps
}

type SelectFilter = {
  variant: 'select'
  filterProps: SingleSelectProps<string>
}

type MultiSelectFilter = {
  variant: 'multiselect'
  filterProps: MultiSelectProps<string>
}

export type Filter = {
  size?: 'wide' | 'normal' | 'flexible'
  label: string
  hidden?: boolean
} & (
  | SelectFilter
  | InputFilter
  | MultiSelectFilter
  | DateRangeFilter
  | CheckboxFilter
)

type FilersProps = {
  filters: Filter[]
  hideClearAllButton?: boolean
  disabledClearAllButton?: boolean
  actionsRender?: React.ReactNode
  onClearAll?: () => void
}

const Filters = (props: FilersProps) => {
  const { isMobile } = useScreenResolution()
  const filters = props.filters.filter(item => !item.hidden)

  const selectedFilterCount = filters
    .map(filter => filter.filterProps.value)
    .filter(value => !!value).length

  return isMobile ? (
    <div className={styles.mobileContainer}>
      <FiltersModal selectedFilterCount={selectedFilterCount}>
        <FilterItems
          {...props}
          filters={filters}
          isMobile
          disabledClearAllButton={props.disabledClearAllButton}
        />
      </FiltersModal>
      {props.actionsRender}
    </div>
  ) : (
    <div className={styles.desktopFilters}>
      <FilterItems
        {...props}
        filters={filters}
        disabledClearAllButton={props.disabledClearAllButton}
      />
    </div>
  )
}

const FilterItems = (
  props: FilersProps & { isMobile?: boolean; disabledClearAllButton?: boolean }
) => {
  const { t } = useTranslation(['common'])

  const filterItem = (filter: Filter) => {
    switch (filter.variant) {
      case 'input':
        return <Input {...filter.filterProps} />
      case 'date-range':
        return <DateRangePicker {...filter.filterProps} />
      case 'checkbox':
        return <Checkbox {...filter.filterProps} />
      case 'select':
      case 'multiselect':
        return <Select {...filter.filterProps} />
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        {props.filters.map(filter => (
          <div
            key={filter.filterProps.id}
            className={clsx(
              styles.filter,
              filter.size === 'wide' && styles.wideSelect,
              filter.size === 'flexible' && styles.flexible
            )}
          >
            <Label
              id={filter.filterProps.id}
              label={filter.label}
              hidden={!props.isMobile}
            >
              {filterItem(filter)}
            </Label>
          </div>
        ))}

        {!props.hideClearAllButton ? (
          <div className={styles.buttonWrapper}>
            <Button
              variant="tertiary"
              onClick={props.onClearAll}
              disabled={props.disabledClearAllButton}
              icon={<CloseIcon />}
            >
              {t('button.clear-all-filters')}
            </Button>
          </div>
        ) : null}
      </div>
      <div className={styles.actionsContainer}>{props.actionsRender}</div>
    </div>
  )
}

export default Filters
