import { useTranslation } from 'react-i18next'

import type { CoveredOrCancelledLesson, LessonDetails } from '@/types/lesson'
import { formatTime } from '@/utils/format-date'
import {
  getLessonFormattedTime,
  getLessonShortedDetails
} from '@/utils/lesson-details-format'

import ConfirmModal from './ConfirmModal'
import { toast } from '../hooks/useToast'
import useRestoreLesson from '../modules/lessonDetails/mutations/useRestoreLesson'

type RestoreLessonModalProps = {
  lesson: LessonDetails | CoveredOrCancelledLesson
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onSuccess: () => void
}

export const RestoreLessonModal = (props: RestoreLessonModalProps) => {
  const { t } = useTranslation('common')

  const { mutate: restoreLesson, isPending: isRestoreLessonLoading } =
    useRestoreLesson({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.successfully-restored-lesson', {
            DATE: getLessonFormattedTime(props.lesson)
          })
        })
        props.onSuccess()
      }
    })

  return (
    <ConfirmModal
      id="restore-lesson-modal"
      header={t('header.restore-lesson', {
        COURSE: props.lesson.course.name
      })}
      description={getLessonShortedDetails(props.lesson)}
      open={props.open}
      onOpenChange={props.onOpenChange}
      subheader={
        <div>
          {t('help.are-you-want-restore-lesson', {
            COURSE: props.lesson.course.name,
            TIME: formatTime(props.lesson.startDate)
          })}
          <br />
          {t('help.restore-initial-lesson')}
        </div>
      }
      loading={isRestoreLessonLoading}
      onConfirm={() => restoreLesson({ id: props.lesson.id })}
    />
  )
}

export default RestoreLessonModal
