import { sumBy } from 'lodash'
import { create } from 'zustand'

import type { Priority } from '@/hooks/useStickyElement'

type StickyElement = {
  id: string
  height: number
  priority: Priority
}

type StickyStore = {
  elements: StickyElement[]
  registerElement: (id: string, priority: Priority, height: number) => void
  unregisterElement: (id: string) => void
  updateElementHeight: (id: string, height: number) => void
  calculateTopOffset: (priority: number) => number
}

const useStickyStore = create<StickyStore>((set, get) => ({
  elements: [],
  registerElement: (id, priority, height) => {
    const { elements } = get()
    const existingElement = elements.find(element => element.id === id)

    if (existingElement) return

    set(state => ({ elements: [...state.elements, { id, priority, height }] }))
  },
  unregisterElement: id => {
    set(state => ({ elements: state.elements.filter(el => el.id !== id) }))
  },
  updateElementHeight: (id, height) => {
    set(state => ({
      elements: state.elements.map(el =>
        el.id === id ? { ...el, height } : el
      )
    }))
  },

  calculateTopOffset: priority => {
    const { elements } = get()
    return sumBy(elements, element =>
      element.priority < priority ? element.height : 0
    )
  }
}))

export default useStickyStore
