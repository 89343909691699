import { isToday } from 'date-fns'
import { useTranslation } from 'react-i18next'

import LessonCancelIcon from '@/assets/icons/lesson-cancel.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import Select, { type SelectOption } from '@/components/Select/Select'
import Tag from '@/components/Tag/Tag'
import { formatTime } from '@/utils/format-date'

import styles from './LessonSelect.module.scss'
import { type Lesson } from '../queries/useLessons'

type LessonSelectProps = {
  value: string
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  onChange: (value?: string) => void
  lessonList: Lesson[]
}

const LessonSelect = (props: LessonSelectProps) => (
  <Select
    id="lesson-select"
    loading={props.isOptionsLoading}
    options={props.options || []}
    value={props.value}
    formatOption={option => (
      <LessonOption option={option} lessonList={props.lessonList} />
    )}
    onChange={value => props.onChange(value)}
  />
)

type LessonOptionProps = {
  option: SelectOption<string>
  lessonList: Lesson[]
}

const LessonOption = (props: LessonOptionProps) => {
  const { t } = useTranslation('lessonDetails')

  const lessonOption = props.lessonList?.find(
    lessonItem => lessonItem.id === props.option.value
  )

  return (
    <div className={styles.lessonOption}>
      <div className={styles.labelWrapper}>
        <span>{props.option.label}</span>
        <span className={styles.selectLessonTime}>
          {`${formatTime(lessonOption?.startDate)} - ${formatTime(lessonOption?.endDate)}`}
        </span>
      </div>

      {lessonOption && isToday(lessonOption.startDate) ? (
        <Tag label={t('text.today')} color="orange" />
      ) : null}

      {lessonOption && lessonOption.isCancelled ? (
        <Tag
          label={t('text.cancelled')}
          prefix={<LessonCancelIcon className={styles.cancelIcon} />}
        />
      ) : lessonOption && lessonOption.isCovered ? (
        <Tag
          label={t('text.cover')}
          prefix={<LessonCoverIcon className={styles.coverIcon} />}
        />
      ) : null}
    </div>
  )
}
export default LessonSelect
