import { useRouter } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'

import UserForm, { type UserForm as UserFormType } from '../components/UserForm'
import { type AddUserPayload } from '../constants/user-payload'
import useAddUser from '../mutations/useAddUser'

const defaultValues: UserFormType = {
  email: '',
  profile: [],
  sendActivationLink: false,
  firstName: '',
  lastName: '',
  ahvNumber: '',
  birthDate: undefined,
  firstLanguage: undefined,
  nationalities: [],
  gender: undefined,
  community: undefined,
  phoneNumber: '',
  emergencyPhoneNumber: '',
  classes: [],
  preferredRooms: []
}

const UserAddView = () => {
  const { t } = useTranslation(['users', 'common'])
  const router = useRouter()

  const { mutate: addUser, isPending } = useAddUser({
    onSuccess: data => {
      if (data.sendActivationLink) {
        toast({
          variant: 'success',
          title: t('toast.successfully-send-activation-link')
        })
      }
      toast({
        variant: 'success',
        title: t('toast.add-user', {
          NAME: `${data.firstName} ${data.lastName}`
        })
      })
      // TODO - redirect to user details - when backend ready -> need id
      router.history.back()
    },
    onError: data => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-add', {
          NAME: `${data.firstName} ${data.lastName}`
        })
      })
    }
  })

  const handleSubmit = (payload: AddUserPayload) => {
    addUser(payload)
  }

  return (
    <BasicLayout
      header={t('header.add-new-user')}
      moduleName={t('common:header.administration')}
    >
      <UserForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        loading={isPending}
      />
    </BasicLayout>
  )
}

export default UserAddView
