import * as Collapsible from '@radix-ui/react-collapsible'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'usehooks-ts'

import ChevronSmallDown from '@/assets/icons/chevron-small-down.svg?react'
import LanguageIcon from '@/assets/icons/language.svg?react'
import UpdateIcon from '@/assets/icons/update.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import { SCHOOL_NAME } from '@/constants/school-name'
import { USER_PROFILE } from '@/constants/user-profile'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import i18n from '@/i18n'
import useChangeLanguage from '@/mutations/useChangeLanguage'
import useAuthStore, { type User } from '@/store/useAuthStore'
import { type Language } from '@/types/lanuage'
import { type UserProfile } from '@/types/user-profile'
import { changeLanguage, getLanguageName } from '@/utils/change-language'
import { fetchUser } from '@/utils/fetch-user'

import styles from './Navigation.module.scss'

const getAdministrationRoutes = () => [
  {
    title: i18n.t('navigation.teachers-and-users', { ns: 'common' }),
    path: '/users'
  }
]

const getAcademicsRoutes = (user?: User) =>
  [
    {
      title: i18n.t('navigation.timetable', { ns: 'common' }),
      path: '/timetable'
    },
    {
      title: i18n.t('navigation.courses', { ns: 'common' }),
      path: '/courses',
      hidden: () => {
        const TEACHING_PROFILES: UserProfile[] = [
          USER_PROFILE.TUTOR,
          USER_PROFILE.TEACHER
        ]

        return (
          !user?.isSuperAdmin &&
          user?.profiles.every(element => !TEACHING_PROFILES.includes(element))
        )
      }
    },
    {
      title: i18n.t('navigation.students-and-classes', { ns: 'common' }),
      path: '/students-and-classes'
    },
    {
      title: i18n.t('navigation.absences', { ns: 'common' }),
      path: '/absences',
      hidden: () =>
        !user?.profiles.includes(USER_PROFILE.TUTOR) && !user?.isSuperAdmin
    },
    {
      title: i18n.t('navigation.covered-cancelled-lessons', { ns: 'common' }),
      path: '/covered-and-cancelled-lessons',
      hidden: () => !user?.isSuperAdmin
    }
  ].filter(item => !item.hidden?.())

const getSettingsRoutes = () => [
  {
    title: i18n.t('navigation.settings', { ns: 'common' }),
    path: '/profile'
  }
]

const getItems = (user?: User) => [
  {
    name: i18n.t('navigation.academics', { ns: 'common' }),
    routes: getAcademicsRoutes(user)
  },
  {
    name: i18n.t('navigation.administration', { ns: 'common' }),
    routes: getAdministrationRoutes()
  },
  {
    name: i18n.t('navigation.school-settings', { ns: 'common' }),
    routes: getSettingsRoutes()
  }
]

const getLanguageOptions = () =>
  [
    {
      name: i18n.t('label.german', { ns: 'common' }),
      value: 'de'
    },
    {
      name: i18n.t('label.english', { ns: 'common' }),
      value: 'en'
    }
  ] as const

const Hamburger = forwardRef(function Hamburger(
  props: { onClick: () => void; isOpen: boolean },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { t } = useTranslation(['common'])
  return (
    <ButtonIcon
      ref={ref}
      className={clsx(styles.hamburger, props.isOpen && styles.open)}
      onClick={() => props.onClick()}
      size="large"
      variant="primary"
      ariaLabel={t('label.open-menu')}
    >
      <span className={clsx(styles.bar, styles.barTop)} aria-hidden="true" />
      <span className={clsx(styles.bar, styles.barMiddle)} aria-hidden="true" />
      <span className={clsx(styles.bar, styles.barBottom)} aria-hidden="true" />
    </ButtonIcon>
  )
})

type NavigationLayoutProps = {
  items: { name: string; routes: { title: string; path: string }[] }[]
  withGeneratorItem?: boolean
}

export const NavigationLayout = (props: NavigationLayoutProps) => {
  const { t } = useTranslation(['common'])

  const [isOpen, setIsOpen] = useState(false)

  const navigationRef = useRef(null)
  const hamburgerRef = useRef(null)
  const languageDropdownRef = useRef(null)

  const { mutate: changeLanguageMutation } = useChangeLanguage({
    onSuccess: data => {
      toast({
        variant: 'success',
        title: t('notification.successfully-changed-language', {
          LANGUAGE: getLanguageName(data.language)
        })
      })

      fetchUser()
    }
  })

  const handleClickOutside = () => {
    setIsOpen(false)
  }

  const [activeLanguage, setActiveLanguage] = useState(
    window.localStorage.getItem('language')
  )

  const changeLanguageHandler = (lang: Language) => {
    changeLanguage(lang)
    changeLanguageMutation({ language: lang })

    setActiveLanguage(lang)
  }

  const { isTablet } = useScreenResolution()

  const tabIndexForElement = isTablet && !isOpen ? -1 : 0

  useOnClickOutside(
    [navigationRef, hamburgerRef, languageDropdownRef],
    handleClickOutside
  )

  return (
    <>
      <div
        className={clsx(styles.mobileHeader, isOpen && styles.mobileHeaderOpen)}
      >
        <Hamburger
          isOpen={isOpen}
          ref={hamburgerRef}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      </div>

      <div
        className={clsx(styles.navigation, isOpen && styles.navigationIsOpen)}
        aria-hidden={!!(isTablet && !isOpen)}
      >
        {isOpen ? <div className={styles.overlay} /> : null}
        <div className={styles.navigationHeaderWrapper}>
          <p className={styles.navigationHeader}>{SCHOOL_NAME}</p>
        </div>

        <div className={styles.navigationWrapper}>
          <NavigationMenu.Root orientation="horizontal" ref={navigationRef}>
            <NavigationMenu.List>
              {props.items.map((collapse, index) => (
                <li key={index}>
                  <Collapsible.Root className={styles.collapse} defaultOpen>
                    <Collapsible.Trigger
                      className={styles.collapseTrigger}
                      tabIndex={tabIndexForElement}
                    >
                      <span className={styles.collapseHeader}>
                        {collapse.name}
                        <ChevronSmallDown
                          className={styles.collapseIcon}
                          role="presentation"
                          aria-hidden="true"
                        />
                      </span>
                    </Collapsible.Trigger>

                    <Collapsible.Content className={styles.collapseContent}>
                      <ul>
                        {collapse.routes.map(item => (
                          <NavigationMenu.Item key={item.path}>
                            <NavigationMenu.Link asChild>
                              <Link
                                tabIndex={tabIndexForElement}
                                to={item.path}
                                className={clsx(styles.navigationLink)}
                                activeProps={{
                                  className: styles.navigationLinkActive
                                }}
                                onClick={() => {
                                  setIsOpen(false)
                                }}
                              >
                                <div className={styles.linkText}>
                                  {item.title}
                                </div>
                              </Link>
                            </NavigationMenu.Link>
                          </NavigationMenu.Item>
                        ))}
                      </ul>
                    </Collapsible.Content>
                  </Collapsible.Root>
                </li>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
        <div className={styles.bottomActions}>
          {props.withGeneratorItem ? (
            <a
              className={styles.bottomMenuItemWrapper}
              href="/generator"
              tabIndex={tabIndexForElement}
            >
              <UpdateIcon className={styles.menuIcon} />

              {t('button.generator')}
            </a>
          ) : null}

          <Dropdown
            variant="inverted"
            sideOffset={-10}
            trigger={
              <button
                className={styles.bottomMenuItemWrapper}
                tabIndex={tabIndexForElement}
              >
                <LanguageIcon className={styles.menuIcon} />

                {t('label.language')}
              </button>
            }
          >
            <div ref={languageDropdownRef}>
              {getLanguageOptions().map(action => (
                <DropdownMenuItem
                  onClick={() => {
                    changeLanguageHandler(action.value)
                  }}
                  key={action.value}
                  variant={
                    action.value == activeLanguage ? 'active' : 'neutral'
                  }
                >
                  <span className={styles.languageOption}>{action.name}</span>
                </DropdownMenuItem>
              ))}
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  )
}

const Navigation = () => {
  const { user } = useAuthStore()

  return (
    <NavigationLayout
      items={getItems(user || undefined)}
      withGeneratorItem={user?.isGeneratorAdmin}
    />
  )
}

export default Navigation
