import { createFileRoute, redirect } from '@tanstack/react-router'

import {
  CoveredAndCancelledLessonsView,
  CoveredLessonsView,
  lessonsFiltersSchema
} from '@/modules/coveredAndCancelledLessons'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const createCoveredOrCancelledLessonsRoute = (
  path: 'covered' | 'cancelled',
  component: () => JSX.Element
) =>
  createFileRoute(`/_auth/covered-and-cancelled-lessons/${path}`)({
    validateSearch: lessonsFiltersSchema,
    component,
    loaderDeps: ({ search }) => ({
      ...search
    }),
    loader: async ({ deps: search, context }) => {
      const semesters =
        await context.queryClient.ensureQueryData(semesterQueryOptions)

      if (!search.semesterId) {
        throw redirect({
          to: `/covered-and-cancelled-lessons/${path}`,
          replace: true,
          search: { ...search, semesterId: semesters.currentSemester?.id }
        })
      }
    }
  })

export const Route = createCoveredOrCancelledLessonsRoute('covered', () => (
  <CoveredAndCancelledLessonsView>
    <CoveredLessonsView />
  </CoveredAndCancelledLessonsView>
))
