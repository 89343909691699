import { createFileRoute, redirect } from '@tanstack/react-router'

import { timetableFiltersSchema, TimetableView } from '@/modules/timetable'

export const Route = createFileRoute('/_auth/timetable/')({
  component: () => <TimetableView />,
  validateSearch: search => timetableFiltersSchema.parse(search),
  loaderDeps: ({ search }) => ({ search }),
  loader: ({ context: { authStore }, deps: { search } }) => {
    if (!authStore?.user?.isTeacher) return

    const isFilterSelected =
      search.classId ||
      search.course ||
      search.room ||
      search.student ||
      search.teacher

    if (isFilterSelected) return

    throw redirect({
      replace: true,
      to: '/timetable',
      search: {
        ...search,
        teacher: authStore?.user?.id
      }
    })
  }
})
