import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    course: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    teacher: z.object({
      id: requiredString(),
      full_name: requiredString()
    }),
    co_teacher_1: z
      .object({
        id: requiredString(),
        full_name: requiredString()
      })
      .nullable()
  })
)

export type Group = {
  id: string
  name: string
  course: {
    id: string
    name: string
  }
  teacher: {
    id: string
    name: string
  }
  coTeacher?: {
    id: string
    name: string
  }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Group[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    course: {
      id: item.course.id,
      name: item.course.name
    },
    teacher: {
      id: item.teacher?.id,
      name: item.teacher?.full_name
    },
    coTeacher: item.co_teacher_1
      ? {
          id: item.co_teacher_1?.id,
          name: item.co_teacher_1?.full_name
        }
      : undefined
  }))

type Params = {
  semester: string
  year?: string[]
  teacherOrCoTeacherId?: string[]
  courseId?: string[]
  groupId?: string[]
  classId: string
  studentId?: string[]
  pageSize?: number | 'max'
  page?: number
}

const useClassGroups = (params: Params) =>
  useQuery({
    queryKey: ['panelClassesGroupsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesGroupsList(
        params.classId,
        params.semester,
        {
          page: params.page,
          page_size: params.pageSize,
          course_id: params.courseId,
          id: params.groupId,
          student_id: params.studentId,
          teacher_or_co_teacher_id: params.teacherOrCoTeacherId
        }
      )

      const parsed = parseResponse(responseSchema.parse(response.results))
      return {
        list: parsed,
        count: response.count
      }
    }
  })

export default useClassGroups
