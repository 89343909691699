import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import ModalForm from '@/components/Modal/ModalForm'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import i18n from '@/i18n'
import { requiredStringWithLabel } from '@/utils/zod'

import styles from './GradeColumnModal.module.scss'
import useAddGradesColumn from '../mutations/useAddGradesColumn'
import useEditGradeColumn from '../mutations/useEditGradesColumn'
import useGradesColumn from '../queries/useGradesColumn'

export const gradeColumnForm = () =>
  z.object({
    name: requiredStringWithLabel(
      i18n.t('label.grade-name', { ns: 'lessonDetails' })
    )
  })

type FormPayload = z.infer<ReturnType<typeof gradeColumnForm>>

type GradeColumnModalProps = {
  gradeColumnId?: string
  groupId: string
  semesterId: string
  trigger: React.ReactNode
  open: boolean
  onGradeColumnChange: () => void
  onOpenChange: (open: boolean) => void
  onRemove?: () => void
}

const GradeColumnModal = (props: GradeColumnModalProps) => {
  const { t } = useTranslation('lessonDetails')
  const isEditMode = !!props.gradeColumnId

  const handleOnEditSuccess = () => {
    props.onOpenChange(false)
    props.onGradeColumnChange()
  }

  const {
    isPending: isAddPending,
    mutate: addGradeColumn,
    formErrors: addFormErrors
  } = useAddGradesColumn({
    onSuccess: () => {
      handleOnEditSuccess()
      toast({
        variant: 'success',
        title: t('toast.grade-column-created')
      })
    }
  })

  const { isLoading, data } = useGradesColumn({
    columnId: props.gradeColumnId,
    enabled: props.open && isEditMode
  })

  const {
    isPending: isEditPending,
    mutate: editGradeColumn,
    formErrors: editFormErrors
  } = useEditGradeColumn({
    onSuccess: () => {
      handleOnEditSuccess()
      toast({
        variant: 'success',
        title: t('toast.grade-column-edited')
      })
    }
  })

  const isModalLoading = isAddPending || isEditPending || isLoading

  const form = useForm<FormPayload>({
    resolver: zodResolver(gradeColumnForm()),
    mode: 'all',
    defaultValues: {
      name: data?.name || ''
    }
  })

  useExternalErrors(isEditMode ? editFormErrors : addFormErrors, form)

  useEffect(() => {
    form.reset()
  }, [form, props.open])

  useEffect(() => {
    if (data) form.reset({ name: data.name })
  }, [data, form])

  const handleOnCancel = () => {
    props.onOpenChange(false)
  }

  const handleOnSubmit = (payload: FormPayload) => {
    isEditMode && data
      ? editGradeColumn({ ...payload, id: data.id })
      : addGradeColumn({
          ...payload,
          semesterId: props.semesterId,
          groupId: props.groupId
        })
  }

  return (
    <ModalForm
      id="grade-modal"
      title={
        isEditMode
          ? t('header.edit-column-grade')
          : t('header.add-column-grade')
      }
      size="xs"
      form={form}
      open={props.open}
      onOpenChange={props.onOpenChange}
      submitText={t('button.save')}
      trigger={props.trigger}
      onSubmit={handleOnSubmit}
      onCancel={handleOnCancel}
      loading={isModalLoading}
      onClose={handleOnCancel}
      onRemove={props.onRemove}
      withRemoveButton={!!props.onRemove}
    >
      <div className={styles.formWrapper}>
        <FormField
          control={form.control}
          id="grade-name"
          required
          label={t('label.grade-name')}
          name="name"
          render={({ inputProps }) => (
            <Input placeholder={t('help.type-grade-name')} {...inputProps} />
          )}
        />
      </div>
    </ModalForm>
  )
}

export default GradeColumnModal
