import type { Group } from '@/queries/useGroup'
import type { User } from '@/store/useAuthStore'
import type { Lesson } from '@/types/lesson'

export const hasEditLessonPermission = (
  user?: User | null,
  lesson?: Lesson
) => {
  if (!user || !lesson) return false

  return (
    user.isSuperAdmin ||
    [
      lesson.teacher.id,
      lesson.teacherCover?.id,
      lesson.coTeacher?.id,
      lesson.coTeacherCover?.id
    ].includes(user.id)
  )
}

export const hasEditGroupPermission = (user?: User | null, group?: Group) => {
  if (!user || !group) return false

  return (
    user.isSuperAdmin ||
    [
      group.teacher?.id,
      ...group.teacherCovers,
      group.coTeacher?.id,
      ...group.coTeacherCovers
    ].includes(user.id)
  )
}
