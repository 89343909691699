import i18n from '@/i18n'

export const absencesStatusTags = {
  excused: {
    color: 'green',
    label: i18n.t('label.excused', { ns: 'absences' })
  },
  unexcused: {
    color: 'red',
    label: i18n.t('label.unexcused', { ns: 'absences' })
  }
} as const
