import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { type Language } from '@/types/lanuage'

const useChangeLanguage = ({
  onSuccess
}: {
  onSuccess: (data: { language: Language }) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { language: Language }) =>
      httpService.panel.panelUsersMePartialUpdate({
        app_language: data.language
      }),
    onSuccess: (_, variables) => onSuccess?.(variables)
  })

export default useChangeLanguage
