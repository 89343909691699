import { useTranslation } from 'react-i18next'

import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'

import Button from '../Button/Button'

type GradebookButtonProps = {
  courseId: string
  groupId: string
  semesterId?: string
}
const GradebookButton = (props: GradebookButtonProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Button
      size="small"
      variant="secondary"
      asLink
      to="/timetable/$courseId/$groupId/grades"
      params={{
        courseId: props.courseId,
        groupId: props.groupId
      }}
      search={{
        semesterId: props.semesterId
      }}
      icon={<ArrowRightIcon />}
    >
      {t('button.go-to-gradebook')}
    </Button>
  )
}

export default GradebookButton
