import * as Sentry from '@sentry/react'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import NavigationLayout from '@/components/common/NavigationLayout/NavigationLayout'
import { changeLanguage } from '@/utils/change-language'
import { fetchUser } from '@/utils/fetch-user'
import { notifyError } from '@/utils/notify-error'

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location }) => {
    {
      if (!context.authStore || !context.authStore.isAuthenticated)
        throw redirect({
          to: '/login',
          search: {
            redirect: location.href
          }
        })
      if (!context.authStore.user) {
        try {
          const user = await fetchUser()
          Sentry.setUser(user)
          context.authStore.setUser(user)
          changeLanguage(user.appLanguage)
        } catch (error) {
          context.authStore.logOut()
          notifyError(error)
          throw redirect({
            to: '/login',
            search: {
              redirect: location.href
            }
          })
        }
      }
    }
  },
  component: () => (
    <NavigationLayout>
      <Outlet />
    </NavigationLayout>
  )
})
