export const downloadFile = ({
  fileData,
  fileName
}: {
  fileData?: File
  fileName: string
}) => {
  if (!fileData) return

  const url = window.URL.createObjectURL(new Blob([fileData]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
