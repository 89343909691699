import { omit } from 'lodash'
import { useState } from 'react'

import type {
  File,
  ErrorFileParams,
  FilesData,
  UpdateFileParams,
  UploadFileParams
} from '@/types/file-upload'

function isString(value: string | undefined): value is string {
  return typeof value === 'string'
}

const useFiles = (initialData: File[], onChange: (value: string[]) => void) => {
  const [filesData, setFilesData] = useState<FilesData>(() => {
    const initialFilesData: FilesData = {}
    initialData.forEach(file => {
      initialFilesData[file.id] = {
        ...file,
        fileState: { status: 'uploaded' },
        backendId: file.id
      }
    })
    return initialFilesData
  })

  const updateFiles = (updater: (data: FilesData) => FilesData) =>
    setFilesData(oldFilesData => {
      const newData = updater(oldFilesData)

      const backendIds = Object.values(newData)
        .map(file => file.backendId)
        .filter(isString)
      onChange(backendIds)

      return newData
    })

  const updateProgress = ({
    id,
    fileName,
    fileSize,
    progress
  }: UpdateFileParams) => {
    setFilesData(oldFilesData => ({
      ...oldFilesData,
      [id]: {
        fileName,
        fileSize,
        fileState: { status: 'uploading', progress }
      }
    }))
  }

  const removeFile = (fileId: string) => {
    updateFiles(oldFilesData => omit(oldFilesData, fileId))
  }

  const setFileError = ({ id, fileName, fileSize, error }: ErrorFileParams) => {
    setFilesData(oldFilesData => ({
      ...oldFilesData,
      [id]: {
        fileName,
        fileSize,
        fileState: { status: 'error', error }
      }
    }))
  }

  const setFileUploaded = ({
    id,
    fileName,
    fileSize,
    backendId
  }: UploadFileParams) => {
    updateFiles(oldFilesData => ({
      ...oldFilesData,
      [id]: {
        fileName,
        fileSize,
        fileState: { status: 'uploaded' },
        backendId
      }
    }))
  }

  return {
    filesData,
    updateProgress,
    setFileUploaded,
    removeFile,
    setFileError
  }
}

export { useFiles }
