import { useTranslation } from 'react-i18next'

import type { Lesson } from '@/types/lesson'
import { formatTime } from '@/utils/format-date'

import Button from '../Button/Button'
import ConfirmModal from '../ConfirmModal'

type CannotCancelLessonModalProps = {
  lesson: Lesson
  open: boolean
  onOpenChange: (open: boolean) => void
}

const CannotCancelLessonModal = (props: CannotCancelLessonModalProps) => {
  const { t } = useTranslation('common')

  return (
    <ConfirmModal
      id="cannot-cancel-lesson-modal"
      header={t('header.cannot-cancel-lesson')}
      subheader={t('help.lesson-has-recorded-attendance', {
        COURSE: props.lesson.course.name,
        TIME: formatTime(props.lesson.startDate)
      })}
      open={props.open}
      onOpenChange={props.onOpenChange}
      confirmButton={
        <Button onClick={() => props.onOpenChange(false)}>
          {t('button.consent')}
        </Button>
      }
    />
  )
}

export default CannotCancelLessonModal
