import Button, {
  type ButtonProps,
  type ButtonVariant
} from '@/components/Button/Button'

import styles from './Actions.module.scss'
import ActionsDrawer from '../ActionsDrawer/ActionsDrawer'

export type Action = {
  onClick?: () => void
  variantButton?: ButtonVariant
  variantAction?: 'normal' | 'danger'
  loading?: boolean
  text: string
  hidden?: boolean
} & Omit<ButtonProps, 'children' | 'onClick'>

type ActionsProps = {
  actions?: Action[]
}

const Actions = (props: ActionsProps) =>
  props.actions?.length ? (
    <>
      <div className={styles.buttonWrapper}>
        {props.actions?.map((action, index) => {
          const { variantAction: _, ...buttonProps } = action
          return (
            <Button
              {...buttonProps}
              key={index}
              variant={action.variantButton || 'secondary'}
            >
              {action.text}
            </Button>
          )
        })}
      </div>

      <ActionsDrawer
        actions={props.actions.map(action => ({
          ...action,
          isDanger: action.variantAction === 'danger'
        }))}
        onlyMobile
        triggerButton={{ size: 'medium' }}
      />
    </>
  ) : null

export default Actions
