import clsx from 'clsx'
import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@/assets/icons/check-circle.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import FileIcon from '@/assets/icons/file.svg?react'
import WarningIcon from '@/assets/icons/warning-circle.svg?react'

import styles from './FileItem.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

type FileItemProps = {
  fileName: string
  fileSize: number
  onDelete?: () => void
  fileState:
    | { status: 'uploaded' }
    | { status: 'error'; error: string }
    | { status: 'uploading'; progress: number }
}

const FileItem = (props: FileItemProps) => {
  const { t } = useTranslation('common')

  const fileSize =
    Math.round((props.fileSize / 1000 / 1000 + Number.EPSILON) * 100) / 100

  const fileStatus = () => {
    if (props.fileState.status === 'error')
      return (
        <span className={clsx(styles.status, styles.error)}>
          <WarningIcon className={styles.icon} />
          {props.fileState.error}
        </span>
      )
    if (props.fileState.status === 'uploaded')
      return (
        <span className={clsx(styles.status, styles.success)}>
          <CheckIcon className={styles.icon} />
          {t('text.file-uploaded')}
        </span>
      )
    return <ProgressBar progress={props.fileState.progress} />
  }

  return (
    <li className={styles.container}>
      <div className={styles.fileDetails}>
        <div className={styles.fileNameContainer}>
          <FileIcon className={styles.icon} />
          <span className={styles.fileName}> {props.fileName}</span>
        </div>
        <ButtonIcon
          onClick={props.onDelete}
          variant="tertiary"
          size="small"
          ariaLabel={t('button.remove')}
        >
          <DeleteIcon />
        </ButtonIcon>
      </div>
      <span className={styles.fileSize}>{fileSize}MB</span>
      {fileStatus()}
    </li>
  )
}

type ProgressBarProps = { progress: number }

const ProgressBar = (props: ProgressBarProps) => (
  <div
    className={styles.progressContainer}
    style={{ '--progress': `${props.progress}%` } as CSSProperties}
  >
    <div className={styles.progressBarBackground}>
      <div className={styles.progress} />
    </div>

    <div className={styles.progressText}>{props.progress}%</div>
  </div>
)

export default FileItem
