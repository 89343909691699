import { createFileRoute } from '@tanstack/react-router'

import {
  addCoverCancelFiltersSchema,
  AddCoverCancelLessonView
} from '@/modules/coveredAndCancelledLessons'

export const Route = createFileRoute(
  '/_auth/covered-and-cancelled-lessons/add-cover-cancel'
)({
  validateSearch: addCoverCancelFiltersSchema,
  component: AddCoverCancelLessonView
})
