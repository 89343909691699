import { z } from 'zod'

import { type SelectOption } from '@/components/Select/Select'
import { requiredString } from '@/utils/zod'

export const responseSchemaOptions = z.array(
  z.object({
    id: requiredString(),
    name: requiredString()
  })
)

export const parseResponseOptions = (
  response: z.infer<typeof responseSchemaOptions>
): SelectOption<string>[] =>
  response.map(item => ({
    label: item.name,
    value: item.id
  }))
