import { useTranslation } from 'react-i18next'

import Login from '@/assets/images/login.svg'
import Logo from '@/assets/images/logo.svg'
import Card from '@/components/Card'

import styles from './Layout.module.scss'

type LayoutProps = {
  header: string
  subheader: React.ReactNode
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { t } = useTranslation('auth')
  return (
    <main className={styles.container}>
      <Card className={styles.card}>
        <img src={Logo} alt={t('help.school-logo')} className={styles.logo} />
        <h1 className={styles.header}> {props.header}</h1>
        <p className={styles.subheader}>{props.subheader}</p>

        {props.children}
      </Card>

      <div className={styles.imageWrapper}>
        <img src={Login} className={styles.image} alt="" />
      </div>
    </main>
  )
}

export default Layout
