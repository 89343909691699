import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'
import { mockQuery } from '@/utils/mock-query'

import {
  userFormfieldErrorsMapper,
  type AddUserPayload
} from '../constants/user-payload'

const useAddUser = (options?: {
  onSuccess?: (validData: AddUserPayload) => void
  onError?: (validData: AddUserPayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: AddUserPayload) =>
      mockQuery({
        email: data.email,
        phone_number: data.phoneNumber,
        emergency_phone_number: data.emergencyPhoneNumber,
        first_name: data.firstName,
        last_name: data.lastName,
        ahv_number: data.ahvNumber,
        date_of_birth: formatApiDate(data.birthDate),
        gender: data.gender,
        nationalities: data.nationalities,
        first_language: data.firstLanguage,
        community: data.community,
        tutor: data.tutor,
        teacher: data.teacher,
        profile: data.profile,
        // TODO - change role
        is_superuser: data.profile.includes('Admin'),
        send_activation_link: data.sendActivationLink
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    onError: (_, variables) => options?.onError?.(variables),
    fieldErrorsMapper: userFormfieldErrorsMapper
  })

export default useAddUser
