import { useQuery } from '@tanstack/react-query'

import { mockQuery } from '@/utils/mock-query'
import { arrayOfOptions } from '@/utils/zod'

const mockData = [
  { label: 'Community 1', value: 'community-1' },
  { label: 'Community 2', value: 'community-2' },
  { label: 'Poland', value: 'Poland' }
]

const useCommunities = () =>
  useQuery({
    // TODO: Replace queryKey with correct key
    queryKey: ['panelLessonsGroupsListPoland'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await mockQuery(mockData)
      const parsed = arrayOfOptions.parse(response.results)

      return {
        options: parsed
      }
    }
  })

export default useCommunities
