import * as Collapsible from '@radix-ui/react-collapsible'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import ChevronLargeDown from '@/assets/icons/chevron-large-down.svg?react'

import styles from './Collapse.module.scss'
import Divider from '../Divider/Divider'

type CollapseProps = {
  header: React.ReactNode
  variant?: 'primary' | 'secondary'
  className?: string
  defaultOpen?: boolean
  scrollIntoDefaultOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  actions?: React.ReactNode
  children?: React.ReactNode
  dataTestId?: string
}

const Collapse = (props: CollapseProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { variant = 'primary' } = props

  useEffect(() => {
    if (props.defaultOpen && props.scrollIntoDefaultOpen)
      ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [props.defaultOpen, props.scrollIntoDefaultOpen])

  return (
    <Collapsible.Root
      ref={ref}
      className={clsx(styles.collapse, props.className, styles[variant])}
      defaultOpen={props.defaultOpen}
      onOpenChange={props.onOpenChange}
    >
      <div
        className={styles.headerWrapper}
        data-test-id={props.dataTestId || 'collapse'}
      >
        <Collapsible.Trigger className={styles.trigger}>
          <div
            className={clsx(
              styles.header,
              props.actions && styles.headerWithActions,
              props.variant === 'secondary' && styles.secondaryHeader
            )}
          >
            <div className={styles.iconWrapper}>
              <ChevronLargeDown className={styles.icon} />
            </div>
            {props.header}
          </div>
        </Collapsible.Trigger>

        {props.actions ? (
          <div className={styles.actions}>{props.actions}</div>
        ) : null}
      </div>
      <Divider className={styles.divider} />
      <Collapsible.Content className={styles.content}>
        <div className={styles.contentBox}>{props.children}</div>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

export default Collapse
