import { createFileRoute, redirect } from '@tanstack/react-router'

import { getRoutesWithPermission } from '@/modules/courses'

export const Route = createFileRoute('/_auth/courses/')({
  beforeLoad: ({ context }) => {
    if (!context.authStore?.user) return
    const [route] = getRoutesWithPermission(context.authStore?.user)

    if (route.link.to) {
      throw redirect({
        replace: true,
        to: route.link.to
      })
    }
  }
})
