import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    group_id: requiredString(),
    group_name: requiredString(),
    course_name: requiredString(),
    teacher: z.object({ id: requiredString(), full_name: requiredString() }),
    co_teacher_1: z
      .object({ id: requiredString(), full_name: requiredString() })
      .nullable(),
    attendance_percentage: z.number(),
    behaviour_grade: z.string().nullable(),
    proposed_grade: z.string().nullable(),
    final_grade: z.string().nullable(),
    grades: z.array(z.string())
  })
)

export type StudentCoursesSummary = {
  id: string
  course: { id: string; name: string }
  group: { id: string; name: string }
  teacher: { id: string; name: string }
  coTeacher?: { id: string; name: string }
  attendance: number
  behaviourGrade: string | null
  proposedGrade: string | null
  finalGrade: string | null
  grades: string[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): StudentCoursesSummary[] =>
  response.map(item => ({
    id: item.id,
    course: { id: item.id, name: item.course_name },
    group: { id: item.group_id, name: item.group_name },
    teacher: { id: item.teacher.id, name: item.teacher.full_name },
    coTeacher: item.co_teacher_1
      ? { id: item.co_teacher_1.id, name: item.co_teacher_1.full_name }
      : undefined,
    attendance: item.attendance_percentage,
    behaviourGrade: item.behaviour_grade,
    proposedGrade: item.proposed_grade,
    finalGrade: item.final_grade,
    grades: item.grades
  }))

const useStudentCourses = ({
  semesterId,
  studentId,
  teacherOrCoTeacherId,
  course
}: {
  semesterId: string
  studentId: string
  teacherOrCoTeacherId: string[] | undefined
  course: string[] | undefined
}) =>
  useQuery({
    queryKey: [
      'courses-summary',
      semesterId,
      studentId,
      teacherOrCoTeacherId,
      course
    ],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelCoursesSummaryList(
        semesterId,
        studentId,
        {
          course_id: course,
          teacher_or_co_teacher_id: teacherOrCoTeacherId
        }
      )

      const parsed = parseResponse(responseSchema.parse(response))
      return parsed
    }
  })

export default useStudentCourses
