import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type Gender } from '@/constants/gender-options'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'
import {
  date,
  requiredString,
  userStatus,
  gender,
  userProfiles
} from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  first_name: requiredString(),
  last_name: requiredString(),
  status: userStatus.optional(),
  email: z.string(),
  phone_number: z.string().nullable().optional(),
  emergency_phone_number: z.string().nullable().optional(),
  is_teacher: z.boolean(),
  is_superuser: z.boolean(),
  profiles: userProfiles,
  gender: gender(),
  date_of_birth: date(),
  nationalities: z.array(z.string()),
  first_language: z.string(),
  ahv_number: z.string(),
  community: z.string(),
  preferred_room: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable()
    .optional(),
  tutor: z
    .object({
      classes: z.array(
        z.object({
          id: requiredString(),
          name: requiredString()
        })
      )
    })
    .nullable()
    .optional(),
  teacher: z
    .object({
      custom_schedule: z.object({
        '0': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '1': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '2': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '3': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '4': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '5': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        ),
        '6': z.array(
          z.object({
            time_from: z.string(),
            time_to: z.string()
          })
        )
      })
    })
    .nullable()
    .optional()
})

export type Schedule = {
  timeFrom: string
  timeTo: string
}

export type User = {
  id: string
  name: string
  firstName: string
  lastName: string
  status?: UserStatus
  email: string
  phoneNumber?: string
  emergencyPhoneNumber?: string
  isTeacher: boolean
  isSuperuser: boolean
  profiles: UserProfile[]
  gender: Gender
  dateOfBirth?: Date
  nationalities: string[]
  firstLanguage: string
  community: string
  ahvNumber: string
  preferredRoom?: {
    id: string
    name: string
  }
  tutor?: {
    classes: {
      id: string
      name: string
    }[]
  } | null
  teacher?: {
    customSchedule: {
      monday: Schedule[]
      tuesday: Schedule[]
      wednesday: Schedule[]
      thurdsay: Schedule[]
      friday: Schedule[]
      saturday: Schedule[]
      sunday: Schedule[]
    }
  }
}

const parseResponse = (response: z.infer<typeof responseSchema>): User => ({
  id: response.id,
  name: response.name,
  firstName: response.first_name,
  lastName: response.last_name,
  status: response.status,
  email: response.email,
  phoneNumber: response.phone_number || undefined,
  emergencyPhoneNumber: response.emergency_phone_number || undefined,
  gender: response.gender,
  dateOfBirth: response.date_of_birth || undefined,
  nationalities: response.nationalities,
  firstLanguage: response.first_language,
  ahvNumber: response.ahv_number,
  community: response.community,
  isTeacher: response.is_teacher,
  isSuperuser: response.is_superuser,
  profiles: response.is_superuser
    ? [...response.profiles, 'Admin']
    : response.profiles,
  tutor: response.tutor || undefined,
  preferredRoom: response.preferred_room ? response.preferred_room : undefined,
  teacher: response.teacher
    ? {
        customSchedule: {
          monday: response.teacher.custom_schedule['0'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          tuesday: response.teacher.custom_schedule['1'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          wednesday: response.teacher.custom_schedule['2'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          thurdsay: response.teacher.custom_schedule['3'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          friday: response.teacher.custom_schedule['4'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          saturday: response.teacher.custom_schedule['5'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          })),
          sunday: response.teacher.custom_schedule['6'].map(schedule => ({
            timeFrom: schedule.time_from,
            timeTo: schedule.time_to
          }))
        }
      }
    : undefined
})

export const userQueryOptions = (id: string) => ({
  queryKey: ['panelUserRetrieve', id],
  staleTime: 60 * 100,
  queryFn: async () => {
    const userResponse = await httpService.panel.panelUsersRetrieve(id, {
      fetchKeys: {
        id: true,
        name: true,
        first_name: true,
        last_name: true,
        status: true,
        email: true,
        phone_number: true,
        emergency_phone_number: true,
        is_teacher: true,
        is_superuser: true,
        profiles: true,
        gender: true,
        date_of_birth: true,
        nationalities: true,
        first_language: true,
        ahv_number: true,
        community: true,
        preferred_room: true,
        tutor: true,
        teacher: true
      }
    })

    return parseResponse(responseSchema.parse(userResponse))
  }
})

const useUser = (id: string) => useQuery(userQueryOptions(id))

export default useUser
