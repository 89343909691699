import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/users/$userId/')({
  beforeLoad: async ({ params }) => {
    throw redirect({
      replace: true,
      to: '/users/$userId/details',
      params
    })
  }
})
