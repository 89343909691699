import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1),
    description: z.string(),
    created: z.string().datetime({ offset: true }),
    due_date: z.string().datetime({ offset: true }),
    send_to_students: z.boolean(),
    is_graded: z.boolean(),
    student_items: z.array(
      z.object({
        id: z.string().min(1),
        student: z.object({
          id: z.string().min(1),
          name: z.string().min(1),
          first_name: z.string().min(1),
          last_name: z.string().min(1)
        }),
        grade: z
          .object({
            id: z.string(),
            mark_id: z.string().nullable()
          })
          .nullable()
      })
    )
  })
)

export type Assignment = {
  id: string
  name: string
  created: Date
  dueDate: Date
  description: string
  sendToStudents: boolean
  isGraded: boolean
  students: {
    assignmentId: string
    studentId: string
    name: string
    firstName: string
    lastName: string
    grade: { id: string; markId: string | null } | null
  }[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): Assignment[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    description: item.description,
    created: new Date(item.created),
    dueDate: new Date(item.due_date),
    sendToStudents: item.send_to_students,
    isGraded: item.is_graded,
    students: item.student_items.map(studentItem => ({
      assignmentId: studentItem.id,
      studentId: studentItem.student.id,
      name: studentItem.student.name,
      firstName: studentItem.student.first_name,
      lastName: studentItem.student.last_name,
      grade: studentItem.grade
        ? { id: studentItem.grade.id, markId: studentItem.grade.mark_id }
        : null
    }))
  }))

const useAssignments = (groupId: string) =>
  useQuery({
    queryKey: ['panelAssignmentsList', groupId],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelAssignmentsList({
        group_id: groupId,
        page_size: 'max'
      })

      const parsed = parseResponse(responseSchema.parse(response.results))
      return parsed
    }
  })

export default useAssignments
