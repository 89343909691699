import { zodResolver } from '@hookform/resolvers/zod'
import { isSameDay } from 'date-fns'
import { groupBy } from 'lodash'
import { useEffect, useState } from 'react'
import { type Control, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Divider from '@/components/Divider/Divider'
import FormField from '@/components/FormField'
import Label from '@/components/Label'
import ModalForm from '@/components/Modal/ModalForm'
import RadioGroup from '@/components/Radio/RadioGroup'
import Textarea from '@/components/Textarea/Textarea'
import DirtyModal from '@/components/common/DirtyModal'
import FileUploader from '@/components/common/FileUploader/FileUploader'
import { toast } from '@/hooks/useToast'
import { useFiles } from '@/hooks/useUploadFile'
import { type File } from '@/types/file-upload'
import { formatDate } from '@/utils/format-date'

import styles from './ManageAbsenceModal.module.scss'
import {
  type ManageAbsenceFormSchema,
  formSchemaAbsence
} from '../constants/absence-payload'
import useUpdateAbsence from '../mutations/useUpdateAbsence'
import useAbsence, { type Attendance } from '../queries/useAbsence'
import { type LessonAttendanceEnum } from '../types/absences-status'

export type AbsenceModalVariant =
  | 'absent_excused'
  | 'absent_unexcused'
  | 'present'
  | 'custom'

type ManageAbsenceModalProps = {
  absenceId?: string
  open: boolean
  onOpenChange: (open: boolean) => void
  onClose: () => void
  markAsPresent: () => void
}

const isAllSelected = (
  value: LessonAttendanceEnum,
  presenceList: LessonAttendanceEnum[]
) => presenceList.every(field => field === value)

const radioOptions = [
  {
    labelledby: 'label-excused',
    value: 'absent_excused',
    className: styles.radioItem
  },
  {
    labelledby: 'label-unexcused',
    value: 'absent_unexcused',
    className: styles.radioItem
  },
  {
    labelledby: 'label-present',
    value: 'present',
    className: styles.radioItem
  }
] as const

const ManageAbsenceModal = (props: ManageAbsenceModalProps) => {
  const { t } = useTranslation('absences')
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState(false)
  const [variant, setVariant] = useState<AbsenceModalVariant>('absent_excused')

  const {
    data: absence,
    isLoading: isFetchAbsenceLoading,
    isFetching
  } = useAbsence({
    id: props.absenceId,
    enabled: props.open && !!props.absenceId
  })

  const { mutate: updateAbsence, isPending: isUpdateAbsenceLoading } =
    useUpdateAbsence({
      onSuccess: payload => {
        const excusedLessonCount = payload.attendances.filter(
          attendance => attendance.presence === 'absent_excused'
        ).length

        const unexcusedLessonCount = payload.attendances.filter(
          attendance => attendance.presence === 'absent_unexcused'
        ).length

        if (excusedLessonCount) {
          toast({
            variant: 'success',
            title: t('notification.successfully-excused-lessons', {
              NUMBER: excusedLessonCount
            })
          })
        }

        if (unexcusedLessonCount) {
          toast({
            variant: 'success',
            title: t('notification.successfully-unexcused-lessons', {
              NUMBER: unexcusedLessonCount
            })
          })
        }
        props.onClose()
        setVariant('absent_excused')
      }
    })

  const form = useForm<ManageAbsenceFormSchema>({
    resolver: zodResolver(formSchemaAbsence()),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      attachments: [],
      comment: '',
      attendances: []
    }
  })

  const { isDirty } = form.formState

  const { fields, replace, update } = useFieldArray({
    control: form.control,
    name: 'attendances'
  })

  const getFieldIndex = (attendanceId: string) =>
    fields.findIndex(field => field.attendanceId === attendanceId)

  const getFieldDetails = (attendanceId: string) =>
    absence?.attendances.find(item => item.id === attendanceId)

  const attendances = form.watch('attendances')
  const groupedAttendance = groupBy(attendances, 'lesson.date')
  const groupedAttendanceKeys = Object.keys(groupedAttendance)

  const getAllDayValue = (day: string) => {
    const presenceList = groupedAttendance[day].map(item => item.presence)

    const isAllPresent = isAllSelected('present', presenceList)
    const isAllAbsentExcused = isAllSelected('absent_excused', presenceList)
    const isAllAbsentUnexcused = isAllSelected('absent_unexcused', presenceList)

    return isAllPresent
      ? 'present'
      : isAllAbsentExcused
        ? 'absent_excused'
        : isAllAbsentUnexcused
          ? 'absent_unexcused'
          : undefined
  }

  const handleOnChangeAllDay = (day: string, value: LessonAttendanceEnum) => {
    const newAttendanceList = attendances
      .map(attendance => {
        const currentAttendance = absence?.attendances.find(
          item => item.id === attendance.attendanceId
        )

        if (!currentAttendance) return

        return {
          ...attendance,
          presence: isSameDay(currentAttendance.lesson.date, day)
            ? value
            : attendance.presence
        }
      })
      .filter(item => !!item)

    replace(newAttendanceList)
  }

  useEffect(() => {
    if (!!absence) {
      form.reset({
        comment: absence.comment,
        attachments: absence.attachment.map(attachment => attachment.id),
        attendances: absence.attendances.map(attendance => ({
          ...attendance,
          attendanceId: attendance.id
        }))
      })
    }
  }, [absence, form])

  const handleSubmit = (data: ManageAbsenceFormSchema) => {
    if (!absence) return

    switch (variant) {
      case 'present':
        props.markAsPresent()
        return

      case 'absent_excused':
      case 'absent_unexcused':
        const payload = {
          ...data,
          attendances: absence.attendances.map(attendance => ({
            id: attendance.id,
            presence: variant
          }))
        }
        updateAbsence({ id: absence.id, payload })
        return

      default:
        updateAbsence({
          id: absence.id,
          payload: {
            ...data,
            attendances: data.attendances.map(attendance => ({
              id: attendance.attendanceId,
              presence: attendance.presence
            }))
          }
        })
    }
  }

  const handleOnCancel = () => {
    isDirty || variant !== 'absent_excused'
      ? setIsDirtyModalOpen(true)
      : handleClose()
  }

  const handleClose = () => {
    props.onOpenChange(false)
    form.reset()
    setVariant('absent_excused')
  }

  const variantOptions = [
    {
      label: t('label.excuse-all'),
      value: 'absent_excused'
    },
    { label: t('label.mark-all-as-unexcused'), value: 'absent_unexcused' },
    { label: t('label.mark-all-as-present'), value: 'present' },
    { label: t('label.choose-lessons'), value: 'custom' }
  ]

  return (
    <ModalForm
      id="manage-absence-lesson"
      title={t('header.manage-absence')}
      description={`${formatDate(absence?.startDate)}-${formatDate(absence?.endDate)}`}
      submitText={t('button.save')}
      form={form}
      loading={isFetchAbsenceLoading || isUpdateAbsenceLoading}
      open={props.open}
      onOpenChange={props.onOpenChange}
      onSubmit={handleSubmit}
      onCancel={handleOnCancel}
      onClose={handleOnCancel}
    >
      <div>
        <Label
          id="absence-modal-variant"
          label={t('label.select-option-for-absece-management')}
          hidden
        />
        <RadioGroup
          id="absence-modal-variant"
          value={variant}
          options={variantOptions}
          onChange={setVariant}
        />
      </div>

      {variant === 'custom' ? (
        <div>
          <AbsenceHeader />

          <div className={styles.container}>
            {groupedAttendanceKeys.map((day, indexDay) => (
              <div key={day} className={styles.wrapper}>
                <div className={styles.row}>
                  <div className={styles.dayWrapper}>
                    <span className={styles.day}>
                      {formatDate(new Date(day))}
                    </span>
                    <span className={styles.allDay}>{t('text.all-day')}</span>
                  </div>

                  <fieldset>
                    <legend className={styles.visuallyHidden}>
                      {t('text.attendance-for-all-day', {
                        DATE: formatDate(new Date(day))
                      })}
                    </legend>

                    <RadioGroup
                      id={`group-${day}`}
                      orientation="horizontal"
                      options={radioOptions}
                      value={getAllDayValue(day)}
                      className={styles.radioGroupWrapper}
                      onChange={value => handleOnChangeAllDay(day, value)}
                    />
                  </fieldset>
                </div>

                {groupedAttendance[day].map(field => (
                  <AbsenceLesson
                    fieldIndex={getFieldIndex(field.attendanceId)}
                    key={getFieldIndex(field.attendanceId)}
                    fieldDetails={getFieldDetails(field.attendanceId)}
                    formControl={form.control}
                    value={fields[getFieldIndex(field.attendanceId)].presence}
                    onValueChange={value => {
                      update(getFieldIndex(field.attendanceId), {
                        ...field,
                        presence: value
                      })
                    }}
                  />
                ))}

                {indexDay < groupedAttendanceKeys.length - 1 ? (
                  <Divider variant="dark" />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {variant !== 'present' ? (
        <>
          <FormField
            control={form.control}
            id="comment"
            label={t('label.comment')}
            name="comment"
            render={({ inputProps }) => (
              <Textarea
                placeholder={t('text.type-comment')}
                numberOfLines={3}
                {...inputProps}
              />
            )}
          />

          {absence && !isFetching ? (
            <Attachments
              attachments={absence.attachment}
              onChange={value => {
                form.setValue('attachments', value)
              }}
            />
          ) : null}
        </>
      ) : null}

      <DirtyModal
        isOpen={isDirtyModalOpen}
        onOpenChange={setIsDirtyModalOpen}
        onConfirm={() => {
          handleClose()
          setIsDirtyModalOpen(false)
        }}
      />
    </ModalForm>
  )
}

const Attachments = (props: {
  attachments: File[]
  onChange: (value: string[]) => void
}) => {
  const { t } = useTranslation('absences')

  const fileUpload = useFiles(props.attachments, props.onChange)

  return (
    <>
      <Divider />
      <h3 className={styles.attachmentsLabel}>{t('label.attachments')}</h3>

      <FileUploader
        maxSize={20000000}
        attachmentType="absence"
        id="attachment"
        label={t('label.attachment')}
        {...fileUpload}
      />
    </>
  )
}

const AbsenceHeader = () => {
  const { t } = useTranslation('absences')
  return (
    <div className={styles.labelWrapper}>
      <span id="label-excused" className={styles.label}>
        {t('label.excused')}
      </span>
      <span id="label-unexcused" className={styles.label}>
        {t('label.unexcused')}
      </span>
      <span id="label-present" className={styles.label}>
        {t('label.present')}
      </span>
    </div>
  )
}

type AbsenceLessonProps = {
  fieldIndex: number
  fieldDetails?: Attendance
  formControl: Control<ManageAbsenceFormSchema>
  value?: LessonAttendanceEnum
  onValueChange: (value: LessonAttendanceEnum) => void
}
const AbsenceLesson = (props: AbsenceLessonProps) => {
  const { t } = useTranslation('absences')

  return (
    <div className={styles.row}>
      <div className={styles.lesson}>
        <span>{props.fieldDetails?.lesson.course.name}</span>
        <span>{props.fieldDetails?.lesson.group.name}</span>
      </div>
      <FormField
        id={`attendances.${props.fieldIndex}.presence`}
        key={`attendances.${props.fieldIndex}.presence`}
        name={`attendances.${props.fieldIndex}.presence`}
        control={props.formControl}
        className={styles.box}
        render={() => (
          <fieldset>
            <legend className={styles.visuallyHidden}>
              {t('text.attendance-for', {
                COURSE: props.fieldDetails?.lesson.course.name,
                GROUP: props.fieldDetails?.lesson.group.name
              })}
            </legend>

            <RadioGroup
              id={`attendances.${props.fieldIndex}.presence`}
              orientation="horizontal"
              options={radioOptions}
              className={styles.radioGroupWrapper}
              value={props.value}
              onChange={props.onValueChange}
            />
          </fieldset>
        )}
      />
    </div>
  )
}

export default ManageAbsenceModal
