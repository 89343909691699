import i18n from '@/i18n'

export const userStatusTags = {
  blocked: { color: 'red', label: i18n.t('label.blocked', { ns: 'common' }) },
  active: { color: 'green', label: i18n.t('label.active', { ns: 'common' }) },
  inactive: {
    color: 'blue',
    label: i18n.t('label.inactive', { ns: 'common' })
  }
} as const
