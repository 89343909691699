export type AcademicAttendance = {
  id: string
  student: AttendanceStudent
  presence?: PresenceEnum | BlankEnum
}

export type AcademicAttendanceRequest = {
  presence?: PresenceEnum | BlankEnum
}

export type ActivityMove = {
  activity: string
  hour: number
  hour_display: string
  day_display: string
  day: string
  duration: string
}

export type AdminImportRequest = {
  /** @minLength 1 */
  timetable: string
}

export type AdminImportedTimetable = {
  id: string
  timetable: string
  /** @format date-time */
  started_at: string | null
  /** @format date-time */
  finished_at: string | null
  status: string
  timetable_public_id: number
}

/**
 * * `en` - English
 * * `de` - German
 * * `fr` - French
 */
export type AppLanguageEnum = 'en' | 'de' | 'fr'
/** * `absence` - Absence */
export type AttachmentTypeEnum = 'absence'

export type AttendanceStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type AuthTokenRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export type BlankEnum = ''

export type BusyInSlotRequestRequest = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   * * `5` - Saturday
   * * `6` - Sunday
   */
  day: DayEnum
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  hour?: HourEnum
  /** @min 1 */
  duration?: number
  /** @format time */
  custom_start?: string
  /** @format time */
  custom_end?: string
}

export type BusyInSlotResponse = {
  busy_teachers_ids: string[]
  busy_rooms_ids: string[]
}

export type Callback = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  external_id: string
  is_optimal?: boolean | null
}

export type CallbackRequest = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  /** @minLength 1 */
  external_id: string
  is_optimal?: boolean | null
}

/**
 * * `success` - Success
 * * `infeasible` - Infeasible
 * * `error` - Error
 * * `optimizing` - Optimizing
 */
export type CallbackStatusEnum =
  | 'success'
  | 'infeasible'
  | 'error'
  | 'optimizing'

/**
 * * `neutral` - Neutral
 * * `positive` - Positive
 * * `negative` - Negative
 */
export type CommentTypeEnum = 'neutral' | 'positive' | 'negative'

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export type CommunityEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'

export type CoursePlanner = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
  description?: string
  subject?: string | null
}

export type CoursePlannerList = {
  id: string
  /** @maxLength 256 */
  code: string
  /** @maxLength 256 */
  name: string
}

export type CoursePlannerRequest = {
  related_courses?: string[]
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  code: string
  description?: string
  /** @minLength 1 */
  subject?: string | null
}

export type CourseSubject = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CurrentLesson = {
  id: string
  students: string
  course: string
}

/**
 * * `0` - Monday
 * * `1` - Tuesday
 * * `2` - Wednesday
 * * `3` - Thursday
 * * `4` - Friday
 * * `5` - Saturday
 * * `6` - Sunday
 */
export type DayEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type DraftActivityCreate = {
  id: string
  teacher_display: string
  co_teacher_1_display: string
  co_teacher_2_display: string
  room_display: string
  co_room_display: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  /** @format time */
  computed_start_time: string
  /** @format time */
  computed_end_time: string
  is_private?: boolean
  course: string
  teacher: string
  room?: string | null
  co_room?: string | null
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  quarter: string | null
  semester: string
  timetable: string
  section?: string | null
  from_optimization?: number | null
  cloned_from: string | null
  students: string[]
}

export type DraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  is_private?: boolean
  /** @minLength 1 */
  course: string
  /** @minLength 1 */
  teacher: string
  /** @minLength 1 */
  room?: string | null
  /** @minLength 1 */
  co_room?: string | null
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  /** @minLength 1 */
  timetable: string
  /** @minLength 1 */
  section?: string | null
  from_optimization?: number | null
  students: string[]
}

export type DraftActivityList = {
  id: string
  date: string
  start: string
  end: string
  start_date_formatted: string
  end_date_formatted: string
  course: string
  course_str: string
  users: SimpleTeacher[]
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  display_hour: string
  room?: string | null
  room_str: string
  co_room_str: string | null
  teacher: SimpleTeacher
  co_teacher_1?: SimpleTeacher | null
  co_teacher_2?: SimpleTeacher | null
}

export type Enrollment = {
  id: number
  student: string
  student_str: string
  /** @default true */
  is_active?: boolean
  number_of_lessons: number
  /** @default false */
  is_private?: boolean
  quarter: string
}
/** * `lesson` - lesson */
export type EventTypeEnum = 'lesson'

export type FailedAttemptError = {
  code: string
  detail: string
  attr: string
  extra_data?: FailedAttemptErrorExtraData
}

export type FailedAttemptErrorExtraData = {
  failed_attempts: number
  max_attempts: number
}

export type FailedAttemptErrorResponse = {
  /** * `validation_error` - Validation Error */
  type: ValidationErrorEnum
  errors: FailedAttemptError[]
}

export type FreeSlot = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  display_hour: string
  start: string
  end: string
  course: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTask = {
  id: number
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  status: GenerationTaskStatusEnum
  stop_request_sent?: boolean
  best_timetable: string | null
  optimize_only_late_slots?: boolean
  status_display: string
  /** @format date-time */
  created: string
  assign_rooms?: boolean
  semester: string
  /**
   * @min 0
   * @max 15
   */
  late_hour?: LateHourEnum
  late_hour_display: string
  is_finished?: boolean
  students?: string[]
  fail_reason_display: string
  timetables: string
  generate_blank_timetable?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTaskRequest = {
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  stop_request_sent?: boolean
  optimize_only_late_slots?: boolean
  assign_rooms?: boolean
  /** @minLength 1 */
  semester: string
  /**
   * @min 0
   * @max 15
   */
  late_hour?: LateHourEnum
  is_finished?: boolean
  students?: string[]
  generate_blank_timetable?: boolean
}

/**
 * * `new` - New
 * * `server_obtained` - Server has been obtained
 * * `generation_in_progress` - Generation in progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type GenerationTaskStatusEnum =
  | 'new'
  | 'server_obtained'
  | 'generation_in_progress'
  | 'failed'
  | 'success'

/**
 * * `0` - 8:00-8:40
 * * `1` - 8:45-9:25
 * * `2` - 9:30-10:10
 * * `3` - 10:30-11:10
 * * `4` - 11:15-11:55
 * * `5` - 13:00-13:40
 * * `6` - 13:45-14:25
 * * `7` - 14:30-15:10
 * * `8` - 15:15-15:55
 * * `9` - 16:00-16:40
 * * `10` - 16:45-17:25
 * * `11` - 17:30-18:10
 * * `12` - 18:15-18:55
 * * `13` - 19:00-19:40
 * * `14` - 19:40-20:30
 * * `15` - 20:30-21:00
 */
export type HourEnum =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15

/**
 * * `regular` - Regular
 * * `behaviour` - Behaviour
 * * `proposed` - Proposed
 * * `final` - Final
 */
export type KindEnum = 'regular' | 'behaviour' | 'proposed' | 'final'

/**
 * * `0` - 8:00-8:40
 * * `1` - 8:45-9:25
 * * `2` - 9:30-10:10
 * * `3` - 10:30-11:10
 * * `4` - 11:15-11:55
 * * `5` - 13:00-13:40
 * * `6` - 13:45-14:25
 * * `7` - 14:30-15:10
 * * `8` - 15:15-15:55
 * * `9` - 16:00-16:40
 * * `10` - 16:45-17:25
 * * `11` - 17:30-18:10
 * * `12` - 18:15-18:55
 * * `13` - 19:00-19:40
 * * `14` - 19:40-20:30
 * * `15` - 20:30-21:00
 */
export type LateHourEnum =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15

export type LessonCoverAndCancelRequest = {
  /** @minLength 1 */
  id: string
  /** @minLength 1 */
  teacher_cover_id?: string | null
  /** @minLength 1 */
  co_teacher_1_cover_id?: string | null
  is_cancelled?: boolean | null
}

export type LockedUntilError = {
  code: string
  detail: string
  attr: string
  extra_data?: LockedUntilErrorExtraData
}

export type LockedUntilErrorExtraData = {
  /** @format date-time */
  locked_until: string
}

export type LockedUntilErrorResponse = {
  /** * `validation_error` - Validation Error */
  type: ValidationErrorEnum
  errors: LockedUntilError[]
}
export type LoginUserResponse = {
  phone_number: string
  /**
   * Email address
   * @format email
   */
  email: string
}
/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export type NationalitiesEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'
export type NullEnum = null

export type PaginatedAcademicAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedActivityMoveList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ActivityMove[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminImportedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminImportedTimetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCoursePlannerListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CoursePlannerList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseSubjectList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseSubject[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftActivityListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftActivityList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGenerationTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GenerationTask[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedPanelAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelAssignmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelAssignment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelClassGroupList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelClassGroup[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelCourseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelCourse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelGradeMarkList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelGradeMark[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonCourseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonCourse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonGroupList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonGroup[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonGroupOptionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonGroupOption[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonGroupStudentClassList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonGroupStudentClass[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonTeacherList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonTeacher[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelParentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelParent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelStudentClassList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelStudentClass[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelTeacherAvailabilityList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelTeacherAvailability[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelUserList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelUser[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedPotentialOptimizationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PotentialOptimization[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedQuarterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Quarter[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: Quarter
}

export type PaginatedRoomList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Room[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: SchoolYear
}

export type PaginatedSectionListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SectionList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSemesterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Semester[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: Semester
}

export type PaginatedSimpleSectionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SimpleSection[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStringOptionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StringOption[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedTeacherList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Teacher[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Timetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsence = {
  id: string
  student: PanelAbsenceStudent
  excused_by: PanelAbsenceUser | null
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  affected_lessons: number
  status: PanelAbsenceStatusEnum
  /** @format date-time */
  excused_at: string | null
  comment: string
  attachments: PanelAbsenceAttachment[]
  attendances: PanelAbsenceAttendance[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttachment = {
  id: string
  file_name: string
  file_size: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttendance = {
  id: string
  presence?: PresenceEnum | BlankEnum
  lesson: PanelAbsenceLesson
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttendanceRequest = {
  /** @minLength 1 */
  id: string
  presence?: PresenceEnum | BlankEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceLesson = {
  id: string
  /** @format date */
  date: string
  group: PanelAbsenceLessonGroup
  course: PanelAbsenceCourse
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceLessonGroup = {
  id: string
  name: string
}
/**
 * * `excused` - Excused
 * * `unexcused` - Unexcused
 */
export type PanelAbsenceStatusEnum = 'excused' | 'unexcused'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceStudent = {
  id: string
  first_name: string
  last_name: string
  name: string
  student_class: PanelAbsenceStudentClass
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceStudentClass = {
  id: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUpdate = {
  id: string
  comment?: string
  attachments: string[]
  attendances: PanelAbsenceAttendance[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUpdateRequest = {
  comment?: string
  attachments: string[]
  attendances: PanelAbsenceAttendanceRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUser = {
  id: string
  first_name: string
  last_name: string
  name: string
}

export type PanelAssignment = {
  id: string
  /** @maxLength 128 */
  name: string
  description?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  group: string
  student_items: PanelAssignmentItem[]
}

export type PanelAssignmentDelete = {
  id: string
  /** @maxLength 128 */
  name: string
}

export type PanelAssignmentGradeItem = {
  id: string
  mark_id: string | null
}

export type PanelAssignmentItem = {
  id: string
  student: PanelAssignmentStudent
  grade: PanelAssignmentGradeItem
}

export type PanelAssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  description?: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  /** @minLength 1 */
  group: string
  student_ids: string[]
}

export type PanelAssignmentStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
}
export type PanelAssignmentUpdate = {
  id: string
  /** @maxLength 128 */
  name: string
  description?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
}

export type PanelAssignmentUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  description?: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  student_ids: string[]
}

export type PanelAttachmentCreate = {
  id: string
  file: string
  /** * `absence` - Absence */
  attachment_type: AttachmentTypeEnum
}

export type PanelAttachmentCreateRequest = {
  /** @format binary */
  file: File
  /** * `absence` - Absence */
  attachment_type: AttachmentTypeEnum
}

export type PanelCheckEmailTakenRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export type PanelCheckEmailTakenResponse = {
  is_taken: boolean
}

export type PanelClassCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelClassGroup = {
  id: string
  name: string
  course: PanelClassCourse
  teacher: PanelClassTeacher
  co_teacher_1: PanelClassTeacher | null
  co_teacher_2: PanelClassTeacher | null
  students: PanelClassStudent[]
}

export type PanelClassGroupOptions = {
  groups: StringOption[]
  courses: StringOption[]
  students: StringOption[]
  teachers: StringOption[]
}

export type PanelClassParent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
}

export type PanelClassRoom = {
  id: string
  name: string
}

export type PanelClassStudent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelClassStudentWithParents = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  parents: PanelClassParent[]
}

export type PanelClassTeacher = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelClassTeacherDetail = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string | null
  /** @format email */
  email: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelComment = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  student: PanelCommentStudent
  /** @format date */
  date: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  added_by: PanelCommentUser
  comment_type: CommentTypeEnum
  content: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  group: PanelCommentGroup
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelCommentCreate = {
  id: string
  /** @format date */
  date: string
  added_by: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  content: string
  group?: string | null
}

export type PanelCommentCreateRequest = {
  student_ids: string[]
  /** @format date */
  date: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  /** @minLength 1 */
  content: string
  /** @minLength 1 */
  group?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentGroup = {
  id: string
  name: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelCommentCourse
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentStudent = {
  id: string
  first_name: string
  last_name: string
  name: string
}

export type PanelCommentUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  student: string
  /** @format date */
  date: string
  added_by: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  content: string
  group?: string | null
}

export type PanelCommentUpdateRequest = {
  /** @format date */
  date: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  /** @minLength 1 */
  content: string
  /** @minLength 1 */
  group?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentUser = {
  id: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCourse = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
  description?: string
}

export type PanelCourseSummary = {
  id: string
  group_id: string
  group_name: string
  course_name: string
  teacher: PanelCourseSummaryTeacher
  co_teacher_1: PanelCourseSummaryTeacher
  /** @format double */
  attendance_percentage: number
  behaviour_grade: string | null
  proposed_grade: string | null
  final_grade: string | null
  grades: string[]
}

export type PanelCourseSummaryOptions = {
  courses: StringOption[]
  teachers: StringOption[]
}

export type PanelCourseSummaryTeacher = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelGrade = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
  group: string
  semester: string
  quarter?: string | null
  assignment_id: string | null
}

export type PanelGradeItem = {
  id: string
  column_id: string
  mark_id: string | null
}

export type PanelGradeItemBulkUpdate = {
  items: PanelGradeItemBulkUpdateItem[]
}

export type PanelGradeItemBulkUpdateItem = {
  id: string
  mark_id: string | null
}

export type PanelGradeItemBulkUpdateItemRequest = {
  /** @minLength 1 */
  id: string
  /** @minLength 1 */
  mark_id: string | null
}

export type PanelGradeItemBulkUpdateRequest = {
  items: PanelGradeItemBulkUpdateItemRequest[]
}

export type PanelGradeItemRequest = {
  /** @minLength 1 */
  mark_id: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelGradeMark = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  label: string
  /** @default false */
  for_behaviour?: boolean
}

export type PanelGradeRequest = {
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  group: string
  /** @minLength 1 */
  semester: string
  /** @minLength 1 */
  quarter?: string | null
}

export type PanelGradeStudent = {
  id: string
  full_name: string
  first_name: string
  last_name: string
}

export type PanelGradeSummaryColumn = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
  /**
   * * `regular` - Regular
   * * `behaviour` - Behaviour
   * * `proposed` - Proposed
   * * `final` - Final
   */
  kind?: KindEnum
  assignment_id: string | null
}

export type PanelGradeUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
}

export type PanelGradeUpdateRequest = {
  /** @minLength 1 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLesson = {
  id: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelLessonCourse
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  group: PanelLessonGroupBase
  group_order: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  room: PanelLessonRoom
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  teacher_cover: PanelLessonTeacher | null
  co_teacher_1_cover: PanelLessonTeacher | null
  co_teacher_1: PanelLessonTeacher | null
  topic: string
  notes: string
  is_covered: boolean
  is_cancelled: boolean
  student_classes: PanelLessonGroupStudentClass[]
  comment: string
  can_be_cancelled: boolean
}

export type PanelLessonBulkCoverAndCancelRequest = {
  lessons: LessonCoverAndCancelRequest[]
  comment?: string
}

export type PanelLessonCancelRequest = {
  comment?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroup = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelLessonCourse
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  co_teacher_1: PanelLessonTeacher | null
  co_teacher_2: PanelLessonTeacher | null
  lesson_count: number
  students_count: number
  student_classes: PanelLessonGroupStudentClass[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupBase = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  course_id: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupDetails = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  tutors: string[]
  teacher_covers: string[]
  co_teacher_1_covers: string[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  co_teacher_1: PanelLessonTeacher | null
  co_teacher_2: PanelLessonTeacher | null
  students: PanelLessonGroupStudent[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupOption = {
  id: string
  name: string
}

export type PanelLessonGroupOptions = {
  groups: StringOption[]
  courses: StringOption[]
  teachers: StringOption[]
  co_teachers: StringOption[]
  students: StringOption[]
  classes: StringOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  student_class: PanelLessonGroupStudentClass
  /** @format double */
  attendance_percentage: number
  behaviour_grade: string | null
  proposed_grade: string | null
  final_grade: string | null
  grades: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonQuarter = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: string
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  short_name: ShortNameD45Enum
  school_year: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonRoom = {
  id: string
  /** @maxLength 128 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonSemester = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /**
   * * `S1` - S1
   * * `S2` - S2
   */
  short_name: SemesterShortName
  school_year: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonTeacher = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type PanelLessonUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  topic?: string
  notes?: string
}

export type PanelLessonUpdateCover = {
  teacher_cover?: string | null
  co_teacher_1_cover?: string | null
  comment?: string
}

export type PanelLessonUpdateRequest = {
  topic?: string
  notes?: string
}

export type PanelLoginRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export type PanelLoginResponse = {
  sending_sms_failed: boolean
  user: LoginUserResponse
}

export type PanelLoginTokenResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type PanelLoginValidateCodeRequest = {
  code: number
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export type PanelNewPasswordConfirmRequest = {
  /** @minLength 1 */
  password: string
  /** @minLength 1 */
  token: string
}

export type PanelNewPasswordRequestRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelParent = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string | null
  is_active?: boolean
}

export type PanelSendActivationLinkResponse = {
  successfully_sent: number
  unsuccessfully_sent: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelStudent = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  year?: string
  student_class: PanelStudentStudentClass | null
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string | null
  is_active?: boolean
  /** @format date */
  date_of_birth: string
  status: string
  parents: PanelStudentParent[]
  tutor: PanelStudentTutor | null
  gender: string
  nationalities: NationalitiesEnum[]
  first_language: string
  ahv_number?: string
  community: CommunityEnum
}

export type PanelStudentClass = {
  id: string
  name: string
  tutor: PanelClassTeacher
  year: string
  number_of_students: number
}

export type PanelStudentClassDetail = {
  id: string
  name: string
  tutor: PanelClassTeacherDetail
  year: string
  number_of_students: number
  /** Maximum number of students */
  capacity: number
  preferred_room: PanelClassRoom | null
  students: PanelClassStudentWithParents[]
}

export type PanelStudentParent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
  email: string
  status: string
}

export type PanelStudentSendActivationLinkRequest = {
  ids: string[]
  send_to_students: boolean
  send_to_parents: boolean
}

export type PanelStudentStudentClass = {
  id: string
  name: string
}

export type PanelStudentTutor = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelTeacherAvailability = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: Status726Enum
}

export type PanelTeacherAvailabilityCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelTeacherAvailabilityDetail = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: Status726Enum
  lessons: PanelTeacherAvailabilityLesson[]
}

export type PanelTeacherAvailabilityGroup = {
  id: string
  name: string
}

export type PanelTeacherAvailabilityLesson = {
  id: string
  course: PanelTeacherAvailabilityCourse
  group: PanelTeacherAvailabilityGroup
}

export type PanelTimetableEvent = PanelTimetableEventLesson

export type PanelTimetableEventCourse = {
  id: string
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
}

export type PanelTimetableEventLesson = {
  id: string
  /** @default "lesson" */
  event_type: EventTypeEnum
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  is_cancelled: boolean
  course: PanelTimetableEventCourse
  group: PanelTimetableEventLessonGroup
  room: PanelTimetableEventRoom | null
  co_room: PanelTimetableEventRoom | null
  teacher: PanelTimetableEventTeacher
  co_teacher_1: PanelTimetableEventTeacher | null
  co_teacher_2: PanelTimetableEventTeacher | null
  teacher_cover: PanelTimetableEventTeacher | null
  co_teacher_1_cover: PanelTimetableEventTeacher | null
  class_names: string[]
  tutors: string[]
}

export type PanelTimetableEventLessonGroup = {
  id: string
}

export type PanelTimetableEventRoom = {
  id: string
  /** @maxLength 128 */
  name: string
}

export type PanelTimetableEventTeacher = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type PanelTimetablePreview = {
  id: string
  /** @default "lesson" */
  event_type: EventTypeEnum
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @default false */
  is_cancelled?: boolean
  course: PanelTimetableEventCourse
  group?: PanelTimetableEventLessonGroup | null
  room: PanelTimetableEventRoom | null
  co_room: PanelTimetableEventRoom | null
  teacher: PanelTimetableEventTeacher
  co_teacher_1: PanelTimetableEventTeacher | null
  co_teacher_2: PanelTimetableEventTeacher | null
  teacher_cover?: PanelTimetableEventTeacher | null
  co_teacher_1_cover?: PanelTimetableEventTeacher | null
  class_names: string[]
  tutors: string[]
}

export type PanelTimetablePreviewCheckResponse = {
  preview_exists: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUser = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: PanelUserStatusEnum
  /**
   * Email address
   * @format email
   */
  email: string
  phone_number: string
  emergency_phone_number: string
  is_teacher: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser: boolean
  is_generator_admin: boolean
  profiles: string[]
  permissions: string
  gender: string
  /** @format date */
  date_of_birth: string | null
  nationalities: NationalitiesEnum[]
  first_language: string
  community: CommunityEnum
  ahv_number: string
  preferred_room: PanelUserRoom | null
  tutor: PanelUserTutor | null
  teacher: PanelUserTeacher | null
  app_language: AppLanguageEnum
}

export type PanelUserOptions = {
  statuses: StringOption[]
  profiles: StringOption[]
  ids: StringOption[]
  emails: StringOption[]
  phone_numbers: StringOption[]
}

export type PanelUserRoom = {
  id: string
  name: string
}

export type PanelUserSendActivationLinkRequest = {
  ids: string[]
}

export type PanelUserSendActivationLinkResponse = {
  successfully_sent: number
  unsuccessfully_sent: number
}

/**
 * * `active` - Active
 * * `inactive` - Inactive
 * * `blocked` - Blocked
 */
export type PanelUserStatusEnum = 'active' | 'inactive' | 'blocked'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTeacher = {
  custom_schedule: any
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTutor = {
  classes: PanelUserTutorStudentClass[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTutorStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserUpdateMe = {
  id: string
  /**
   * * `en` - English
   * * `de` - German
   * * `fr` - French
   */
  app_language?: AppLanguageEnum
}
export type PatchedAcademicAttendanceRequest = {
  presence?: PresenceEnum | BlankEnum
}

export type PatchedCoursePlannerRequest = {
  related_courses?: string[]
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  code?: string
  description?: string
  /** @minLength 1 */
  subject?: string | null
}

export type PatchedCourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedDraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day?: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  is_private?: boolean
  /** @minLength 1 */
  course?: string
  /** @minLength 1 */
  teacher?: string
  /** @minLength 1 */
  room?: string | null
  /** @minLength 1 */
  co_room?: string | null
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  /** @minLength 1 */
  timetable?: string
  /** @minLength 1 */
  section?: string | null
  from_optimization?: number | null
  students?: string[]
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedPanelAbsenceUpdateRequest = {
  comment?: string
  attachments?: string[]
  attendances?: PanelAbsenceAttendanceRequest[]
}

export type PatchedPanelAssignmentUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  description?: string
  /** @format date-time */
  due_date?: string
  send_to_students?: boolean
  is_graded?: boolean
  student_ids?: string[]
}

export type PatchedPanelCommentUpdateRequest = {
  /** @format date */
  date?: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type?: CommentTypeEnum
  /** @minLength 1 */
  content?: string
  /** @minLength 1 */
  group?: string | null
}

export type PatchedPanelGradeItemRequest = {
  /** @minLength 1 */
  mark_id?: string | null
}

export type PatchedPanelGradeUpdateRequest = {
  /** @minLength 1 */
  name?: string
}

export type PatchedPanelLessonUpdateCoverRequest = {
  /** @minLength 1 */
  teacher_cover?: string | null
  /** @minLength 1 */
  co_teacher_1_cover?: string | null
  comment?: string
}

export type PatchedPanelLessonUpdateRequest = {
  topic?: string
  notes?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedPanelUserUpdateMeRequest = {
  /**
   * * `en` - English
   * * `de` - German
   * * `fr` - French
   */
  app_language?: AppLanguageEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
}

/** Adds nested create feature */
export type PatchedSectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  /** @minLength 1 */
  quarter?: string | null
  /** @minLength 1 */
  semester?: string
  /** @minLength 1 */
  course?: string
  /**
   * Main teacher
   * @minLength 1
   */
  teacher?: string
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedTimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type PotentialOptimization = {
  id: number
  activity: string
  conflict_students: string[]
  conflict_free_students: string[]
  no_teacher?: boolean
  private_activities_collisions: string
  /**
   * @min 0
   * @max 2147483647
   */
  cost: number
  /**
   * @min 0
   * @max 2147483647
   */
  profit: number
  /**
   * @min 0
   * @max 2147483647
   */
  balance: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  day_display: string
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  hour_display: string
}

/**
 * * `present` - Present
 * * `late` - Lateness
 * * `absent_excused` - Absent excused
 * * `absent_unexcused` - Absent unexcused
 * * `` - Blank
 */
export type PresenceEnum =
  | 'present'
  | 'late'
  | 'absent_excused'
  | 'absent_unexcused'
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Quarter = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: string
  short_name: ShortNameD45Enum
  school_year: number
}

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type QuarterShortNameEnum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Room = {
  id: string
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolYear = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: string
}

/** Adds nested create feature */
export type Section = {
  id: string
  course_name: string
  course_code: string
  all_enrollments: Enrollment[]
  enrollments?: SectionEnrollment[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  public_id: number
  hours: number | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  expected_double_slots: number | null
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  quarter?: string | null
  semester: string
  course: string
  /** Main teacher */
  teacher: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

export type SectionEnrollment = {
  id: string
  student: string
  section?: string
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionEnrollmentRequest = {
  /** @minLength 1 */
  student: string
  /** @minLength 1 */
  section?: string
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionList = {
  id: string
  public_id: number
  course_name: string
  course_code: string
  course_id: string
  /** Main teacher */
  teacher_id: string | null
  /** Co-artisan 1 */
  co_teacher_1_id: string | null
  /** Co-artisan 2 */
  co_teacher_2_id: string | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  exclude_from_generation?: boolean
}

/** Adds nested create feature */
export type SectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  /** @minLength 1 */
  quarter?: string | null
  /** @minLength 1 */
  semester: string
  /** @minLength 1 */
  course: string
  /**
   * Main teacher
   * @minLength 1
   */
  teacher: string
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

export type SectionToEnroll = {
  id: string
  /** Main teacher */
  teacher?: string | null
  teacher_str: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  all_enrollments: Enrollment[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Semester = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: SemesterShortName
  school_year: string
}

/**
 * * `S1` - S1
 * * `S2` - S2
 */
export type SemesterShortName = 'S1' | 'S2'

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type ShortNameD45Enum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

/** Sections without enrollments */
export type SimpleSection = {
  id: string
  quarter?: string | null
  course: string
  /** Main teacher */
  teacher?: string | null
  teacher_str: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
}

export type SimpleTeacher = {
  id: string
  full_name: string
}

export type Status726Enum = 'available' | 'busy' | 'unavailable'

export type StringOption = {
  label: string
  value: string
}
export type SwaggerGradeSummary = {
  columns: PanelGradeSummaryColumn[]
  students_grades: SwaggerStudentsGrades[]
}

export type SwaggerStudentsGrades = {
  student: PanelGradeStudent
  grades: PanelGradeItem[]
  /** @format double */
  attendance_percentage: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Teacher = {
  id: string
  /** @maxLength 16 */
  acronym: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string | null
  is_active?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Timetable = {
  id: string
  public_id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  quarter: string | null
  semester: string
  total_activities: number
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  status_display: string
  generation_task: number
  quarter_str: string
  quarter_short_name: QuarterShortNameEnum
  semester_str: string
  semester_short_name: SemesterShortName
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type TimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type TimetableStats = {
  id: string
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  total_activities: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  generation_task: GenerationTask
  late_activities: string
  students_with_most_hours: string
  teachers_with_most_hours: string
  generation_time: string
}

/**
 * * `live` - Live
 * * `active_in_past` - Active in past
 * * `generation_in_progress` - Generation in progress
 * * `draft` - Draft
 * * `infeasible` - Infeasible data. Please increase time, or verify sections
 * * `failed` - Generation process failed
 */
export type TimetableStatusEnum =
  | 'live'
  | 'active_in_past'
  | 'generation_in_progress'
  | 'draft'
  | 'infeasible'
  | 'failed'

export type TwoFactorLogin =
  | TwoFactorLoginResponse
  | TwoFactorLoginCodeResponse
  | TwoFactorLoginUserResponse

export type TwoFactorLoginCodeResponse = {
  code: number
  user: LoginUserResponse
}

export type TwoFactorLoginResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type TwoFactorLoginUserResponse = {
  user: LoginUserResponse
}

export type UpdateActivitiesRooms = {
  /**
   * @format uri
   * @pattern (?:csv)$
   */
  file: string
}

export type UpdateActivitiesRoomsRequest = {
  /**
   * @format binary
   * @pattern (?:csv)$
   */
  file: File
}

/** * `validation_error` - Validation Error */
export type ValidationErrorEnum = 'validation_error'

import type { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then(response => response.data)
  }
}

type Flatten<T> = T extends any[] ? T[number] : T
type ChangeTypeOfKeys<T extends object, Keys extends keyof T, NewType> = {
  [key in keyof T]: key extends Keys ? NewType : T[key]
}
type MapObjectKeysToTrue<ObjectType extends Record<PropertyKey, any>> = {
  [key in keyof ObjectType]?: ObjectType[key] extends Record<PropertyKey, any>
    ? true | MapObjectKeysToTrue<Flatten<ObjectType[key]>>
    : true
}
type FetchKeysType = { [key in PropertyKey]: true | FetchKeysType }
type FetchKeys<ResponseModel> = ResponseModel extends {
  count?: number
  results?: Array<infer DataModel>
}
  ? DataModel extends Record<PropertyKey, any>
    ? MapObjectKeysToTrue<DataModel>
    : DataModel
  : ResponseModel extends Record<PropertyKey, any>
  ? MapObjectKeysToTrue<Flatten<ResponseModel>>
  : ResponseModel

type NestedSafePick<ResponseModel, FetchKeysType> = ResponseModel extends Array<
  infer Model
>
  ? NestedSafePick<Model, FetchKeysType>[]
  : {
      [key in keyof FetchKeysType]: FetchKeysType[key] extends Record<
        PropertyKey,
        any
      >
        ? key extends keyof ResponseModel
          ? ResponseModel[key] extends Array<infer DataModel>
            ? NestedSafePick<DataModel, FetchKeysType[key]>[]
            : NestedSafePick<ResponseModel[key], FetchKeysType[key]>
          : never
        : key extends keyof ResponseModel
        ? ResponseModel[key] extends Array<infer DataModel>
          ? DataModel[]
          : ResponseModel[key]
        : never
    }

type PickKeys<ResponseModel, FetchKeysType> = {} extends FetchKeysType
  ? ResponseModel
  : ResponseModel extends {
      count?: number
      results?: Array<infer DataModel>
    }
  ? ChangeTypeOfKeys<
      ResponseModel,
      'results',
      NestedSafePick<DataModel, FetchKeysType>[]
    >
  : ResponseModel extends Array<infer DataModel>
  ? NestedSafePick<DataModel, FetchKeysType>[]
  : NestedSafePick<ResponseModel, FetchKeysType>

function postprocessQuery(query?: any) {
  if (!query) return query

  const parsedQuery = query
  if (query.fetchKeys) {
    function getStringifiedQueryKeys(keys: FetchKeysType) {
      let query = '{'
      Object.entries(keys).forEach(([key, value], index) => {
        if (typeof value === 'object') {
          const keyToAdd = `${key}${getStringifiedQueryKeys(value)}`
          query += index > 0 ? `,${keyToAdd}` : keyToAdd
        } else {
          query += index > 0 ? `,${key}` : key
        }
      })
      return query + '}'
    }

    parsedQuery.query = getStringifiedQueryKeys(query.fetchKeys)
  }

  delete parsedQuery.fetchKeys
  return parsedQuery
}

/**
 * @title API* @version 1.0.0 (v1)*/
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  logs = {}
  mobile = {}
  panel = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesList
     * @request GET:/panel/absences/
     */
    panelAbsencesList: <T extends FetchKeys<PaginatedPanelAbsenceList>>(
      query?: {
        class_id?: (string | null)[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        /**
         * * `excused` - Excused
         * * `unexcused` - Unexcused
         */
        status?: ('excused' | 'unexcused')[]
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelAbsenceList, T>, any>({
        path: `/panel/absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesRetrieve
     * @request GET:/panel/absences/{id}/
     */,
    panelAbsencesRetrieve: <T extends FetchKeys<PanelAbsence>>(
      id: string,
      query?: {
        class_id?: (string | null)[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        semester_id?: string
        /**
         * * `excused` - Excused
         * * `unexcused` - Unexcused
         */
        status?: ('excused' | 'unexcused')[]
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAbsence, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesUpdate
     * @request PUT:/panel/absences/{id}/
     */,
    panelAbsencesUpdate: <T extends FetchKeys<void>>(
      id: string,
      data: PanelAbsenceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesPartialUpdate
     * @request PATCH:/panel/absences/{id}/
     */,
    panelAbsencesPartialUpdate: <T extends FetchKeys<PanelAbsenceUpdate>>(
      id: string,
      data: PatchedPanelAbsenceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAbsenceUpdate, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesMarkAsPresentCreate
     * @request POST:/panel/absences/{id}/mark-as-present/
     */,
    panelAbsencesMarkAsPresentCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/absences/${id}/mark-as-present/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAbsencesOptionsClassesList
     * @request GET:/panel/absences/options/classes/
     */,
    panelAbsencesOptionsClassesList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/absences/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAbsencesOptionsStatusList
     * @request GET:/panel/absences/options/status/
     */,
    panelAbsencesOptionsStatusList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/absences/options/status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsList
     * @request GET:/panel/assignments/
     */,
    panelAssignmentsList: <T extends FetchKeys<PaginatedPanelAssignmentList>>(
      query?: {
        group_id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelAssignmentList, T>, any>({
        path: `/panel/assignments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsCreate
     * @request POST:/panel/assignments/
     */,
    panelAssignmentsCreate: <T extends FetchKeys<PanelAssignment>>(
      data: PanelAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignment, T>, any>({
        path: `/panel/assignments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsRetrieve
     * @request GET:/panel/assignments/{id}/
     */,
    panelAssignmentsRetrieve: <T extends FetchKeys<PanelAssignment>>(
      id: string,
      query?: {
        group_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignment, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsUpdate
     * @request PUT:/panel/assignments/{id}/
     */,
    panelAssignmentsUpdate: <T extends FetchKeys<PanelAssignmentUpdate>>(
      id: string,
      data: PanelAssignmentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentUpdate, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsPartialUpdate
     * @request PATCH:/panel/assignments/{id}/
     */,
    panelAssignmentsPartialUpdate: <T extends FetchKeys<PanelAssignmentUpdate>>(
      id: string,
      data: PatchedPanelAssignmentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentUpdate, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsDestroy
     * @request DELETE:/panel/assignments/{id}/
     */,
    panelAssignmentsDestroy: <T extends FetchKeys<PanelAssignmentDelete>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentDelete, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'DELETE',
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttachmentsCreate
     * @request POST:/panel/attachments/
     */,
    panelAttachmentsCreate: <T extends FetchKeys<PanelAttachmentCreate>>(
      data: PanelAttachmentCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAttachmentCreate, T>, any>({
        path: `/panel/attachments/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      })
    /**
     * @description Handle incoming GET requests
     *
     * @tags panel
     * @name PanelAttachmentsDownloadRetrieve
     * @request GET:/panel/attachments/download/{id}/
     */,
    panelAttachmentsDownloadRetrieve: <T extends FetchKeys<File>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<File, T>, any>({
        path: `/panel/attachments/download/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesList
     * @request GET:/panel/attendances/
     */,
    panelAttendancesList: <
      T extends FetchKeys<PaginatedAcademicAttendanceList>
    >(
      query?: {
        lesson_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceList, T>, any>({
        path: `/panel/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesRetrieve
     * @request GET:/panel/attendances/{id}/
     */,
    panelAttendancesRetrieve: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      query?: {
        lesson_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesUpdate
     * @request PUT:/panel/attendances/{id}/
     */,
    panelAttendancesUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: AcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesPartialUpdate
     * @request PATCH:/panel/attendances/{id}/
     */,
    panelAttendancesPartialUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: PatchedAcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthLoginCreate
     * @request POST:/panel/auth/login/
     * @secure
     */,
    panelAuthLoginCreate: <T extends FetchKeys<PanelLoginResponse>>(
      data: PanelLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PanelLoginResponse, T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/panel/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthLoginValidateCodeCreate
     * @request POST:/panel/auth/login/validate-code/
     * @secure
     */,
    panelAuthLoginValidateCodeCreate: <
      T extends FetchKeys<PanelLoginTokenResponse>
    >(
      data: PanelLoginValidateCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PanelLoginTokenResponse, T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/panel/auth/login/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthNewPasswordCreate
     * @request POST:/panel/auth/new-password/
     * @secure
     */,
    panelAuthNewPasswordCreate: <T extends FetchKeys<void>>(
      data: PanelNewPasswordRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/auth/new-password/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthNewPasswordValidateCodeCreate
     * @request POST:/panel/auth/new-password/validate-code/
     * @secure
     */,
    panelAuthNewPasswordValidateCodeCreate: <T extends FetchKeys<void>>(
      data: PanelNewPasswordConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/auth/new-password/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesList
     * @request GET:/panel/classes/
     */,
    panelClassesList: <T extends FetchKeys<PaginatedPanelStudentClassList>>(
      query?: {
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelStudentClassList, T>, any>({
        path: `/panel/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/
     */,
    panelClassesGroupsList: <T extends FetchKeys<PaginatedPanelClassGroupList>>(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelClassGroupList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsRetrieve
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options
     */,
    panelClassesGroupsOptionsRetrieve: <
      T extends FetchKeys<PanelClassGroupOptions>
    >(
      classId: string,
      periodId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelClassGroupOptions, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsCoursesList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/courses/
     */,
    panelClassesGroupsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsGroupsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/groups/
     */,
    panelClassesGroupsOptionsGroupsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsStudentsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/students/
     */,
    panelClassesGroupsOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsTeachersList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/teachers/
     */,
    panelClassesGroupsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesRetrieve
     * @request GET:/panel/classes/{id}/
     */,
    panelClassesRetrieve: <T extends FetchKeys<PanelStudentClassDetail>>(
      id: string,
      query?: {
        id?: string[]
        student_id?: string[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudentClassDetail, T>, any>({
        path: `/panel/classes/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsList
     * @request GET:/panel/comments/
     */,
    panelCommentsList: <T extends FetchKeys<PaginatedPanelCommentList>>(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelCommentList, T>, any>({
        path: `/panel/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsCreate
     * @request POST:/panel/comments/
     */,
    panelCommentsCreate: <T extends FetchKeys<PanelCommentCreate>>(
      data: PanelCommentCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCommentCreate, T>, any>({
        path: `/panel/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsRetrieve
     * @request GET:/panel/comments/{id}/
     */,
    panelCommentsRetrieve: <T extends FetchKeys<PanelComment>>(
      id: string,
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelComment, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsUpdate
     * @request PUT:/panel/comments/{id}/
     */,
    panelCommentsUpdate: <T extends FetchKeys<PanelCommentUpdate>>(
      id: string,
      data: PanelCommentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCommentUpdate, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsPartialUpdate
     * @request PATCH:/panel/comments/{id}/
     */,
    panelCommentsPartialUpdate: <T extends FetchKeys<PanelCommentUpdate>>(
      id: string,
      data: PatchedPanelCommentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCommentUpdate, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsDestroy
     * @request DELETE:/panel/comments/{id}/
     */,
    panelCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCommentsOptionsAddedByList
     * @request GET:/panel/comments/options/added-by/
     */,
    panelCommentsOptionsAddedByList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/comments/options/added-by/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCommentsOptionsCommentTypeList
     * @request GET:/panel/comments/options/comment-type/
     */,
    panelCommentsOptionsCommentTypeList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/comments/options/comment-type/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCoursesList
     * @request GET:/panel/courses/
     */,
    panelCoursesList: <T extends FetchKeys<PaginatedPanelCourseList>>(
      query?: {
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelCourseList, T>, any>({
        path: `/panel/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCoursesRetrieve
     * @request GET:/panel/courses/{id}/
     */,
    panelCoursesRetrieve: <T extends FetchKeys<PanelCourse>>(
      id: string,
      query?: {
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        student_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCourse, T>, any>({
        path: `/panel/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/
     */,
    panelCoursesSummaryList: <T extends FetchKeys<PanelCourseSummary[]>>(
      semesterId: string,
      studentId: string,
      query?: {
        course_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCourseSummary[], T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryOptionsRetrieve
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/options/
     */,
    panelCoursesSummaryOptionsRetrieve: <
      T extends FetchKeys<PanelCourseSummaryOptions>
    >(
      semesterId: string,
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCourseSummaryOptions, T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryOptionsCoursesList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/options/courses/
     */,
    panelCoursesSummaryOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      semesterId: string,
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryOptionsTeachersList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/options/teachers/
     */,
    panelCoursesSummaryOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      semesterId: string,
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesCreate
     * @request POST:/panel/grades/
     */,
    panelGradesCreate: <T extends FetchKeys<PanelGrade>>(
      data: PanelGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGrade, T>, any>({
        path: `/panel/grades/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesRetrieve
     * @request GET:/panel/grades/{id}/
     */,
    panelGradesRetrieve: <T extends FetchKeys<PanelGrade>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGrade, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesUpdate
     * @request PUT:/panel/grades/{id}/
     */,
    panelGradesUpdate: <T extends FetchKeys<PanelGradeUpdate>>(
      id: string,
      data: PanelGradeUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeUpdate, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesPartialUpdate
     * @request PATCH:/panel/grades/{id}/
     */,
    panelGradesPartialUpdate: <T extends FetchKeys<PanelGradeUpdate>>(
      id: string,
      data: PatchedPanelGradeUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeUpdate, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesDestroy
     * @request DELETE:/panel/grades/{id}/
     */,
    panelGradesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsUpdate
     * @request PUT:/panel/grades/grade-items/{id}/
     */,
    panelGradesGradeItemsUpdate: <T extends FetchKeys<PanelGradeItem>>(
      id: string,
      data: PanelGradeItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeItem, T>, any>({
        path: `/panel/grades/grade-items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsPartialUpdate
     * @request PATCH:/panel/grades/grade-items/{id}/
     */,
    panelGradesGradeItemsPartialUpdate: <T extends FetchKeys<PanelGradeItem>>(
      id: string,
      data: PatchedPanelGradeItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeItem, T>, any>({
        path: `/panel/grades/grade-items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsBulkUpdate
     * @request PUT:/panel/grades/grade-items/bulk/
     */,
    panelGradesGradeItemsBulkUpdate: <
      T extends FetchKeys<PanelGradeItemBulkUpdate>
    >(
      data: PanelGradeItemBulkUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeItemBulkUpdate, T>, any>({
        path: `/panel/grades/grade-items/bulk/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelGradesGradeMarksList
     * @request GET:/panel/grades/grade-marks/
     */,
    panelGradesGradeMarksList: <
      T extends FetchKeys<PaginatedPanelGradeMarkList>
    >(
      query?: {
        for_behaviour?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelGradeMarkList, T>, any>({
        path: `/panel/grades/grade-marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelGradesGradeMarksRetrieve
     * @request GET:/panel/grades/grade-marks/{id}/
     */,
    panelGradesGradeMarksRetrieve: <T extends FetchKeys<PanelGradeMark>>(
      id: string,
      query?: {
        for_behaviour?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeMark, T>, any>({
        path: `/panel/grades/grade-marks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesSummaryRetrieve
     * @request GET:/panel/grades/summary/{groupId}/{periodId}/
     */,
    panelGradesSummaryRetrieve: <T extends FetchKeys<SwaggerGradeSummary>>(
      groupId: string,
      periodId: string,
      query?: {
        /** Filter by class id. */
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SwaggerGradeSummary, T>, any>({
        path: `/panel/grades/summary/${groupId}/${periodId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsList
     * @request GET:/panel/lessons/
     */,
    panelLessonsList: <T extends FetchKeys<PaginatedPanelLessonList>>(
      query?: {
        any_teacher_id?: string[]
        class_id?: string[]
        course_id?: string[]
        covered_or_covering_teacher_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string[]
        is_cancelled?: boolean
        is_covered?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        show_past_data?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonList, T>, any>({
        path: `/panel/lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsRetrieve
     * @request GET:/panel/lessons/{id}/
     */,
    panelLessonsRetrieve: <T extends FetchKeys<PanelLesson>>(
      id: string,
      query?: {
        any_teacher_id?: string[]
        class_id?: string[]
        course_id?: string[]
        covered_or_covering_teacher_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string[]
        is_cancelled?: boolean
        is_covered?: boolean
        semester_id?: string
        show_past_data?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLesson, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsUpdate
     * @request PUT:/panel/lessons/{id}/
     */,
    panelLessonsUpdate: <T extends FetchKeys<PanelLessonUpdate>>(
      id: string,
      data: PanelLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonUpdate, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsPartialUpdate
     * @request PATCH:/panel/lessons/{id}/
     */,
    panelLessonsPartialUpdate: <T extends FetchKeys<PanelLessonUpdate>>(
      id: string,
      data: PatchedPanelLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonUpdate, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCancelCreate
     * @request POST:/panel/lessons/{id}/cancel/
     */,
    panelLessonsCancelCreate: <T extends FetchKeys<void>>(
      id: string,
      data: PanelLessonCancelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsClearAttendancePartialUpdate
     * @request PATCH:/panel/lessons/{id}/clear-attendance/
     */,
    panelLessonsClearAttendancePartialUpdate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/clear-attendance/`,
        method: 'PATCH',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCoverPartialUpdate
     * @request PATCH:/panel/lessons/{id}/cover/
     */,
    panelLessonsCoverPartialUpdate: <
      T extends FetchKeys<PanelLessonUpdateCover>
    >(
      id: string,
      data: PatchedPanelLessonUpdateCoverRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonUpdateCover, T>, any>({
        path: `/panel/lessons/${id}/cover/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCoverDestroy
     * @request DELETE:/panel/lessons/{id}/cover/
     */,
    panelLessonsCoverDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/cover/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsUncancelCreate
     * @request POST:/panel/lessons/{id}/uncancel/
     */,
    panelLessonsUncancelCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/uncancel/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsBulkCoverAndCancelCreate
     * @request POST:/panel/lessons/bulk-cover-and-cancel/
     */,
    panelLessonsBulkCoverAndCancelCreate: <T extends FetchKeys<void>>(
      data: PanelLessonBulkCoverAndCancelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/bulk-cover-and-cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsGroupsList
     * @request GET:/panel/lessons/groups/
     */,
    panelLessonsGroupsList: <
      T extends FetchKeys<PaginatedPanelLessonGroupList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonGroupList, T>, any>({
        path: `/panel/lessons/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsGroupsRetrieve
     * @request GET:/panel/lessons/groups/{id}/
     */,
    panelLessonsGroupsRetrieve: <T extends FetchKeys<PanelLessonGroupDetails>>(
      id: string,
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonGroupDetails, T>, any>({
        path: `/panel/lessons/groups/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsRetrieve
     * @request GET:/panel/lessons/groups/options/
     */,
    panelLessonsGroupsOptionsRetrieve: <
      T extends FetchKeys<PanelLessonGroupOptions>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonGroupOptions, T>, any>({
        path: `/panel/lessons/groups/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsClassesList
     * @request GET:/panel/lessons/groups/options/classes/
     */,
    panelLessonsGroupsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsCoursesList
     * @request GET:/panel/lessons/groups/options/courses/
     */,
    panelLessonsGroupsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsGroupsList
     * @request GET:/panel/lessons/groups/options/groups/
     */,
    panelLessonsGroupsOptionsGroupsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsStudentsList
     * @request GET:/panel/lessons/groups/options/students/
     */,
    panelLessonsGroupsOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsTeachersList
     * @request GET:/panel/lessons/groups/options/teachers/
     */,
    panelLessonsGroupsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsRetrieve
     * @request GET:/panel/lessons/options/
     */,
    panelLessonsOptionsRetrieve: <T extends FetchKeys<void>>(
      query?: {
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsClassesList
     * @request GET:/panel/lessons/options/classes/
     */,
    panelLessonsOptionsClassesList: <
      T extends FetchKeys<PaginatedPanelLessonGroupStudentClassList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonGroupStudentClassList, T>, any>(
        {
          path: `/panel/lessons/options/classes/`,
          method: 'GET',
          query: postprocessQuery(query),
          format: 'json',
          ...params
        }
      )
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsCoursesList
     * @request GET:/panel/lessons/options/courses/
     */,
    panelLessonsOptionsCoursesList: <
      T extends FetchKeys<PaginatedPanelLessonCourseList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonCourseList, T>, any>({
        path: `/panel/lessons/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsGroupsList
     * @request GET:/panel/lessons/options/groups/
     */,
    panelLessonsOptionsGroupsList: <
      T extends FetchKeys<PaginatedPanelLessonGroupOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonGroupOptionList, T>, any>({
        path: `/panel/lessons/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsTeachersList
     * @request GET:/panel/lessons/options/teachers/
     */,
    panelLessonsOptionsTeachersList: <
      T extends FetchKeys<PaginatedPanelLessonTeacherList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonTeacherList, T>, any>({
        path: `/panel/lessons/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesList
     * @request GET:/panel/old-planner/activities/
     */,
    panelOldPlannerActivitiesList: <
      T extends FetchKeys<PaginatedDraftActivityListList>
    >(
      query?: {
        course?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftActivityListList, T>, any>({
        path: `/panel/old-planner/activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesCreate
     * @request POST:/panel/old-planner/activities/
     */,
    panelOldPlannerActivitiesCreate: <T extends FetchKeys<DraftActivityCreate>>(
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesUpdate
     * @request PUT:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesUpdate: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesPartialUpdate
     * @request PATCH:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesPartialUpdate: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      data: PatchedDraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesDestroy
     * @request DELETE:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesRoomsPreferencesRetrieve
     * @request GET:/panel/old-planner/activities/{id}/rooms-preferences/
     */,
    panelOldPlannerActivitiesRoomsPreferencesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/rooms-preferences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesAvailableFiltersRetrieve
     * @request GET:/panel/old-planner/activities/available-filters/
     */,
    panelOldPlannerActivitiesAvailableFiltersRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesCountRetrieve
     * @request GET:/panel/old-planner/activities/count/
     */,
    panelOldPlannerActivitiesCountRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesDaysRetrieve
     * @request GET:/panel/old-planner/activities/days/
     */,
    panelOldPlannerActivitiesDaysRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/days/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Pass timetable and hour in query param
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportActivitiesAfterHourRetrieve
     * @request GET:/panel/old-planner/activities/export-activities-after-hour/
     */,
    panelOldPlannerActivitiesExportActivitiesAfterHourRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-activities-after-hour/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportActivitiesPerSlotRetrieve
     * @request GET:/panel/old-planner/activities/export-activities-per-slot/
     */,
    panelOldPlannerActivitiesExportActivitiesPerSlotRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-activities-per-slot/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportStudentActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/export-student-activities/
     */,
    panelOldPlannerActivitiesExportStudentActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-student-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportTeacherActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/export-teacher-activities/
     */,
    panelOldPlannerActivitiesExportTeacherActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-teacher-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesHoursRetrieve
     * @request GET:/panel/old-planner/activities/hours/
     */,
    panelOldPlannerActivitiesHoursRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/hours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesUpdateActivitiesRoomsCreate
     * @request POST:/panel/old-planner/activities/update-activities-rooms/
     */,
    panelOldPlannerActivitiesUpdateActivitiesRoomsCreate: <
      T extends FetchKeys<UpdateActivitiesRooms>
    >(
      data: UpdateActivitiesRoomsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UpdateActivitiesRooms, T>, any>({
        path: `/panel/old-planner/activities/update-activities-rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerAuthLoginCreate
     * @request POST:/panel/old-planner/auth/login/
     * @secure
     */,
    panelOldPlannerAuthLoginCreate: <T extends FetchKeys<TwoFactorLogin>>(
      data: AuthTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TwoFactorLogin, T>, any>({
        path: `/panel/old-planner/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerAuthLogoutCreate
     * @request POST:/panel/old-planner/auth/logout/
     * @secure
     */,
    panelOldPlannerAuthLogoutCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/auth/logout/`,
        method: 'POST',
        secure: true,
        ...params
      })
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags panel
     * @name PanelOldPlannerConflictsRetrieve
     * @request GET:/panel/old-planner/conflicts/{id}/{day}/
     */,
    panelOldPlannerConflictsRetrieve: <T extends FetchKeys<void>>(
      day: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/conflicts/${id}/${day}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesList
     * @request GET:/panel/old-planner/courses/
     */,
    panelOldPlannerCoursesList: <
      T extends FetchKeys<PaginatedCoursePlannerListList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCoursePlannerListList, T>, any>({
        path: `/panel/old-planner/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesCreate
     * @request POST:/panel/old-planner/courses/
     */,
    panelOldPlannerCoursesCreate: <T extends FetchKeys<CoursePlanner>>(
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesRetrieve
     * @request GET:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesRetrieve: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesUpdate
     * @request PUT:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesPartialUpdate
     * @request PATCH:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesPartialUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: PatchedCoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesDestroy
     * @request DELETE:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsList
     * @request GET:/panel/old-planner/courses/subjects/
     */,
    panelOldPlannerCoursesSubjectsList: <
      T extends FetchKeys<PaginatedCourseSubjectList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseSubjectList, T>, any>({
        path: `/panel/old-planner/courses/subjects/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsCreate
     * @request POST:/panel/old-planner/courses/subjects/
     */,
    panelOldPlannerCoursesSubjectsCreate: <T extends FetchKeys<CourseSubject>>(
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsRetrieve
     * @request GET:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsRetrieve: <
      T extends FetchKeys<CourseSubject>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsUpdate
     * @request PUT:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsUpdate: <T extends FetchKeys<CourseSubject>>(
      id: string,
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsPartialUpdate
     * @request PATCH:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsPartialUpdate: <
      T extends FetchKeys<CourseSubject>
    >(
      id: string,
      data: PatchedCourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsDestroy
     * @request DELETE:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorCallbackCreate
     * @request POST:/panel/old-planner/generator/callback
     */,
    panelOldPlannerGeneratorCallbackCreate: <T extends FetchKeys<Callback>>(
      data: CallbackRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Callback, T>, any>({
        path: `/panel/old-planner/generator/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorStatusesRetrieve
     * @request GET:/panel/old-planner/generator/statuses
     */,
    panelOldPlannerGeneratorStatusesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/generator/statuses`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksList
     * @request GET:/panel/old-planner/generator/tasks/
     */,
    panelOldPlannerGeneratorTasksList: <
      T extends FetchKeys<PaginatedGenerationTaskList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGenerationTaskList, T>, any>({
        path: `/panel/old-planner/generator/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksCreate
     * @request POST:/panel/old-planner/generator/tasks/
     */,
    panelOldPlannerGeneratorTasksCreate: <T extends FetchKeys<GenerationTask>>(
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksRetrieve
     * @request GET:/panel/old-planner/generator/tasks/{id}/
     */,
    panelOldPlannerGeneratorTasksRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: {
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksStatsRetrieve
     * @request GET:/panel/old-planner/generator/tasks/{id}/stats/
     */,
    panelOldPlannerGeneratorTasksStatsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksStopCreate
     * @request POST:/panel/old-planner/generator/tasks/{id}/stop/
     */,
    panelOldPlannerGeneratorTasksStopCreate: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/stop/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksCostsRetrieve
     * @request GET:/panel/old-planner/generator/tasks/costs/
     */,
    panelOldPlannerGeneratorTasksCostsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      query?: {
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/costs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingCeleryTaskStatusList
     * @request GET:/panel/old-planner/importing/celery-task-status/
     */,
    panelOldPlannerImportingCeleryTaskStatusList: <
      T extends FetchKeys<PaginatedAdminImportedTimetableList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminImportedTimetableList, T>, any>({
        path: `/panel/old-planner/importing/celery-task-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingCeleryTaskStatusRetrieve
     * @request GET:/panel/old-planner/importing/celery-task-status/{id}/
     */,
    panelOldPlannerImportingCeleryTaskStatusRetrieve: <
      T extends FetchKeys<AdminImportedTimetable>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminImportedTimetable, T>, any>({
        path: `/panel/old-planner/importing/celery-task-status/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingImportTimetableCreate
     * @request POST:/panel/old-planner/importing/import-timetable/
     */,
    panelOldPlannerImportingImportTimetableCreate: <T extends FetchKeys<void>>(
      data: AdminImportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/importing/import-timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsList
     * @request GET:/panel/old-planner/potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsList: <
      T extends FetchKeys<PaginatedPotentialOptimizationList>
    >(
      query?: {
        /**
         * * `0` - 8:00-8:40
         * * `1` - 8:45-9:25
         * * `2` - 9:30-10:10
         * * `3` - 10:30-11:10
         * * `4` - 11:15-11:55
         * * `5` - 13:00-13:40
         * * `6` - 13:45-14:25
         * * `7` - 14:30-15:10
         * * `8` - 15:15-15:55
         * * `9` - 16:00-16:40
         * * `10` - 16:45-17:25
         * * `11` - 17:30-18:10
         * * `12` - 18:15-18:55
         * * `13` - 19:00-19:40
         * * `14` - 19:40-20:30
         * * `15` - 20:30-21:00
         */
        activity__hour?:
          | '0'
          | '1'
          | '10'
          | '11'
          | '12'
          | '13'
          | '14'
          | '15'
          | '2'
          | '3'
          | '4'
          | '5'
          | '6'
          | '7'
          | '8'
          | '9'
          | null
        /**
         * * `0` - Monday
         * * `1` - Tuesday
         * * `2` - Wednesday
         * * `3` - Thursday
         * * `4` - Friday
         * * `5` - Saturday
         * * `6` - Sunday
         */
        day?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        /**
         * * `0` - 8:00-8:40
         * * `1` - 8:45-9:25
         * * `2` - 9:30-10:10
         * * `3` - 10:30-11:10
         * * `4` - 11:15-11:55
         * * `5` - 13:00-13:40
         * * `6` - 13:45-14:25
         * * `7` - 14:30-15:10
         * * `8` - 15:15-15:55
         * * `9` - 16:00-16:40
         * * `10` - 16:45-17:25
         * * `11` - 17:30-18:10
         * * `12` - 18:15-18:55
         * * `13` - 19:00-19:40
         * * `14` - 19:40-20:30
         * * `15` - 20:30-21:00
         */
        hour?:
          | '0'
          | '1'
          | '10'
          | '11'
          | '12'
          | '13'
          | '14'
          | '15'
          | '2'
          | '3'
          | '4'
          | '5'
          | '6'
          | '7'
          | '8'
          | '9'
          | null
        no_teacher?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        timetable?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPotentialOptimizationList, T>, any>({
        path: `/panel/old-planner/potential-optimizations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsUpdate
     * @request PUT:/panel/old-planner/potential-optimizations/{id}/apply-potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsUpdate: <
      T extends FetchKeys<void>
    >(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/potential-optimizations/${id}/apply-potential-optimizations/`,
        method: 'PUT',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate
     * @request PATCH:/panel/old-planner/potential-optimizations/{id}/apply-potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate:
      <T extends FetchKeys<void>>(id: string, params: RequestParams = {}) =>
        this.request<PickKeys<void, T>, any>({
          path: `/panel/old-planner/potential-optimizations/${id}/apply-potential-optimizations/`,
          method: 'PATCH',
          ...params
        })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsActivitiesMovesList
     * @request GET:/panel/old-planner/potential-optimizations/activities/{id}/moves/
     */,
    panelOldPlannerPotentialOptimizationsActivitiesMovesList: <
      T extends FetchKeys<PaginatedActivityMoveList>
    >(
      id: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedActivityMoveList, T>, any>({
        path: `/panel/old-planner/potential-optimizations/activities/${id}/moves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags panel
     * @name PanelOldPlannerRoomsAssignerCreate
     * @request POST:/panel/old-planner/rooms-assigner/{id}/
     */,
    panelOldPlannerRoomsAssignerCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/rooms-assigner/${id}/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsList
     * @request GET:/panel/old-planner/sections/
     */,
    panelOldPlannerSectionsList: <
      T extends FetchKeys<PaginatedSectionListList>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSectionListList, T>, any>({
        path: `/panel/old-planner/sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsCreate
     * @request POST:/panel/old-planner/sections/
     */,
    panelOldPlannerSectionsCreate: <T extends FetchKeys<Section>>(
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsRetrieve
     * @request GET:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsRetrieve: <T extends FetchKeys<Section>>(
      id: string,
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsUpdate
     * @request PUT:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsUpdate: <T extends FetchKeys<Section>>(
      id: string,
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsPartialUpdate
     * @request PATCH:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsPartialUpdate: <T extends FetchKeys<Section>>(
      id: string,
      data: PatchedSectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsDestroy
     * @request DELETE:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsAllowedStudentsList
     * @request GET:/panel/old-planner/sections/{id}/allowed-students/
     */,
    panelOldPlannerSectionsAllowedStudentsList: <
      T extends FetchKeys<StringOption[]>
    >(
      id: string,
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/old-planner/sections/${id}/allowed-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportStudentsEnrollmentsRetrieve
     * @request GET:/panel/old-planner/sections/export-students-enrollments/
     */,
    panelOldPlannerSectionsExportStudentsEnrollmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-students-enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportStudentsInSectionsRetrieve
     * @request GET:/panel/old-planner/sections/export-students-in-sections/
     */,
    panelOldPlannerSectionsExportStudentsInSectionsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-students-in-sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportTeachersEnrolmentsRetrieve
     * @request GET:/panel/old-planner/sections/export-teachers-enrolments/
     */,
    panelOldPlannerSectionsExportTeachersEnrolmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-teachers-enrolments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Sections with students and scorings
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSectionToEnrollList
     * @request GET:/panel/old-planner/sections/section-to-enroll/
     */,
    panelOldPlannerSectionsSectionToEnrollList: <
      T extends FetchKeys<SectionToEnroll[]>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        quarter?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SectionToEnroll[], T>, any>({
        path: `/panel/old-planner/sections/section-to-enroll/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Sections API without enrollments
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSimpleList
     * @request GET:/panel/old-planner/sections/simple/
     */,
    panelOldPlannerSectionsSimpleList: <
      T extends FetchKeys<PaginatedSimpleSectionList>
    >(
      query?: {
        course?: string
        /** multi value filter: id1,id2,id3 */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSimpleSectionList, T>, any>({
        path: `/panel/old-planner/sections/simple/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Sections API without enrollments
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSimpleRetrieve
     * @request GET:/panel/old-planner/sections/simple/{id}/
     */,
    panelOldPlannerSectionsSimpleRetrieve: <T extends FetchKeys<SimpleSection>>(
      id: string,
      query?: {
        course?: string
        /** Multiple values may be separated by commas. */
        id?: string[]
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSection, T>, any>({
        path: `/panel/old-planner/sections/simple/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsTotalDurationRetrieve
     * @request GET:/panel/old-planner/sections/total-duration/
     */,
    panelOldPlannerSectionsTotalDurationRetrieve: <
      T extends FetchKeys<Section>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/total-duration/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableList
     * @request GET:/panel/old-planner/timetable/
     */,
    panelOldPlannerTimetableList: <T extends FetchKeys<PaginatedTimetableList>>(
      query?: {
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        semester?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableList, T>, any>({
        path: `/panel/old-planner/timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableCreate
     * @request POST:/panel/old-planner/timetable/
     */,
    panelOldPlannerTimetableCreate: <T extends FetchKeys<Timetable>>(
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableRetrieve: <T extends FetchKeys<Timetable>>(
      id: string,
      query?: {
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        semester?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableUpdate
     * @request PUT:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableUpdate: <T extends FetchKeys<Timetable>>(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetablePartialUpdate
     * @request PATCH:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetablePartialUpdate: <T extends FetchKeys<Timetable>>(
      id: string,
      data: PatchedTimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableDestroy
     * @request DELETE:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableBusyInSlotCreate
     * @request POST:/panel/old-planner/timetable/{id}/busy-in-slot/
     */,
    panelOldPlannerTimetableBusyInSlotCreate: <
      T extends FetchKeys<BusyInSlotResponse>
    >(
      id: string,
      data: BusyInSlotRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BusyInSlotResponse, T>, any>({
        path: `/panel/old-planner/timetable/${id}/busy-in-slot/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableCollectPotentialOptimizationsCreate
     * @request POST:/panel/old-planner/timetable/{id}/collect-potential-optimizations/
     */,
    panelOldPlannerTimetableCollectPotentialOptimizationsCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/collect-potential-optimizations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableFilterOptionsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/filter-options/
     */,
    panelOldPlannerTimetableFilterOptionsRetrieve: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableFreeSlotsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/free-slots/
     */,
    panelOldPlannerTimetableFreeSlotsRetrieve: <T extends FetchKeys<FreeSlot>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FreeSlot, T>, any>({
        path: `/panel/old-planner/timetable/${id}/free-slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableStatsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/stats/
     */,
    panelOldPlannerTimetableStatsRetrieve: <
      T extends FetchKeys<TimetableStats>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableStats, T>, any>({
        path: `/panel/old-planner/timetable/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableTogglePreviewForTeachersCreate
     * @request POST:/panel/old-planner/timetable/{id}/toggle-preview-for-teachers/
     */,
    panelOldPlannerTimetableTogglePreviewForTeachersCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/toggle-preview-for-teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerTimetableCurrentLessonsList
     * @request GET:/panel/old-planner/timetable/current-lessons/
     */,
    panelOldPlannerTimetableCurrentLessonsList: <
      T extends FetchKeys<CurrentLesson[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CurrentLesson[], T>, any>({
        path: `/panel/old-planner/timetable/current-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsList
     * @request GET:/panel/parents/
     */,
    panelParentsList: <T extends FetchKeys<PaginatedPanelParentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelParentList, T>, any>({
        path: `/panel/parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsRetrieve
     * @request GET:/panel/parents/{user}/
     */,
    panelParentsRetrieve: <T extends FetchKeys<PanelParent>>(
      user: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelParent, T>, any>({
        path: `/panel/parents/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsBlockCreate
     * @request POST:/panel/parents/{user}/block/
     */,
    panelParentsBlockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsSendActivationLinkCreate
     * @request POST:/panel/parents/{user}/send-activation-link/
     */,
    panelParentsSendActivationLinkCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/send-activation-link/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsUnblockCreate
     * @request POST:/panel/parents/{user}/unblock/
     */,
    panelParentsUnblockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsQuartersList
     * @request GET:/panel/periods/quarters/
     */,
    panelPeriodsQuartersList: <T extends FetchKeys<PaginatedQuarterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedQuarterList, T>, any>({
        path: `/panel/periods/quarters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsSemestersList
     * @request GET:/panel/periods/semesters/
     */,
    panelPeriodsSemestersList: <T extends FetchKeys<PaginatedSemesterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSemesterList, T>, any>({
        path: `/panel/periods/semesters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsYearsList
     * @request GET:/panel/periods/years/
     */,
    panelPeriodsYearsList: <T extends FetchKeys<PaginatedSchoolYearList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolYearList, T>, any>({
        path: `/panel/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsList
     * @request GET:/panel/rooms/
     */,
    panelRoomsList: <T extends FetchKeys<PaginatedRoomList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRoomList, T>, any>({
        path: `/panel/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsCreate
     * @request POST:/panel/rooms/
     */,
    panelRoomsCreate: <T extends FetchKeys<Room>>(
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/panel/rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsRetrieve
     * @request GET:/panel/rooms/{id}/
     */,
    panelRoomsRetrieve: <T extends FetchKeys<Room>>(
      id: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsUpdate
     * @request PUT:/panel/rooms/{id}/
     */,
    panelRoomsUpdate: <T extends FetchKeys<Room>>(
      id: string,
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsPartialUpdate
     * @request PATCH:/panel/rooms/{id}/
     */,
    panelRoomsPartialUpdate: <T extends FetchKeys<Room>>(
      id: string,
      data: PatchedRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsDestroy
     * @request DELETE:/panel/rooms/{id}/
     */,
    panelRoomsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsList
     * @request GET:/panel/students/
     */,
    panelStudentsList: <T extends FetchKeys<PaginatedPanelStudentList>>(
      query?: {
        class_id?: (string | null)[]
        group_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelStudentList, T>, any>({
        path: `/panel/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsRetrieve
     * @request GET:/panel/students/{user}/
     */,
    panelStudentsRetrieve: <T extends FetchKeys<PanelStudent>>(
      user: string,
      query?: {
        class_id?: (string | null)[]
        group_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudent, T>, any>({
        path: `/panel/students/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsBlockCreate
     * @request POST:/panel/students/{user}/block/
     */,
    panelStudentsBlockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/students/${user}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsUnblockCreate
     * @request POST:/panel/students/{user}/unblock/
     */,
    panelStudentsUnblockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/students/${user}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsClassesList
     * @request GET:/panel/students/classes/
     */,
    panelStudentsClassesList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/students/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsClassesList
     * @request GET:/panel/students/options/classes/
     */,
    panelStudentsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: (string | null)[]
        group_id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsStatusesList
     * @request GET:/panel/students/options/statuses/
     */,
    panelStudentsOptionsStatusesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsYearsList
     * @request GET:/panel/students/options/years/
     */,
    panelStudentsOptionsYearsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: (string | null)[]
        group_id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsSendActivationLinkCreate
     * @request POST:/panel/students/send-activation-link/
     */,
    panelStudentsSendActivationLinkCreate: <
      T extends FetchKeys<PanelSendActivationLinkResponse>
    >(
      data: PanelStudentSendActivationLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelSendActivationLinkResponse, T>, any>({
        path: `/panel/students/send-activation-link/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsStatusesList
     * @request GET:/panel/students/statuses/
     */,
    panelStudentsStatusesList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/students/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsYearsList
     * @request GET:/panel/students/years/
     */,
    panelStudentsYearsList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/students/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelTeachersList
     * @request GET:/panel/teachers/
     */,
    panelTeachersList: <T extends FetchKeys<PaginatedTeacherList>>(
      query?: {
        is_tutor?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeacherList, T>, any>({
        path: `/panel/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelTeachersRetrieve
     * @request GET:/panel/teachers/{user}/
     */,
    panelTeachersRetrieve: <T extends FetchKeys<Teacher>>(
      user: string,
      query?: {
        is_tutor?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Teacher, T>, any>({
        path: `/panel/teachers/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTeachersAvailabilityRetrieve
     * @request GET:/panel/teachers/{user}/availability/{startDate}/{endDate}/
     */,
    panelTeachersAvailabilityRetrieve: <
      T extends FetchKeys<PanelTeacherAvailabilityDetail>
    >(
      endDate: string,
      startDate: string,
      user: string,
      query?: {
        ignore_lesson_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTeacherAvailabilityDetail, T>, any>({
        path: `/panel/teachers/${user}/availability/${startDate}/${endDate}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTeachersAvailabilityList
     * @request GET:/panel/teachers/availability/{startDate}/{endDate}/
     */,
    panelTeachersAvailabilityList: <
      T extends FetchKeys<PaginatedPanelTeacherAvailabilityList>
    >(
      endDate: string,
      startDate: string,
      query?: {
        ignore_lesson_id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelTeacherAvailabilityList, T>, any>({
        path: `/panel/teachers/availability/${startDate}/${endDate}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsList
     * @request GET:/panel/timetable/events/
     */,
    panelTimetableEventsList: <T extends FetchKeys<PanelTimetableEvent[]>>(
      query: {
        /** @minLength 1 */
        class_id?: string
        /** @minLength 1 */
        course_id?: string
        /** @format date */
        date_from: string
        /** @format date */
        date_to: string
        /** @minLength 1 */
        room_id?: string
        /** @minLength 1 */
        student_id?: string
        /** @minLength 1 */
        teacher_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetableEvent[], T>, any>({
        path: `/panel/timetable/events/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetablePreviewList
     * @request GET:/panel/timetable/preview/
     */,
    panelTimetablePreviewList: <T extends FetchKeys<PanelTimetablePreview[]>>(
      query: {
        /** @minLength 1 */
        class_id?: string
        /** @minLength 1 */
        course_id?: string
        /** @format date */
        date_from: string
        /** @format date */
        date_to: string
        /** @minLength 1 */
        room_id?: string
        /** @minLength 1 */
        student_id?: string
        /** @minLength 1 */
        teacher_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetablePreview[], T>, any>({
        path: `/panel/timetable/preview/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetablePreviewCheckRetrieve
     * @request GET:/panel/timetable/preview/check/
     */,
    panelTimetablePreviewCheckRetrieve: <
      T extends FetchKeys<PanelTimetablePreviewCheckResponse>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetablePreviewCheckResponse, T>, any>({
        path: `/panel/timetable/preview/check/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersList
     * @request GET:/panel/users/
     */,
    panelUsersList: <T extends FetchKeys<PaginatedPanelUserList>>(
      query?: {
        email?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        phone_number?: string[]
        /**
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `User` - User
         * * `Superuser` - Super admin
         */
        profile?: ('Superuser' | 'Teacher' | 'Tutor' | 'User')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelUserList, T>, any>({
        path: `/panel/users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersRetrieve
     * @request GET:/panel/users/{id}/
     */,
    panelUsersRetrieve: <T extends FetchKeys<PanelUser>>(
      id: string,
      query?: {
        email?: string[]
        id?: string[]
        phone_number?: string[]
        /**
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `User` - User
         * * `Superuser` - Super admin
         */
        profile?: ('Superuser' | 'Teacher' | 'Tutor' | 'User')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUser, T>, any>({
        path: `/panel/users/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersBlockCreate
     * @request POST:/panel/users/{id}/block/
     */,
    panelUsersBlockCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/users/${id}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersUnblockCreate
     * @request POST:/panel/users/{id}/unblock/
     */,
    panelUsersUnblockCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/users/${id}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersCheckEmailTakenCreate
     * @request POST:/panel/users/check-email-taken/
     */,
    panelUsersCheckEmailTakenCreate: <
      T extends FetchKeys<PanelCheckEmailTakenResponse>
    >(
      data: PanelCheckEmailTakenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCheckEmailTakenResponse, T>, any>({
        path: `/panel/users/check-email-taken/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersMeRetrieve
     * @request GET:/panel/users/me/
     */,
    panelUsersMeRetrieve: <T extends FetchKeys<PanelUser>>(
      query?: {
        email?: string[]
        id?: string[]
        phone_number?: string[]
        /**
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `User` - User
         * * `Superuser` - Super admin
         */
        profile?: ('Superuser' | 'Teacher' | 'Tutor' | 'User')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUser, T>, any>({
        path: `/panel/users/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersMePartialUpdate
     * @request PATCH:/panel/users/me/
     */,
    panelUsersMePartialUpdate: <T extends FetchKeys<PanelUserUpdateMe>>(
      data: PatchedPanelUserUpdateMeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserUpdateMe, T>, any>({
        path: `/panel/users/me/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags panel
     * @name PanelUsersOptionsRetrieve
     * @request GET:/panel/users/options/
     */,
    panelUsersOptionsRetrieve: <T extends FetchKeys<PanelUserOptions>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserOptions, T>, any>({
        path: `/panel/users/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsEmailsList
     * @request GET:/panel/users/options/emails/
     */,
    panelUsersOptionsEmailsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/emails/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsIdsList
     * @request GET:/panel/users/options/ids/
     */,
    panelUsersOptionsIdsList: <T extends FetchKeys<PaginatedStringOptionList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsPhoneNumbersList
     * @request GET:/panel/users/options/phone-numbers/
     */,
    panelUsersOptionsPhoneNumbersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/phone-numbers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsProfilesList
     * @request GET:/panel/users/options/profiles/
     */,
    panelUsersOptionsProfilesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/profiles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsStatusesList
     * @request GET:/panel/users/options/statuses/
     */,
    panelUsersOptionsStatusesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersSendActivationLinkCreate
     * @request POST:/panel/users/send-activation-link/
     */,
    panelUsersSendActivationLinkCreate: <
      T extends FetchKeys<PanelUserSendActivationLinkResponse>
    >(
      data: PanelUserSendActivationLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserSendActivationLinkResponse, T>, any>({
        path: `/panel/users/send-activation-link/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  parent = {}
  public = {}
  student = {}
}
