import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useTranslation } from 'react-i18next'

import styles from './NotApplicable.module.scss'

const NotApplicable = () => {
  const { t } = useTranslation('common')

  return (
    <span className={styles.notApplicable}>
      {t('text.n-a')}
      <VisuallyHidden.Root>{t('text.not-applicable')}</VisuallyHidden.Root>
    </span>
  )
}
export default NotApplicable
