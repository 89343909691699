import { useQuery } from '@tanstack/react-query'
import type { z } from 'zod'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = arrayOfOptions

type Class = {
  value: string
  label: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Class[] =>
  response.map(item => ({
    value: item.value,
    label: item.label
  }))

const useClasses = () =>
  useQuery({
    queryKey: ['panelStudentsClassesList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsClassesList({
        fetchKeys: {
          label: true,
          value: true
        }
      })

      const parsedClasses = parseResponse(responseSchema.parse(response))

      const options = parsedClasses.map(group => ({
        label: group.label,
        value: group.value
      }))

      return {
        options,
        getLessonName: (id: string) =>
          parsedClasses.find(group => group.value === id)
      }
    }
  })

export default useClasses
