import { date, z } from 'zod'

import { type FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import { type UserProfile } from '@/types/user-profile'
import { gender, requiredStringWithLabel, userProfiles } from '@/utils/zod'

export const userFormSchema = z.object({
  email: requiredStringWithLabel(i18n.t('users:label.email')).email({
    message: i18n.t('common:error.incorrect-email-format')
  }),
  profile: userProfiles.min(1, {
    message: i18n.t('error.required-field-with-label', {
      ns: 'common',
      LABEL: i18n.t('users:label.user-role')
    })
  }),
  sendActivationLink: z.boolean().optional(),
  firstName: requiredStringWithLabel(i18n.t('users:label.first-name')),
  lastName: requiredStringWithLabel(i18n.t('users:label.last-name')),
  ahvNumber: requiredStringWithLabel(i18n.t('users:label.ahv-number')),
  birthDate: date().optional(),
  gender: gender().optional(),
  nationalities: z.array(z.string()),
  firstLanguage: z.string().optional(),
  community: z.string().optional(),
  phoneNumber: z.string().optional(),
  emergencyPhoneNumber: z.string().optional(),
  classes: z.array(z.string()),
  preferredRooms: z.array(z.string())
})

type UserFormPayload = {
  email: string
  profile: UserProfile[]
  sendActivationLink?: boolean
  firstName: string
  lastName: string
  ahvNumber: string
  birthDate?: Date
  gender?: string
  nationalities?: string[]
  firstLanguage?: string
  community?: string
  phoneNumber?: string
  emergencyPhoneNumber?: string
}

export type AddUserPayload = UserFormPayload & {
  tutor: {
    classes: string[]
  } | null
  teacher: {
    preferred_rooms?: string[]
  } | null
}

export type EditUserPayload = AddUserPayload & {
  id: string
}

export const userFormfieldErrorsMapper: FieldErrorsMapper<AddUserPayload> = {
  email: 'email',
  phone_number: 'phoneNumber',
  emergency_phone_number: 'emergencyPhoneNumber',
  first_name: 'firstName',
  last_name: 'lastName',
  ahv_number: 'ahvNumber',
  date_of_birth: 'birthDate',
  gender: 'gender',
  nationalities: 'nationalities',
  first_language: 'firstLanguage',
  community: 'community',
  profile: 'profile',
  non_field_error: 'root'
}
