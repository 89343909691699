import { useQuery } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import { sortBy } from 'lodash'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type TeacherAvailability } from '@/types/lesson'
import { requiredString, teacherStatus } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    last_name: requiredString(),
    status: teacherStatus
  })
)

const parseResponse = (
  response: z.infer<typeof responseSchema>
): TeacherAvailability[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    lastName: item.last_name,
    status: item.status
  }))

const useTeachersAvailability = (params: {
  startDate: Date
  endDate: Date
  enabled: boolean
  ignoreLessonId?: string[]
}) =>
  useQuery({
    queryKey: ['panelTeachersAvailabilityList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelTeachersAvailabilityList(
        formatISO(params.endDate),
        formatISO(params.startDate),
        {
          page_size: 'max',
          ignore_lesson_id: params.ignoreLessonId,
          fetchKeys: {
            id: true,
            name: true,
            status: true
          }
        }
      )
      const parsedTeachers = parseResponse(
        responseSchema.parse(response.results)
      )

      const options = sortBy(parsedTeachers, ['status', 'lastName']).map(
        teacher => ({
          label: teacher.name,
          value: teacher.id
        })
      )

      return {
        options,
        list: parsedTeachers
      }
    },
    enabled: !!params.enabled
  })

export default useTeachersAvailability
