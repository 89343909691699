import { Outlet, createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { CoursesView } from '@/modules/courses'

export const Route = createFileRoute('/_auth/courses')({
  component: () => (
    <CoursesView>
      <Outlet />
    </CoursesView>
  ),
  meta: () => [{ title: i18n.t('navigation.courses', { ns: 'common' }) }]
})
