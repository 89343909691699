import type { UseQueryResult } from '@tanstack/react-query'

import {
  useCoTeacherAvailabilityQueries,
  useTeacherAvailabilityQueries
} from '@/queries/useTeacherAvailabilityQueries'
import type {
  DetailedTeacherAvailability,
  CoverCancelLesson
} from '@/types/lesson'

type ConflictsProps = {
  lessons?: CoverCancelLesson[]
  previousLessons?: CoverCancelLesson[]
}

const useConflicts = (props: ConflictsProps) => {
  const lessonsWithAttendanceChecked =
    props.previousLessons?.filter(item => !item.canBeCancelled) || []

  const teachersAvailability = useTeacherAvailabilityQueries(
    props.lessons || []
  )

  const coTeachersAvailability = useCoTeacherAvailabilityQueries(
    props.lessons || []
  )
  const getTeachersCount = (
    list: UseQueryResult<DetailedTeacherAvailability | undefined, Error>[],
    status: 'busy' | 'unavailable'
  ) => list.filter(teacher => teacher.data?.status === status).length

  const lessonWithTeacherBusyCount = getTeachersCount(
    teachersAvailability,
    'busy'
  )
  const lessonWithCoTeacherBusyCount = getTeachersCount(
    coTeachersAvailability,
    'busy'
  )
  const lessonWithTeacherUnavailableCount = getTeachersCount(
    teachersAvailability,
    'unavailable'
  )
  const lessonWithCoTeacherUnavailableCount = getTeachersCount(
    coTeachersAvailability,
    'unavailable'
  )

  const conflictsCount =
    lessonsWithAttendanceChecked.length +
    lessonWithTeacherBusyCount +
    lessonWithCoTeacherBusyCount +
    lessonWithTeacherUnavailableCount +
    lessonWithCoTeacherUnavailableCount

  return {
    conflictsCount,
    lessonsWithAttendanceChecked,
    teachersAvailability,
    coTeachersAvailability,
    lessonWithTeacherUnvailable:
      lessonWithTeacherUnavailableCount || lessonWithCoTeacherUnavailableCount,
    lessonWithTeacherBusy:
      lessonWithTeacherBusyCount || lessonWithCoTeacherBusyCount
  }
}

export default useConflicts
