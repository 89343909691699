import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    full_name: z.string().min(1)
  })
)

type StudentOption = {
  label: string
  value: string
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): StudentOption[] =>
  response.map(item => ({
    value: item.id,
    label: item.full_name
  }))

type Params = {
  search?: string
  year?: string[]
  classId?: string[]
  tutor?: string[]
  parent?: string[]
  status?: UserStatus[]
  groupId?: string
  page?: number
  fetchKeys?: Record<string, boolean>
}

const useStudentOptions = (params: Params = {}) =>
  useQuery({
    queryKey: ['panelStudentOptionsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsList({
        search: params.search,
        year: params.year,
        class_id: params.classId,
        tutor_id: params.tutor,
        parent_id: params.parent,
        status: params.status,
        page_size: 'max',
        group_id: params.groupId,
        page: params.page,
        fetchKeys: {
          id: true,
          full_name: true
        }
      })
      return parseResponse(responseSchema.parse(response.results))
    },
    placeholderData: previous => previous
  })

export default useStudentOptions
