import clsx from 'clsx'

import DownloadIcon from '@/assets/icons/download.svg?react'

import styles from './FileLink.module.scss'

type FileLinkProps = {
  className?: string
  dataTestId?: string
  children: React.ReactNode
  onClick: () => void
}

const FileLink = (props: FileLinkProps) => (
  <button
    className={clsx(styles.link, props.className)}
    data-test-id={props.dataTestId}
    onClick={props.onClick}
  >
    <>
      <DownloadIcon className={styles.icon} />
      {props.children}
    </>
  </button>
)

export default FileLink
