import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { lessonListQueryOptions } from '@/modules/lessonDetails'

export const Route = createFileRoute('/_auth/timetable/$courseId/$groupId/')({
  validateSearch: z.object({
    lessonId: z.string().optional()
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ params, context, deps: { search } }) => {
    const lessonList = await context.queryClient.ensureQueryData(
      lessonListQueryOptions(params.groupId)
    )

    const closestLessonId = lessonList.closestLesson.id

    throw redirect({
      to: '/timetable/$courseId/$groupId/lesson-details',
      search: { lessonId: search.lessonId || closestLessonId },
      params
    })
  }
})
