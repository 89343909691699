import clsx from 'clsx'
import { useDropzone, type ErrorCode } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import UploadIcon from '@/assets/icons/upload.svg?react'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './FileUpload.module.scss'

type FileUploadProps = {
  disabled?: boolean
  maxSize: number
  id: string
  onFileAccept: (file: File) => void
  onFileRejected?: (file: File, errorCodes: (ErrorCode | string)[]) => void
}

const acceptFilesFormat: Record<string, string[]> = {
  'image/*': ['.png', '.jpeg', '.jpg'],
  'application/pdf': ['.pdf'],
  'application/doc': ['.doc', '.docx']
}

const FileUpload = (props: FileUploadProps) => {
  const { t } = useTranslation('common')
  const { isMobile } = useScreenResolution()

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFilesFormat,
    disabled: props.disabled,
    maxSize: props.maxSize,
    onDropAccepted: files => {
      files.forEach(props.onFileAccept)
    },
    onDropRejected: rejectedFiles => {
      rejectedFiles.forEach(rejectedFile =>
        props.onFileRejected?.(
          rejectedFile.file,
          rejectedFile.errors.map(error => error.code)
        )
      )
    }
  })

  const formatsList = Object.keys(acceptFilesFormat).reduce((prev, acc) => {
    const formats = acceptFilesFormat[acc]
      .map(format => format.replace(/\./g, '').toUpperCase())
      .join(', ')
    return prev ? `${prev}, ${formats}` : formats
  }, '')

  return (
    <div className={styles.container}>
      <div
        {...getRootProps({
          className: clsx(
            styles.dropContainer,
            props.disabled && styles.disabled
          )
        })}
      >
        <input {...getInputProps()} id={props.id} />
        <UploadIcon className={styles.uploadIcon} />
        {isMobile ? (
          <p className={styles.uploadTextMobile}>{t('text.upload-file')}</p>
        ) : (
          <p className={styles.uploadText}>
            <Trans
              t={t}
              i18nKey="text.drag-files"
              components={{ underlined: <u /> }}
            />
          </p>
        )}
      </div>
      <div className={styles.inputDetails}>
        <span>{t('text.supported-format', { FORMATS_LIST: formatsList })}</span>
        <span>
          {t('text.maximum-file-size', {
            FILE_SIZE: `${props.maxSize / 1000 / 1000}MB`
          })}
        </span>
      </div>
    </div>
  )
}

export default FileUpload
