import { type TagColor } from '@/components/Tag/Tag'
import { USER_PROFILE } from '@/constants/user-profile'
import i18n from '@/i18n'

export const getColorByProfile = (value: string): TagColor => {
  switch (value) {
    case USER_PROFILE.TUTOR:
      return 'blue'
    case USER_PROFILE.TEACHER:
      return 'orange'
    case USER_PROFILE.ADMIN:
      return 'violet'
    default:
      return 'gray'
  }
}

export const getLabelByProfile = (value: string): string => {
  switch (value) {
    case USER_PROFILE.TUTOR:
      return i18n.t('text.tutor', { ns: 'users' })
    case USER_PROFILE.TEACHER:
      return i18n.t('text.teacher', { ns: 'users' })
    case USER_PROFILE.ADMIN:
      return i18n.t('text.admin', { ns: 'users' })
    default:
      return value
  }
}
