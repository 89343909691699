import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

import { type OptionsParams } from '../types'

const useStudentsOptions = (params: OptionsParams) =>
  useQuery({
    queryKey: ['panelLessonsGroupsOptionsStudentsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelLessonsGroupsOptionsStudentsList({
          tutor_id: params.tutorId,
          teacher_or_co_teacher_id: params.teacherOrCoTeacherId,
          semester_id: params.semester,
          page_size: 'max'
        })

      return arrayOfOptions.parse(response.results)
    },
    enabled: !!params.semester
  })

export default useStudentsOptions
