import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MoreOutlined from '@/assets/icons/menu-vertical.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Drawer from '@/components/Drawer/Drawer'

import styles from './ActionsDrawer.module.scss'
import { type Action } from '../BasicLayout/Actions'

export type DrawerAction = Action & { isDanger?: boolean }

type ActionsDrawerProps = {
  actions: DrawerAction[]
  onlyMobile?: boolean
  triggerButton: { size: 'small' | 'medium'; block?: boolean }
}

const ActionsDrawer = (props: ActionsDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { t } = useTranslation('common')

  const buttonProps = {
    variant: 'secondary',
    className: clsx(props.onlyMobile && styles.drawerTrigger),
    size: props.triggerButton.size
  } as const

  return props.actions.length ? (
    <Drawer
      open={isDrawerOpen}
      onOpenChange={setIsDrawerOpen}
      trigger={
        props.triggerButton.block ? (
          <Button {...buttonProps} block icon={<MoreOutlined />}>
            {t('button.actions')}
          </Button>
        ) : (
          <ButtonIcon ariaLabel={t('button.actions')} {...buttonProps}>
            <MoreOutlined />
          </ButtonIcon>
        )
      }
    >
      <ul>
        {props.actions
          .filter(action => !action.hidden)
          .map((action, index) => {
            const { isDanger: _, icon: __, ...actionProps } = action

            return (
              <li key={index} className={styles.item}>
                <Button
                  {...actionProps}
                  variant="tertiary"
                  className={styles.actionButton}
                  onClick={() => {
                    setIsDrawerOpen(false)
                    action.onClick?.()
                  }}
                >
                  <div
                    className={clsx(
                      styles.action,
                      action.isDanger && styles.actionDanger
                    )}
                  >
                    <span className={styles.icon}>{action.icon}</span>
                    <span>{action.text}</span>
                  </div>
                </Button>
              </li>
            )
          })}
      </ul>
    </Drawer>
  ) : null
}

export default ActionsDrawer
