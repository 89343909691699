import { useTranslation } from 'react-i18next'

import type { Filter } from '@/components/common/Filters/Filters'
import useSemesters from '@/queries/useSemesters'

import { type CoursesFiltersKey } from '../constants/courses-filters'
import useClassesOptions from '../queries/useClassesOptions'
import useCoursesOptions from '../queries/useCoursesOptions'
import useGroupsOptions from '../queries/useGroupsOptions'
import useStudentsOptions from '../queries/useStudentsOptions'
import useTeachersOptions from '../queries/useTeachersOptions'

type CoursesFiltersProps = {
  semester?: string
  tutotId?: string
  teacherOrCoTeacherId?: string[]
  studentsId?: string[]
  coursesId?: string[]
  ids?: string[]
  classesId?: string[]
  hideTeacherFilter?: boolean
  onChangeFilter: (key: CoursesFiltersKey, value?: string | string[]) => void
  onChangeSemester: (semester?: string) => void
}

const useFilters = (props: CoursesFiltersProps) => {
  const { t } = useTranslation(['courses'])
  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const optionsParams = {
    semester: props.semester,
    tutorId: props.tutotId,
    teacherOrCoTeacherId: props.teacherOrCoTeacherId
  }

  const { data: teachers, isLoading: isTeachersPending } = useTeachersOptions({
    ...optionsParams,
    enabled: !props.hideTeacherFilter
  })
  const { data: classes, isLoading: isClassesPending } =
    useClassesOptions(optionsParams)
  const { data: courses, isLoading: isCoursesPending } =
    useCoursesOptions(optionsParams)
  const { data: groups, isLoading: isGroupsPending } =
    useGroupsOptions(optionsParams)
  const { data: students, isLoading: isStudentsPending } =
    useStudentsOptions(optionsParams)

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: props.semester,
        placeholder: t('label.semester'),
        onChange: value => props.onChangeSemester(value)
      }
    },
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        multiple: true,
        loading: isCoursesPending,
        options: courses || [],
        value: props.coursesId,
        placeholder: t('label.course'),
        onChange: value => props.onChangeFilter('coursesId', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'multiselect',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        multiple: true,
        loading: isGroupsPending,
        options: groups || [],
        value: props.ids,
        placeholder: t('label.group'),
        onChange: value => props.onChangeFilter('ids', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'multiselect',
      hidden: props.hideTeacherFilter,
      filterProps: {
        id: 'teacher',
        dataTestId: 'teacher-filter',
        multiple: true,
        loading: isTeachersPending,
        options: teachers || [],
        value: props.teacherOrCoTeacherId,
        placeholder: t('label.teacher'),
        onChange: value => props.onChangeFilter('teacherOrCoTeacherId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isStudentsPending,
        options: students || [],
        value: props.studentsId,
        placeholder: t('label.student'),
        onChange: value => props.onChangeFilter('studentsId', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        loading: isClassesPending,
        options: classes || [],
        value: props.classesId,
        placeholder: t('label.class'),
        onChange: value => props.onChangeFilter('classesId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return { filters, isSomeFilterSelected }
}

export default useFilters
