import { getRouteApi } from '@tanstack/react-router'
import { takeRight } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table, { type Column } from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradebookButton from '@/components/common/GradebookButton'
import NotApplicable from '@/components/common/NotApplicable/NotApplicable'
import TeachersColumn from '@/components/common/TeachersColumn/TeachersColumn'
import TruncateElementsList from '@/components/common/TruncateElementsList'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useSemesters from '@/queries/useSemesters'

import styles from './StudentCoursesAndGradesView.module.scss'
import { type StudentCoursesFiltersKey } from '../constants/student-courses-filters'
import useCoursesOptions from '../queries/useCoursesOptions'
import useStudentCourses, {
  type StudentCoursesSummary
} from '../queries/useStudentCourses'
import useTeachersOptions from '../queries/useTeachersOptions'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/$studentId/courses-and-grades'
)

const StudentCoursesAndGradesView = () => {
  const { t } = useTranslation('students')

  const [isExpandedGrades, setIsExpandedGrades] = useState(false)

  const navigate = routeApi.useNavigate()
  const { studentId } = routeApi.useParams()
  const { semester, teacherOrCoTeacherId, course } = routeApi.useSearch()

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()
  const { data: teachers, isLoading: isTeachersLoading } = useTeachersOptions({
    studentId,
    semesterId: semester
  })
  const { data: courses, isLoading: isCoursesLoading } = useCoursesOptions({
    studentId,
    semesterId: semester
  })

  const changeFilter = (
    key: StudentCoursesFiltersKey,
    value?: string | string[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { data, isLoading } = useStudentCourses({
    semesterId: semester,
    studentId,
    teacherOrCoTeacherId,
    course
  })

  const columns: Column<StudentCoursesSummary>[] = [
    {
      title: t('header.course'),
      key: 'course',
      dataIndex: ['course', 'name'],
      width: 200
    },
    {
      title: t('header.teachers'),
      key: 'teacher',
      render: (value: StudentCoursesSummary) => (
        <TeachersColumn teacher={value.teacher} coTeacher={value.coTeacher} />
      ),
      width: 200
    },
    {
      title: t('header.group'),
      key: 'group',
      dataIndex: ['group', 'name'],
      width: 200
    },
    {
      title: t('header.grades'),
      key: 'grades',
      dataIndex: 'grades',
      render: (grades: number[]) => (
        <TruncateElementsList
          expanded={isExpandedGrades}
          onExpandedChange={setIsExpandedGrades}
          elipsis={hiddenItemsCount => (
            <Tooltip
              text={takeRight(grades, hiddenItemsCount).join(', ')}
              trigger={
                <Tag
                  color="white"
                  variant="with-background"
                  label={`+${hiddenItemsCount}`}
                  onClick={() => {
                    setIsExpandedGrades(true)
                  }}
                />
              }
            />
          )}
        >
          {grades.map((grade, index) => (
            <span key={index} className={styles.grade}>
              {grade}
            </span>
          ))}
        </TruncateElementsList>
      ),
      width: 100
    },
    {
      title: t('header.attendance'),
      key: 'attendance',
      dataIndex: 'attendance',
      render: (value: number) => `${Math.round(value * 100)}%`,
      width: 130
    },
    {
      title: t('header.behaviour-grade'),
      key: 'behaviour',
      dataIndex: 'behaviourGrade',
      render: (value: string | null) => value ?? <NotApplicable />,
      width: 130
    },
    {
      title: t('header.proposed-grade'),
      key: 'proposed',
      dataIndex: 'proposedGrade',
      render: (value: string | null) => value ?? <NotApplicable />,
      width: 130
    },
    {
      title: t('header.final-grade'),
      key: 'final',
      dataIndex: 'finalGrade',
      render: (value: string | null) => value ?? <NotApplicable />,
      width: 130
    },
    {
      key: 'gradebook',
      render: (value: StudentCoursesSummary) => (
        <GradebookButton
          courseId={value.course.id}
          groupId={value.group.id}
          semesterId={semester}
        />
      ),
      width: 165
    }
  ]

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semester,
        placeholder: t('label.semester'),
        onChange: value => {
          navigate({
            search: {
              semester: value,
              page: 1,
              pageSize: DEFAULT_PAGE_SIZE
            }
          })
        }
      }
    },
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        multiple: true,
        loading: isCoursesLoading,
        options: courses || [],
        value: course,
        placeholder: t('label.course'),
        onChange: value => changeFilter('course', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'multiselect',
      filterProps: {
        id: 'teachers',
        multiple: true,
        loading: isTeachersLoading,
        options: teachers || [],
        value: teacherOrCoTeacherId,
        placeholder: t('label.teacher'),
        onChange: value => changeFilter('teacherOrCoTeacherId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />

      <Table
        id="courses-summary"
        data={data || []}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  )
}

export default StudentCoursesAndGradesView
