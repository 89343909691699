import { createFileRoute, Outlet } from '@tanstack/react-router'

import i18n from '@/i18n'

export const Route = createFileRoute('/_auth/users')({
  component: Outlet,
  meta: () => [
    { title: i18n.t('navigation.teachers-and-users', { ns: 'common' }) }
  ]
})
