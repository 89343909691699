import InfoCircle from '@/assets/icons/info-circle.svg?react'

import styles from './DataCard.module.scss'

type DataCardProps = {
  icon?: React.ReactNode
  label: React.ReactNode
  count?: number
}

const DataCard = (props: DataCardProps) => (
  <div className={styles.container}>
    <div className={styles.labelWrapper}>
      {props.icon ?? <InfoCircle className={styles.icon} />}
      <span className={styles.label}>{props.label}</span>
    </div>

    {props.count !== undefined ? (
      <span className={styles.count}>{props.count}</span>
    ) : (
      <span className={styles.noData}>{'-'}</span>
    )}
  </div>
)
export default DataCard
