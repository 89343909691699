import clsx from 'clsx'
import { groupBy, zip } from 'lodash'
import { useTranslation } from 'react-i18next'

import InfoCircle from '@/assets/icons/info-circle.svg?react'
import LessonCancelIcon from '@/assets/icons/lesson-cancel.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import LessonUnchangesIcon from '@/assets/icons/lesson-unchanges.svg?react'
import Alert from '@/components/Alert/Alert'
import DataCard from '@/components/DataCard/DataCard'
import Tooltip from '@/components/Tooltip/Tooltip'
import { type CoverCancelLesson } from '@/types/lesson'
import { formatDate, formatTime } from '@/utils/format-date'
import { getLessonFormattedTime } from '@/utils/lesson-details-format'

import styles from './Summary.module.scss'
import useConflicts from '../hooks/useConflicts'

type SummaryProps = {
  className?: string
  lessons?: CoverCancelLesson[]
  previousLessons?: CoverCancelLesson[]
  hideHeader?: boolean
}

const Summary = (props: SummaryProps) => {
  const { t } = useTranslation('coveredCancelledLessons')

  const groupedLessons = groupBy(
    props.lessons
      ?.filter(lesson => lesson.comment)
      .map(lesson => ({
        ...lesson,
        date: formatDate(lesson.startDate)
      })),
    'date'
  )

  const coveredLessonsCount = props.lessons?.filter(
    lesson => lesson.isCovered
  ).length

  const cancelledLessonsCount = props.lessons?.filter(
    lesson => lesson.isCancelled
  ).length

  const unchangedLessonsCount = props.lessons?.filter(
    lesson => !lesson.isCancelled && !lesson.isCovered
  ).length

  const isCoverCancelLessonCommentExist = props.lessons?.some(
    lesson => !!lesson.comment
  )

  return (
    <div>
      {!props.hideHeader ? (
        <p className={styles.summaryHeader}>{t('header.summary')}</p>
      ) : null}
      <div className={clsx(styles.summary, props.className)}>
        <div className={styles.wrapper}>
          <div className={styles.dataCardWrapper}>
            <DataCard
              label={t('label.covered-lessons')}
              count={coveredLessonsCount}
              icon={<LessonCoverIcon className={styles.lessonCoverIcon} />}
            />
            <DataCard
              label={t('label.cancelled-lessons')}
              count={cancelledLessonsCount}
              icon={<LessonCancelIcon className={styles.lessonCancelIcon} />}
            />
            <DataCard
              label={t('label.unchanged-lessons')}
              count={unchangedLessonsCount}
              icon={
                <LessonUnchangesIcon className={styles.lessonUnchagesIcon} />
              }
            />
          </div>
        </div>

        <Conflicts
          lessons={props.lessons}
          previousLessons={props.previousLessons}
        />

        <div className={styles.wrapper}>
          <p className={styles.header}>
            {t('header.old-comments')}
            <Tooltip
              text={t('text.old-comments')}
              trigger={<InfoCircle className={styles.icon} />}
            />
          </p>

          {isCoverCancelLessonCommentExist ? (
            Object.entries(groupedLessons).map(([date, lessonsList]) => (
              <div key={date} className={styles.dayWrapper}>
                <span className={styles.day}>{date}</span>
                {lessonsList.map(lesson => (
                  <div key={lesson.id}>
                    <span className={styles.details}>
                      {`${formatTime(lesson.startDate)} - ${formatTime(lesson.startDate)} ${lesson.course.name}`}
                    </span>
                    <span className={styles.comment}>{lesson.comment}</span>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <span className={styles.noData}> {t('text.no-comments')}</span>
          )}
        </div>
      </div>
    </div>
  )
}

const Conflicts = (props: {
  lessons?: CoverCancelLesson[]
  previousLessons?: CoverCancelLesson[]
}) => {
  const { t } = useTranslation('coveredCancelledLessons')

  const {
    lessonsWithAttendanceChecked,
    conflictsCount,
    teachersAvailability,
    coTeachersAvailability,
    lessonWithTeacherBusy,
    lessonWithTeacherUnvailable
  } = useConflicts({
    lessons: props.lessons,
    previousLessons: props.previousLessons
  })

  const getConflictsAlert = () => {
    const lessonWithTeacherAvailability = zip(
      props.lessons || [],
      teachersAvailability,
      coTeachersAvailability
    )
    return conflictsCount ? (
      <Alert
        variant="warning"
        message={
          <div className={styles.conflicts}>
            {lessonWithTeacherBusy ? (
              <div>
                <span>{t('label.teacher-busy')}</span>

                <ul>
                  {lessonWithTeacherAvailability?.map(
                    (
                      [lesson, teacherAvailability, coTeacherAvailability],
                      index
                    ) => (
                      <span key={index}>
                        {teacherAvailability?.data?.status === 'busy' &&
                        lesson ? (
                          <ConflictItem
                            key={lesson.id + 'teacher-busy'}
                            lesson={lesson}
                            teacherName={teacherAvailability.data.name}
                          />
                        ) : coTeacherAvailability?.data?.status === 'busy' &&
                          lesson ? (
                          <ConflictItem
                            key={lesson.id + 'coTeacher-busy'}
                            lesson={lesson}
                            teacherName={coTeacherAvailability.data.name}
                          />
                        ) : null}
                      </span>
                    )
                  )}
                </ul>
              </div>
            ) : null}

            {lessonWithTeacherUnvailable ? (
              <div>
                <span>{t('label.teacher-unavailable')}</span>

                <ul>
                  {lessonWithTeacherAvailability?.map(
                    (
                      [lesson, teacherAvailability, coTeacherAvailability],
                      index
                    ) => (
                      <span key={index}>
                        {teacherAvailability?.data?.status === 'unavailable' &&
                        lesson ? (
                          <ConflictItem
                            key={lesson.id + 'teacher-unavailable'}
                            lesson={lesson}
                            teacherName={teacherAvailability.data.name}
                          />
                        ) : null}
                        {coTeacherAvailability?.data?.status ===
                          'unavailable' && lesson ? (
                          <ConflictItem
                            key={lesson.id + 'coTeacher-unavailable'}
                            lesson={lesson}
                            teacherName={coTeacherAvailability.data.name}
                          />
                        ) : null}
                      </span>
                    )
                  )}
                </ul>
              </div>
            ) : null}
            {lessonsWithAttendanceChecked.length ? (
              <div>
                <>
                  <span>{t('label.attendance-already-checked')}</span>

                  <ul>
                    {lessonsWithAttendanceChecked.map(item => (
                      <ConflictItem key={item.id} lesson={item} />
                    ))}
                  </ul>
                </>
              </div>
            ) : null}
          </div>
        }
      />
    ) : (
      <Alert variant="success" message={t('text.no-conflicts')} />
    )
  }
  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>
        {t('header.warnings')} ({conflictsCount})
      </p>

      {!props.previousLessons ? (
        <span className={styles.noData}>
          {t('text.select-teacher-and-dates')}
        </span>
      ) : (
        getConflictsAlert()
      )}
    </div>
  )
}

type ConflictItemProps = {
  lesson: CoverCancelLesson
  teacherName?: string
}
const ConflictItem = (props: ConflictItemProps) => (
  <li className={styles.conflictItem}>
    {props.teacherName ? (
      <span className={styles.teacherName}>{`${props.teacherName} `}</span>
    ) : null}
    {`${getLessonFormattedTime(props.lesson)} ${props.lesson.course.name}`}
  </li>
)

export default Summary
