import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  semesterId: string
  studentId: string
}

const useTeachersOptions = (params: Params) =>
  useQuery({
    queryKey: ['panelCoursesSummaryOptionsTeachersList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelCoursesSummaryOptionsTeachersList(
          params.semesterId,
          params.studentId,
          {
            page_size: 'max'
          }
        )

      return arrayOfOptions.parse(response.results)
    },
    enabled: !!params.semesterId
  })

export default useTeachersOptions
