import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

type PayloadDownloadAttachment = {
  attachmentId: string
  name: string
}

const useDownloadAttachment = (options: {
  onSuccess: (params: {
    file?: File
    payload: PayloadDownloadAttachment
  }) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (payload: PayloadDownloadAttachment) => {
      const file = await httpService.panel.panelAttachmentsDownloadRetrieve(
        payload.attachmentId,
        {},
        { format: 'blob' }
      )

      return file
    },
    onSuccess: (data, payload) => options.onSuccess({ file: data, payload })
  })

export default useDownloadAttachment
