import * as Label from '@radix-ui/react-label'
import * as RadioGroupRadix from '@radix-ui/react-radio-group'
import clsx from 'clsx'

import type { FormFieldType } from '@/types/form-field-type'

import styles from './RadioGroup.module.scss'

type Option = {
  value: string
  disabled?: boolean
  className?: string
} & (
  | { label: string; labelledby?: never }
  | { labelledby: string; label?: never }
)

type RadioGroupProps<T> = {
  orientation?: 'horizontal' | 'vertical'
  options: readonly Option[]
  disabled?: boolean
  dataTestId?: string
  className?: string
} & FormFieldType<T>

const RadioGroup = <T extends string>(props: RadioGroupProps<T>) => {
  const { orientation = 'vertical' } = props

  return (
    <RadioGroupRadix.Root
      className={clsx(
        styles.radio,
        orientation === 'horizontal' && styles.horizontal,
        props.className
      )}
      data-test-id={props.dataTestId}
      value={props.value}
      orientation={orientation}
      disabled={props.disabled}
      onValueChange={event => props.onChange?.(event as T)}
      id={props.id}
      aria-describedby={props.describedby}
      aria-invalid={props.invalid}
      required={props.required}
      aria-disabled={props.disabled}
    >
      {props.options.map(option => (
        <div
          key={option.value}
          className={clsx(
            styles.radioWrapper,
            (option.disabled || props.disabled) && styles.radioWrapperDisabled,
            option.className
          )}
        >
          <RadioGroupRadix.Item
            className={clsx(
              styles.item,
              props.value === option.value && styles.checked
            )}
            value={option.value}
            disabled={option.disabled}
            id={option.value}
            aria-labelledby={option.labelledby}
          >
            <RadioGroupRadix.Indicator className={styles.indicator} />
          </RadioGroupRadix.Item>

          {option.label ? (
            <Label.Root className={styles.label} htmlFor={option.value}>
              {option.label}
            </Label.Root>
          ) : null}
        </div>
      ))}
    </RadioGroupRadix.Root>
  )
}

export default RadioGroup
