import countries, { type LocalizedCountryNames } from 'i18n-iso-countries'
import German from 'i18n-iso-countries/langs/de.json'
import English from 'i18n-iso-countries/langs/en.json'

countries.registerLocale(English)
countries.registerLocale(German)

export const countryNamesEn: LocalizedCountryNames<{ select: 'official' }> = {
  ...countries.getNames('en'),
  // https://github.com/michaelwittig/node-i18n-iso-countries/issues/318
  // country codes missing in i18n-iso-countries but present in libphonenumber-js
  AC: 'Ascension Island',
  TA: 'Tristan da Cunha'
}
export const countryNamesDe = {
  ...countries.getNames('de'),
  AC: 'Ascension',
  TA: 'Tristan da Cunha'
}
