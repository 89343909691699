import { createFileRoute, useNavigate } from '@tanstack/react-router'

import Button from '@/components/Button/Button'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useAuthStore from '@/store/useAuthStore'

export const Route = createFileRoute('/_auth/profile')({
  component: ProfileComponent
})

function ProfileComponent() {
  const authStore = useAuthStore()
  const navigate = useNavigate()
  return (
    <BasicLayout
      header={`${authStore?.user?.firstName} ${authStore?.user?.lastName}`}
    >
      <div>
        <Button
          onClick={() => {
            authStore.logOut()
            navigate({ to: '/login', search: { redirect: Route.fullPath } })
          }}
        >
          Log out
        </Button>
      </div>
    </BasicLayout>
  )
}
