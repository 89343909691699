import clsx from 'clsx'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChevronSmallDown from '@/assets/icons/chevron-small-down.svg?react'
import ChevronSmallUp from '@/assets/icons/chevron-small-up.svg?react'
import Delete from '@/assets/icons/delete.svg?react'
import EditOutlined from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import Tooltip from '@/components/Tooltip/Tooltip'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import type { ColumnGrade } from '@/types/grades'
import { type Semester } from '@/types/semester'

import AssignmentModal from './AssignmentModal'
import styles from './ColumnHeaderGradesView.module.scss'
import GradeColumnModal from './GradeColumnModal'
import RemoveAssignmentSubheader from './RemoveAssignmentSubheader'
import useRemoveAssignment from '../mutations/useRemoveAssignment'
import useRemoveGradesColumn from '../mutations/useRemoveGradesColumn'

type ColumnHeaderProps = {
  tooltip: string
  children: React.ReactNode
  column: ColumnGrade
  semesterId: string
  courseId: string
  groupId: string
  canChangeGrades?: boolean
  groupSemesters: Semester[]
  onGradeColumnChange: () => void
}

const ColumnHeader = (props: ColumnHeaderProps) => {
  const { t } = useTranslation(['lessonDetails'])

  const { isTablet } = useScreenResolution()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [isGradeModalOpen, setIsGradeModalOpen] = useState(false)
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false)
  const [isRemoveGradeConfirmOpen, setIsRemoveGradeConfirmOpen] =
    useState(false)
  const [isRemoveAssignmentConfirmModal, setIsRemoveAssignmentConfirmModal] =
    useState(false)

  const focusRef = useRef<HTMLElement | null>(null)
  const dropdownTriggerRef = useRef<HTMLDivElement | null>(null)

  const { mutate: removeColumn } = useRemoveGradesColumn({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.grade-column-removed')
      })
      props.onGradeColumnChange()
    }
  })

  const { mutate: removeAssignment, isPending: isRemoveAssignmentLoading } =
    useRemoveAssignment({
      onSuccess: data => {
        toast({
          title: t('toast.assignment-removed', {
            ASSIGNMENT: data?.name
          }),
          variant: 'success'
        })
        props.onGradeColumnChange()
      }
    })

  return (
    <>
      {props.canChangeGrades ? (
        <Dropdown
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
          contentHidden={isGradeModalOpen || isAssignmentModalOpen}
          onContentCloseAutoFocus={event => {
            if (focusRef.current) {
              focusRef.current.focus()
              focusRef.current = null
              event.preventDefault()
            }
          }}
          trigger={
            <div
              tabIndex={0}
              ref={dropdownTriggerRef}
              className={styles.tooltipWrapper}
            >
              <Tooltip
                renderTriggerAsChild
                hideTooltipOnMobileDevice
                trigger={
                  <div
                    className={styles.headerCell}
                    role={isTablet ? undefined : 'button'}
                    tabIndex={isTablet ? undefined : 0}
                  >
                    {props.children}
                    {isDropdownOpen ? (
                      <ChevronSmallUp />
                    ) : (
                      <ChevronSmallDown
                        className={clsx(styles.columnMenuChevron)}
                      />
                    )}
                  </div>
                }
                text={props.tooltip}
              />
            </div>
          }
        >
          <>
            {props.column.assignmentId ? (
              <>
                <AssignmentModal
                  id={props.column.assignmentId || ''}
                  groupId={props.groupId}
                  mode="edit"
                  groupSemesters={props.groupSemesters || []}
                  onClose={() => {
                    props.onGradeColumnChange()
                  }}
                  controlledByParent={{
                    open: isAssignmentModalOpen,
                    onOpenChange: value => {
                      setIsAssignmentModalOpen(value)
                      if (!value) {
                        setIsDropdownOpen(false)
                      }
                    }
                  }}
                  trigger={
                    <DropdownMenuItem
                      onSelect={event => {
                        event.preventDefault()
                        focusRef.current = dropdownTriggerRef.current
                      }}
                    >
                      <EditOutlined /> {t('button.edit-assignment')}
                    </DropdownMenuItem>
                  }
                />

                <DropdownMenuItem
                  onClick={() => setIsRemoveAssignmentConfirmModal(true)}
                  variant="danger"
                >
                  <Delete /> {t('button.remove-assignment')}
                </DropdownMenuItem>
              </>
            ) : (
              <>
                <GradeColumnModal
                  gradeColumnId={props.column.id}
                  semesterId={props.semesterId}
                  groupId={props.groupId}
                  onRemove={() => setIsRemoveGradeConfirmOpen(true)}
                  open={isGradeModalOpen}
                  onGradeColumnChange={props.onGradeColumnChange}
                  onOpenChange={value => {
                    setIsGradeModalOpen(value)
                    if (!value) {
                      setIsDropdownOpen(false)
                    }
                  }}
                  trigger={
                    <DropdownMenuItem
                      onSelect={event => {
                        event.preventDefault()
                        focusRef.current = dropdownTriggerRef.current
                      }}
                    >
                      <EditOutlined /> {t('button.edit-grade')}
                    </DropdownMenuItem>
                  }
                />
                <DropdownMenuItem
                  onClick={() => setIsRemoveGradeConfirmOpen(true)}
                  variant="danger"
                >
                  <Delete /> {t('button.remove-grade')}
                </DropdownMenuItem>
              </>
            )}
          </>
        </Dropdown>
      ) : (
        <Tooltip
          trigger={<div className={styles.headerCell}>{props.children}</div>}
          text={props.tooltip}
        />
      )}

      <ConfirmModal
        id="remove-column-confirmation"
        open={isRemoveGradeConfirmOpen}
        onOpenChange={setIsRemoveGradeConfirmOpen}
        header={t('notification.remove-grade-column', {
          COLUMN: props.column.name
        })}
        subheader={
          <>
            {t('notification.grade-column-will-be-removed')}
            <br />
            {t('notification.can-not-be-undone')}
          </>
        }
        confirmButton={
          <Button
            variant="danger"
            onClick={() => removeColumn({ id: props.column.id })}
          >
            {t('button.remove')}
          </Button>
        }
      />

      <ConfirmModal
        id="confirm-remove-modal"
        header={t('notification.remove-assignment')}
        subheader={<RemoveAssignmentSubheader isGradedAssignment />}
        confirmButton={
          <Button
            variant="danger"
            onClick={() =>
              removeAssignment({ id: props.column.assignmentId || '' })
            }
            loading={isRemoveAssignmentLoading}
          >
            {t('button.remove')}
          </Button>
        }
        open={isRemoveAssignmentConfirmModal}
        onOpenChange={setIsRemoveAssignmentConfirmModal}
      />
    </>
  )
}

export default ColumnHeader
