import type { AxiosProgressEvent } from 'axios'

import {
  ContentType,
  type PanelAttachmentCreate
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

type AttachmentType = 'absence'

type UploadFilePayload = {
  id: string
  file: File
  attachmentType: AttachmentType
  onProgressChange?: (progress: number) => void
}

const useUploadFile = ({
  onSuccess,
  onError
}: {
  onSuccess: (
    data: PanelAttachmentCreate | undefined,
    variables: UploadFilePayload
  ) => void
  onError: (variables: UploadFilePayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: UploadFilePayload) =>
      httpService.panel.panelAttachmentsCreate(
        {
          file: data.file,
          attachment_type: data.attachmentType
        },
        {
          type: ContentType.FormData,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            data.onProgressChange?.(
              Math.round(
                (100 * progressEvent.loaded) / (progressEvent.total ?? 1)
              )
            )
          }
        }
      ),
    onError: (_, variables) => onError?.(variables),
    onSuccess
  })

export default useUploadFile
