import { zodResolver } from '@hookform/resolvers/zod'
import { isSameDay, isWithinInterval } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { z } from 'zod'

import Button from '@/components/Button/Button'
import Checkbox from '@/components/Checkbox/Checkbox'
import CheckboxGroup from '@/components/Checkbox/CheckboxGroup'
import ConfirmModal from '@/components/ConfirmModal'
import DatePicker from '@/components/DatePicker/DatePicker'
import Divider from '@/components/Divider/Divider'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import ModalForm from '@/components/Modal/ModalForm'
import Textarea from '@/components/Textarea/Textarea'
import DirtyModal from '@/components/common/DirtyModal'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import useStudentOptions from '@/queries/useStudentOptions'
import type { Semester } from '@/types/semester'

import styles from './AssignmentModal.module.scss'
import RemoveAssignmentSubheader from './RemoveAssignmentSubheader'
import { formSchemaAddAssignment } from '../constants/assignment-payload'
import useAddAssignment from '../mutations/useAddAssignment'
import useEditAssignment from '../mutations/useEditAssignment'
import useRemoveAssignment from '../mutations/useRemoveAssignment'
import useAssignment from '../queries/useAssignment'
import useLessons from '../queries/useLessons'

type AssignmentModalProps = AssignmentModalContentProps & {
  controlledByParent?: {
    open: boolean
    onOpenChange: (open: boolean) => void
  }
}

const AssignmentModal = (props: AssignmentModalProps) => {
  const [open, setOpen] = useState(false)

  return (
    <AssignmentModalContent
      {...props}
      open={props.controlledByParent ? props.controlledByParent.open : open}
      onOpenChange={value =>
        props.controlledByParent
          ? props.controlledByParent.onOpenChange(value)
          : setOpen(value)
      }
    />
  )
}

type AssignmentModalContentProps = {
  groupId: string
  trigger?: React.ReactNode
  onClose?: () => void
  groupSemesters: Semester[]
} & (
  | {
      mode: 'edit'
      id: string
    }
  | {
      mode: 'add'
    }
)

type ModalProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
}

type FormSchema = z.infer<ReturnType<typeof formSchemaAddAssignment>>

const AssignmentModalContent = (
  props: AssignmentModalContentProps & ModalProps
) => {
  const { t } = useTranslation(['lessonDetails'])
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenEditConfirmModal, setIsOpenEditConfirmModal] = useState(false)
  const [isOpenRemoveConfirmModal, setIsOpenRemoveConfirmModal] =
    useState(false)

  const { data: assignment, isFetching: isAssignmentLoading } = useAssignment({
    id: props.mode === 'edit' ? props.id : '',
    enabled: props.mode === 'edit' && props.open
  })

  const { data: lessons } = useLessons(props.groupId)

  const isLessonInDate = (date: Date) =>
    !!lessons?.list.some(
      lesson => isSameDay(lesson.startDate, date) && !lesson.isCancelled
    )

  const {
    mutate: addAssignment,
    isPending: isAddAssignmentLoading,
    formErrors: formErrorsAddAssignment
  } = useAddAssignment({
    onSuccess: data => {
      cancelModal()
      toast({
        title: t('toast.assignment-created', { ASSIGNMENT: data.name }),
        variant: 'success'
      })
      props.onClose?.()
    }
  })

  const {
    mutate: editAssignment,
    isPending: isEditAssignmentLoading,
    formErrors: formErrorsEditAssignment
  } = useEditAssignment({
    onSuccess: data => {
      cancelModal()
      toast({
        title: t('toast.assignment-edited', { ASSIGNMENT: data.name }),
        variant: 'success'
      })
      props.onClose?.()
    }
  })

  const { mutate: removeAssignment, isPending: isRemoveAssignmentLoading } =
    useRemoveAssignment({
      onSuccess: () => {
        cancelModal()
        toast({
          title: t('toast.assignment-removed', {
            ASSIGNMENT: assignment?.name
          }),
          variant: 'success'
        })
        props.onClose?.()
      }
    })

  const { data: studentOptions, isLoading: isStudentsLoading } =
    useStudentOptions({
      groupId: props.groupId
    })

  const cancelModal = () => {
    form.reset()
    props.onOpenChange?.(false)
  }

  const isDateInsideGroupSemesters = (date: Date) =>
    props.groupSemesters
      .map(semester =>
        isWithinInterval(date, {
          start: semester.startDate,
          end: semester.endDate
        })
      )
      .some(Boolean)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchemaAddAssignment(isDateInsideGroupSemesters)),
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      dueDate: undefined,
      isGraded: false,
      sendToStudents: false,
      studentIds: []
    }
  })

  const [submittedFormData, setSubmittedFormData] = useState<FormSchema>()

  const { isDirty } = form.formState

  useEffect(() => {
    if (!!assignment) {
      form.reset({
        name: assignment?.name,
        description: assignment?.description,
        dueDate: assignment?.dueDate,
        isGraded: assignment?.isGraded,
        sendToStudents: assignment?.sendToStudents,
        studentIds: assignment?.students.map(student => student.studentId)
      })
    }
  }, [assignment, form])

  const formErrors = formErrorsAddAssignment
    ? formErrorsAddAssignment
    : formErrorsEditAssignment
      ? formErrorsEditAssignment
      : []

  useExternalErrors(formErrors, form)

  const isUncheckedAssignGrades = (data?: FormSchema) =>
    !data?.isGraded && !!form.formState.defaultValues?.isGraded

  const wasStudentUnchecked = (data?: FormSchema) =>
    !!form.formState.defaultValues?.studentIds &&
    form.formState.defaultValues.studentIds.some(
      student => student && !data?.studentIds.includes(student)
    )

  const handleSubmit = () => {
    const data = form.getValues()
    setSubmittedFormData(data)

    if (props.mode === 'add') {
      const payload = {
        ...data,
        group: props.groupId
      }
      addAssignment(payload)
    } else {
      const payload = {
        ...data,
        id: props.id,
        group: props.groupId
      }

      if (isUncheckedAssignGrades(data) || wasStudentUnchecked(data)) {
        setIsOpenEditConfirmModal(true)
      } else {
        editAssignment(payload)
      }
    }
  }

  const handleOnCancel = () => {
    isDirty ? setIsOpenConfirmModal(true) : cancelModal()
  }

  const handleRemove = () => {
    if (props.mode === 'edit') {
      setIsOpenRemoveConfirmModal(true)
    }
  }

  const handleOnConfirmRemove = () => {
    if (props.mode === 'edit') removeAssignment({ id: assignment?.id || '' })
    setIsOpenRemoveConfirmModal(false)
  }

  const handleOnConfirmEdit = () => {
    const data = form.getValues()

    if (props.mode === 'edit') {
      const payload = {
        ...data,
        id: props.id,
        group: props.groupId
      }
      editAssignment(payload)
      setIsOpenEditConfirmModal(false)
    }
  }

  const shouldDateBeDisabledInAssignmntDeadline = (date: Date) =>
    !isDateInsideGroupSemesters(date)

  return (
    <>
      <ModalForm
        id="add-assignment"
        title={
          props.mode === 'add'
            ? t('header.add-assignment')
            : t('header.edit-assignment')
        }
        form={form}
        open={props.open}
        onOpenChange={props.onOpenChange}
        loading={
          isStudentsLoading ||
          isAddAssignmentLoading ||
          isEditAssignmentLoading ||
          isAssignmentLoading ||
          isRemoveAssignmentLoading
        }
        submitText={t('button.save')}
        withRemoveButton={props.mode === 'edit'}
        trigger={props.trigger}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        onCancel={handleOnCancel}
        onClose={handleOnCancel}
      >
        <h3 className={styles.assignmentDetails}>
          {t('header.assignment-details')}
        </h3>

        <FormField
          control={form.control}
          id="name"
          required
          label={t('label.assignment-name')}
          name="name"
          render={({ inputProps }) => (
            <Input
              placeholder={t('help.type-assignment-name')}
              {...inputProps}
            />
          )}
        />
        <div className={styles.checkboxWrapper}>
          <FormField
            control={form.control}
            id="assign-grades"
            required
            name="isGraded"
            render={({ inputProps }) => (
              <Checkbox
                tooltip={t('help.column-will-be-created')}
                label={t('label.assign-grade')}
                {...inputProps}
              />
            )}
          />
          <FormField
            control={form.control}
            id="send-to-students"
            required
            name="sendToStudents"
            render={({ inputProps }) => (
              <Checkbox
                label={t('label.send-to-students')}
                tooltip={t('help.assignment-will-be-in-app')}
                {...inputProps}
              />
            )}
          />
        </div>

        <FormField
          control={form.control}
          id="due-date"
          label={t('label.deadline')}
          name="dueDate"
          required
          render={({ inputProps }) => (
            <DatePicker
              disabledDate={shouldDateBeDisabledInAssignmntDeadline}
              onClear={() => form.resetField('dueDate')}
              className={styles.deadline}
              defaultValue={assignment?.dueDate}
              markedDate={isLessonInDate}
              {...inputProps}
            />
          )}
        />
        <FormField
          control={form.control}
          id="description"
          label={t('label.assignment-description')}
          name="description"
          render={({ inputProps }) => (
            <Textarea
              placeholder={t('help.type-assignment-description')}
              numberOfLines={3}
              maxLength={500}
              {...inputProps}
            />
          )}
        />

        <Divider />

        <FormField
          control={form.control}
          id="student-ids"
          required
          name="studentIds"
          label={t('label.enroll-students')}
          hideLabel
          render={({ inputProps }) => (
            <CheckboxGroup
              options={studentOptions || []}
              label={t('label.enroll-students')}
              {...inputProps}
            />
          )}
        />
      </ModalForm>

      <ConfirmModal
        id="confirm-edit-modal"
        header={
          isUncheckedAssignGrades(submittedFormData)
            ? t('notification.save-assignment')
            : t('notification.disenroll-students')
        }
        subheader={
          <SubheaderEditConfirmModal
            isUncheckedAssignGrades={isUncheckedAssignGrades(submittedFormData)}
          />
        }
        onConfirm={handleOnConfirmEdit}
        open={isOpenEditConfirmModal}
        onOpenChange={setIsOpenEditConfirmModal}
      />

      <ConfirmModal
        id="confirm-remove-modal"
        header={t('notification.remove-assignment')}
        subheader={
          <RemoveAssignmentSubheader
            isGradedAssignment={!!assignment?.isGraded}
          />
        }
        confirmButton={
          <Button variant="danger" onClick={handleOnConfirmRemove}>
            {t('button.remove')}
          </Button>
        }
        open={isOpenRemoveConfirmModal}
        onOpenChange={setIsOpenRemoveConfirmModal}
      />

      <DirtyModal
        isOpen={isOpenConfirmModal}
        onOpenChange={setIsOpenConfirmModal}
        onConfirm={() => {
          cancelModal()
          setIsOpenConfirmModal(false)
        }}
      />
    </>
  )
}

const SubheaderEditConfirmModal = (props: {
  isUncheckedAssignGrades: boolean
}) => {
  const { t } = useTranslation(['lessonDetails'])
  return (
    <div>
      <p className={styles.text}>
        {props.isUncheckedAssignGrades
          ? t('help.column-will-be-removed') + ' ' + t('help.all-will-be-lost')
          : t('help.grade-will-be-deleted')}
      </p>
    </div>
  )
}

export default AssignmentModal
