import clsx from 'clsx'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer as DrawerPrimitive } from 'vaul'

import CloseIcon from '@/assets/icons/close.svg?react'

import styles from './Drawer.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Divider from '../Divider/Divider'

type DrawerProps = {
  direction?: 'right' | 'bottom'
  title?: React.ReactNode
  trigger: React.ReactNode
  children: React.ReactNode
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

const Drawer = (props: DrawerProps) => {
  const { t } = useTranslation('common')
  const { direction = 'bottom' } = props

  const isSideDrawer = direction === 'right'

  return (
    <DrawerPrimitive.Root
      direction={direction}
      open={props.open}
      onOpenChange={value => {
        props.onOpenChange?.(value)
      }}
    >
      <DrawerPrimitive.Trigger asChild>{props.trigger}</DrawerPrimitive.Trigger>
      <DrawerPrimitive.Portal>
        <DrawerPrimitive.Overlay className={styles.overlay} />
        <DrawerPrimitive.Content
          className={clsx(styles.content, styles[direction])}
        >
          {isSideDrawer && props.title ? (
            <>
              <div className={styles.titleWrapper}>
                <span className={styles.title}>{props.title}</span>
              </div>

              <Divider className={styles.divider} />
            </>
          ) : null}

          <div className={styles.container}>{props.children}</div>

          {isSideDrawer ? (
            <DrawerPrimitive.Close asChild>
              <ButtonIcon
                className={styles.closeIcon}
                size="small"
                variant="tertiary"
                ariaLabel={t('button.close')}
              >
                <CloseIcon />
              </ButtonIcon>
            </DrawerPrimitive.Close>
          ) : null}
        </DrawerPrimitive.Content>
        <DrawerPrimitive.Overlay />
      </DrawerPrimitive.Portal>
    </DrawerPrimitive.Root>
  )
}
export default Drawer
