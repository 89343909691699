import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import Select from '@/components/Select/Select'
import { noneOption } from '@/constants/none-option'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import useMarksList from '@/queries/useMarksList'

import styles from './GradesInAssignmentModal.module.scss'
import {
  type AddGradesInAssignmentPayload,
  formSchemaAddGradesInAssignment
} from '../constants/assignment-payload'
import useAddGradesInAssignments from '../mutations/useAddGradesInAssignment'
import useAssignment from '../queries/useAssignment'

type GradesInAssignmentModalProps = {
  id: string
  groupId: string
  trigger?: React.ReactNode
  onClose?: () => void
}

const GradesInAssignmentModal = (props: GradesInAssignmentModalProps) => {
  const { t } = useTranslation(['lessonDetails'])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: assignment, isFetching: isAssignmentLoading } = useAssignment({
    id: props.id,
    enabled: !!props.id && isModalOpen
  })

  const { isFetching: isMarksFetching, data: marks } = useMarksList()

  const {
    mutate: addGrades,
    isPending: isGradesAdding,
    formErrors
  } = useAddGradesInAssignments({
    onSuccess: () => {
      toast({
        title: t('toast.assignment-grades-edited'),
        variant: 'success'
      })
      props.onClose?.()
      setIsModalOpen(false)
    }
  })

  const form = useForm<AddGradesInAssignmentPayload>({
    resolver: zodResolver(formSchemaAddGradesInAssignment()),
    mode: 'all',
    defaultValues: {
      items: [{ id: '', markId: undefined }]
    }
  })

  useExternalErrors(formErrors, form)

  const { fields } = useFieldArray({
    control: form.control,
    name: 'items'
  })

  const getStudentName = (studentId?: string) =>
    assignment?.students.find(student => student.studentId === studentId)?.name

  const selectOptions = [noneOption(), ...(marks?.marksOptions || [])]

  useEffect(() => {
    if (!!assignment) {
      form.reset({
        items: assignment?.students.map(student => ({
          studentId: student.studentId,
          id: student.grade?.id,
          markId: student.grade?.markId || undefined
        }))
      })
    }
  }, [assignment, form])

  const handleSubmit = (payload: AddGradesInAssignmentPayload) => {
    addGrades(payload)
  }

  const isModalLoading =
    isAssignmentLoading || isMarksFetching || isGradesAdding

  const handleClose = () => {
    form.reset()
    setIsModalOpen(false)
  }

  return (
    <ModalForm
      id="add-grades-in-assignment"
      title={t('header.add-grades-for-assignment', {
        ASSIGNMENT: assignment?.name
      })}
      form={form}
      open={isModalOpen}
      onOpenChange={setIsModalOpen}
      loading={isModalLoading}
      submitText={t('button.save')}
      trigger={props.trigger}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      onClose={handleClose}
    >
      {fields.map((field, index) => (
        <div
          key={field.id}
          data-test-id="grade-for-student"
          className={styles.studentContainer}
        >
          <h3 className={styles.student} data-test-id="student-name">
            {getStudentName(field.studentId)}
          </h3>

          <FormField
            control={form.control}
            label={t('label.grade')}
            id={`items.${index}.markId`}
            name={`items.${index}.markId`}
            render={({ inputProps }) => (
              <Select
                {...inputProps}
                placeholder={t('help.select-grade')}
                mountedInBody
                options={selectOptions}
                onChange={value => {
                  form.setValue(
                    `items.${index}.markId`,
                    value !== 'none' ? value : ''
                  )
                }}
              />
            )}
          />
        </div>
      ))}
    </ModalForm>
  )
}

export default GradesInAssignmentModal
