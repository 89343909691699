import { z } from 'zod'

import { requiredString } from '@/utils/zod'

export const studentCoursesFiltersSchema = z.object({
  course: z.array(z.string()).optional(),
  teacherOrCoTeacherId: z.array(z.string()).optional(),
  semester: requiredString().catch('')
})

export type StudentCoursesFiltersKey = keyof z.infer<
  typeof studentCoursesFiltersSchema
>
