import React from 'react'
import { useTranslation } from 'react-i18next'

import { type Route } from '@/components/Tabs/Tabs'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'

const CoveredAndCancelledLessonsView = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { t } = useTranslation('common')

  const routes: Route[] = [
    {
      title: t('navigation.covered-lessons'),
      link: {
        to: '/covered-and-cancelled-lessons/covered'
      }
    },
    {
      title: t('navigation.cancelled-lessons'),
      link: {
        to: '/covered-and-cancelled-lessons/cancelled'
      }
    }
  ]

  return (
    <BasicLayout
      routes={routes}
      header={t('navigation.covered-cancelled-lessons')}
      moduleName={t('header.academics')}
    >
      {children}
    </BasicLayout>
  )
}

export default CoveredAndCancelledLessonsView
