import * as Label from '@radix-ui/react-label'
import * as RadixSwitch from '@radix-ui/react-switch'
import clsx from 'clsx'

import styles from './Switch.module.scss'
import type { FormFieldType } from '../../types/form-field-type'

type SwitchProps = {
  dataTestId?: string
  disabled?: boolean
  variant?: 'default' | 'header'
} & FormFieldType<boolean> &
  ({ label: string; ariaLabel?: never } | { ariaLabel: string; label?: never })

const Switch = (props: SwitchProps) => {
  const { variant = 'default' } = props

  return (
    <div
      className={clsx(
        styles.wrapper,
        props.disabled && styles.wrapperDisabled,
        props.value && styles.wrapperChecked,
        variant === 'header' && styles.wrapperHeader
      )}
      data-test-id={props.dataTestId}
    >
      <RadixSwitch.Root
        id={props.id}
        checked={props.value}
        disabled={props.disabled}
        className={styles.switch}
        onCheckedChange={value => props.onChange?.(value)}
        aria-label={props.ariaLabel}
        aria-describedby={props.describedby}
        aria-invalid={props.invalid}
        required={props.required}
        aria-disabled={props.disabled}
      >
        <RadixSwitch.Thumb className={styles.indicator} />
      </RadixSwitch.Root>

      {props.label ? (
        <Label.Root className={styles.label} htmlFor={props.id}>
          {props.label}
        </Label.Root>
      ) : null}
    </div>
  )
}
export default Switch
