import { z } from 'zod'

import i18n from '@/i18n'
import { requiredString, maxLength, requiredStringWithLabel } from '@/utils/zod'

export const formSchemaAddAssignment = (isDateValid: (date: Date) => boolean) =>
  z.object({
    name: requiredStringWithLabel(
      i18n.t('label.assignment-name', { ns: 'lessonDetails' })
    ),
    description: maxLength(500),
    dueDate: z
      .date({
        required_error: i18n.t('error.required-field-with-label', {
          ns: 'common',
          LABEL: i18n.t('label.deadline', { ns: 'lessonDetails' })
        })
      })
      .refine(value => value && isDateValid(value), {
        message: i18n.t('error.due-date-has-to-be-inside-semester', {
          ns: 'lessonDetails'
        })
      }),
    isGraded: z.boolean(),
    sendToStudents: z.boolean(),
    studentIds: z.array(z.string()).min(1, {
      message: i18n.t('error.one-student-is-required', {
        ns: 'lessonDetails'
      })
    })
  })

export type AddAssignmentPayload = z.infer<
  ReturnType<typeof formSchemaAddAssignment>
> & {
  group: string
}

export type EditAssignmentPayload = AddAssignmentPayload & {
  id: string
}

export const formSchemaRemoveAssignment = () =>
  z.object({
    id: requiredString()
  })

export type RemoveAssignmentPayload = z.infer<
  ReturnType<typeof formSchemaRemoveAssignment>
>

export const formSchemaAddGradesInAssignment = () =>
  z.object({
    items: z.array(
      z.object({
        id: requiredString(),
        studentId: requiredString(),
        markId: z.string().optional()
      })
    )
  })

export type AddGradesInAssignmentPayload = z.infer<
  ReturnType<typeof formSchemaAddGradesInAssignment>
>
