import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

export type BulkCoverAndCancelLeson = {
  id: string
  teacher_cover_id?: string | null
  co_teacher_1_cover_id?: string | null
  is_cancelled?: boolean
}

type BulkCoverAndCancelData = {
  lessons: BulkCoverAndCancelLeson[]
  comment?: string
}

const useBulkCoverAndCancel = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: BulkCoverAndCancelData) =>
      httpService.panel.panelLessonsBulkCoverAndCancelCreate(data),
    onSuccess: options?.onSuccess
  })

export default useBulkCoverAndCancel
