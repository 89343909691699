import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useMarkAsPresent = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string }) =>
      httpService.panel.panelAbsencesMarkAsPresentCreate(data.id),
    onSuccess,
    onError
  })

export default useMarkAsPresent
