import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type CoverCancelLesson } from '@/types/lesson'
import { formatApiDate } from '@/utils/format-date'
import { requiredString, date } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    start_date: date(),
    end_date: date(),
    group: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    course: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    comment: z.string(),
    teacher: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    teacher_cover: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    co_teacher_1: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    co_teacher_1_cover: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    is_cancelled: z.boolean(),
    is_covered: z.boolean(),
    can_be_cancelled: z.boolean()
  })
)

const parseResponse = (
  response: z.infer<typeof responseSchema>
): CoverCancelLesson[] =>
  response.map(lesson => ({
    id: lesson.id,
    startDate: lesson.start_date,
    endDate: lesson.end_date,
    group: lesson.group,
    course: lesson.course,
    comment: lesson.comment,
    teacher: lesson.teacher,
    teacherCover: lesson.teacher_cover || undefined,
    coTeacher: lesson.co_teacher_1 || undefined,
    coTeacherCover: lesson.co_teacher_1_cover || undefined,
    isCancelled: lesson.is_cancelled,
    isCovered: lesson.is_covered,
    canBeCancelled: lesson.can_be_cancelled
  }))

type Params = {
  showPastData?: boolean
  teacherId?: string
  dateAfter?: string
  dateBefore?: string
  enabled: boolean
}
const useLessonsAddCoverCancel = (params: Params) =>
  useQuery({
    queryKey: ['panelLessonsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const lessons = await httpService.panel.panelLessonsList({
        page_size: 'max',
        show_past_data: true,
        any_teacher_id: params.teacherId ? [params.teacherId] : undefined,
        date_after: params.dateAfter
          ? formatApiDate(new Date(params.dateAfter))
          : undefined,
        date_before: params.dateBefore
          ? formatApiDate(new Date(params.dateBefore))
          : undefined,
        fetchKeys: {
          id: true,
          start_date: true,
          end_date: true,
          group: true,
          teacher: true,
          co_teacher_1: true,
          teacher_cover: true,
          co_teacher_1_cover: true,
          comment: true,
          course: true,
          is_cancelled: true,
          is_covered: true,
          can_be_cancelled: true
        }
      })

      return {
        list: parseResponse(responseSchema.parse(lessons.results)),
        count: lessons.count
      }
    },
    enabled: params.enabled,
    refetchOnWindowFocus: false
  })

export default useLessonsAddCoverCancel
