import type { QueryClient } from '@tanstack/react-query'
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext
} from '@tanstack/react-router'

import type { AuthStore } from '@/store/useAuthStore'

export const Route = createRootRouteWithContext<{
  authStore: AuthStore | null
  queryClient: QueryClient
}>()({
  component: () => (
    <>
      <ScrollRestoration getKey={location => location.pathname} />
      <Outlet />
    </>
  ),

  pendingComponent: () => <div>Loading...</div>
})
