import { useQuery } from '@tanstack/react-query'
import type { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type SelectOption } from '@/components/Select/Select'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = arrayOfOptions

const parseResponse = (
  response: z.infer<typeof responseSchema>
): SelectOption<string>[] => response

const useAbsencesClassesOptions = () =>
  useQuery({
    queryKey: ['panelAbsencesOptionsClassesList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const classesOptions =
        await httpService.panel.panelAbsencesOptionsClassesList()

      return parseResponse(responseSchema.parse(classesOptions))
    }
  })

export default useAbsencesClassesOptions
