import { type Route } from '@/components/Tabs/Tabs'
import { USER_PROFILE } from '@/constants/user-profile'
import i18n from '@/i18n'
import { type User } from '@/store/useAuthStore'
import { type RouteWithHasPermission } from '@/types/route'

const coursesRoutes = (user?: User): RouteWithHasPermission[] => [
  {
    title: i18n.t('navigation.my-courses', { ns: 'courses' }),
    link: {
      to: '/courses/my-courses'
    },
    hasPermission: () => !!user?.profiles.includes(USER_PROFILE.TEACHER)
  },
  {
    title: i18n.t('navigation.my-class-course', { ns: 'courses' }),
    link: {
      to: '/courses/my-class-courses'
    },
    hasPermission: () => !!user?.profiles.includes(USER_PROFILE.TUTOR)
  },
  {
    title: i18n.t('navigation.all-course', { ns: 'courses' }),
    link: {
      to: '/courses/all-courses'
    },
    hasPermission: () => !!user?.isSuperAdmin
  }
]

export const getRoutesWithPermission = (user?: User): Route[] =>
  coursesRoutes(user)
    .filter(
      route =>
        typeof route.hasPermission === 'function' && route.hasPermission()
    )
    .map(route => ({
      title: route.title,
      link: route.link
    }))
