import clsx from 'clsx'
import { last } from 'lodash'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './TruncateElementsList.module.scss'
import Tag from '../Tag/Tag'

type TruncateElementsListProps = {
  children: React.ReactNode[]
  elipsis: (hiddenItemsCount: number) => React.ReactNode
  className?: string
  expanded?: boolean
  onExpandedChange?: (expanded: boolean) => void
}

const getContentWidth = (element?: Element | null) => {
  if (!element) return 0
  const widthWithPaddings = element.clientWidth
  const elementComputedStyle = window.getComputedStyle(element, null)
  return (
    widthWithPaddings -
    parseFloat(elementComputedStyle.paddingLeft) -
    parseFloat(elementComputedStyle.paddingRight)
  )
}

const getElementsToShow = (
  containerWidth: number,
  elementsWidthList: number[]
) => {
  if (elementsWidthList.length === 1) return 1
  let availableSpace = containerWidth
  for (let i = 1; i < elementsWidthList.length; i++) {
    if (availableSpace - elementsWidthList[i - 1] < elementsWidthList[i]) {
      return i
    }
    availableSpace -= elementsWidthList[i - 1]
  }

  return elementsWidthList.length
}

const TruncateElementsList = (props: TruncateElementsListProps) => {
  const { t } = useTranslation('common')

  const ref = useRef<HTMLDivElement>(null)
  const [elementsToShow, setElemenstsToShow] = useState(props.children.length)

  useLayoutEffect(() => {
    const parentWidth = getContentWidth(ref.current?.parentElement)
    if (!ref.current?.children) return

    const tagWidth = last([...ref.current?.children])?.clientWidth || 0

    const elementsWidths = [...ref.current?.children]
      .slice(0, -1)
      .map(item => item.clientWidth + 1)

    setElemenstsToShow(
      getElementsToShow(parentWidth - tagWidth, elementsWidths)
    )
  }, [elementsToShow])

  return (
    <div ref={ref} className={clsx(props.className, styles.wrapper)}>
      {props.expanded ? (
        <div className={styles.expandedContainer}>
          {props.children}
          <Tag
            color="white"
            variant="with-background"
            className={styles.hideTag}
            label={t('label.hide')}
            onClick={() => {
              props.onExpandedChange?.(false)
            }}
          />
        </div>
      ) : (
        <>
          {props.children.filter((_, index) => index < elementsToShow)}

          <span
            className={
              elementsToShow === props.children.length
                ? styles.displayNone
                : styles.tagDisplay
            }
          >
            {props.elipsis(props.children.length - elementsToShow)}
          </span>
        </>
      )}
    </div>
  )
}

export default TruncateElementsList
