import { getRouteApi } from '@tanstack/react-router'
import { uniqBy } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import UndoIcon from '@/assets/icons/undo.svg?react'
import UpdateIcon from '@/assets/icons/update.svg?react'
import type { TableAction } from '@/components/Table/Table'
import CancelLessonModal from '@/components/common/CancelLessonModal'
import CannotCancelLessonModal from '@/components/common/CannotCancelLessonModal'
import RemoveCoverModal from '@/components/common/RemoveCoverModal'
import { type CoveredOrCancelledLesson } from '@/types/lesson'

import CoveredAndCancelledLessonsList from '../components/CoveredAndCancelledLessonsList'
import { getTeachersColumn } from '../components/TruncateColumn'
import useLessons from '../queries/useLessons'

const routeApi = getRouteApi('/_auth/covered-and-cancelled-lessons/covered')

const CoveredLessonsView = () => {
  const { t } = useTranslation('coveredCancelledLessons')
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const queryParams = { ...search, isCovered: true }
  const { refetch: refetchLessonList } = useLessons(queryParams)

  const [isOpenRemoveCoverLessonModal, setIsOpenRemoveCoverLessonModal] =
    useState(false)
  const [isOpenCanNotCancelLessonModal, setIsOpenCanNotCancelLessonModal] =
    useState(false)
  const [currentLesson, setCurrentLesson] = useState<CoveredOrCancelledLesson>()
  const [isCancelLessonModalOpen, setIsCancelLessonModalOpen] = useState(false)

  const actions: TableAction<CoveredOrCancelledLesson>[] = [
    {
      icon: <UndoIcon />,
      onClick: lesson => {
        setCurrentLesson(lesson)
        setIsOpenRemoveCoverLessonModal(true)
      },
      text: t('button.remove-cover')
    },
    {
      icon: <CancelIcon />,
      onClick: lesson => {
        setCurrentLesson(lesson)

        if (lesson.canBeCancelled) setIsCancelLessonModalOpen(true)
        else setIsOpenCanNotCancelLessonModal(true)
      },
      text: t('button.cancel-lesson'),
      variantAction: 'danger'
    }
  ]

  const coveringTeacherColumn = getTeachersColumn({
    key: 'covering-teacher',
    header: t('header.covering-teacher'),
    getTeachers: ({ teacherCover, coTeacherCover }) =>
      uniqBy([teacherCover, coTeacherCover], 'id')
  })

  const coveredTeacherColumn = getTeachersColumn({
    getTeachers: (lesson: CoveredOrCancelledLesson) =>
      [
        lesson.teacherCover ? lesson.teacher : null,
        lesson.coTeacherCover ? lesson.coTeacher : null
      ].filter(item => !!item),
    header: t('header.covered-teacher'),
    key: 'teacher'
  })

  const handleChange = () => {
    refetchLessonList()
    setCurrentLesson(undefined)
  }

  return (
    <>
      <CoveredAndCancelledLessonsList
        id="covered-lessons-table"
        actions={actions}
        search={queryParams}
        onNavigate={navigate}
        coverSettingAction={{
          icon: <EditIcon />,
          text: t('button.edit-cover')
        }}
        additionalColumns={[coveredTeacherColumn, coveringTeacherColumn]}
        coverCancelButton={{
          text: t('button.cover-lesson'),
          icon: <UpdateIcon />
        }}
      />

      {currentLesson ? (
        <RemoveCoverModal
          open={isOpenRemoveCoverLessonModal}
          onOpenChange={setIsOpenRemoveCoverLessonModal}
          lesson={currentLesson}
          onSuccess={handleChange}
        />
      ) : null}

      {currentLesson ? (
        <CancelLessonModal
          lesson={currentLesson}
          onSuccess={handleChange}
          open={isCancelLessonModalOpen}
          onOpenChange={setIsCancelLessonModalOpen}
        />
      ) : null}

      {currentLesson ? (
        <CannotCancelLessonModal
          lesson={currentLesson}
          open={isOpenCanNotCancelLessonModal}
          onOpenChange={setIsOpenCanNotCancelLessonModal}
        />
      ) : null}
    </>
  )
}

export default CoveredLessonsView
