import { zodResolver } from '@hookform/resolvers/zod'
import { useBlocker, useRouter } from '@tanstack/react-router'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type z } from 'zod'

import { httpService } from '@/api/http.service'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import Checkbox from '@/components/Checkbox/Checkbox'
import DatePicker from '@/components/DatePicker/DatePicker'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Loading from '@/components/Loading'
import Select, { type SelectOption } from '@/components/Select/Select'
import Tag from '@/components/Tag/Tag'
import DirtyModal from '@/components/common/DirtyModal'
import genderOptions from '@/constants/gender-options'
import { USER_PROFILE } from '@/constants/user-profile'
import useClasses from '@/queries/useClasses'
import useCommunities from '@/queries/useCommunities'
import useLanguages from '@/queries/useLanguages'
import useNationalities from '@/queries/useNationalities'
import useRooms from '@/queries/useRooms'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'

import styles from './UserForm.module.scss'
import { type AddUserPayload, userFormSchema } from '../constants/user-payload'
import { getColorByProfile, getLabelByProfile } from '../utils/getProfileTag'

export type UserForm = z.infer<typeof userFormSchema>

type UserFormProps = {
  userStatus?: UserStatus
  defaultValues: UserForm
  loading: boolean
  onSubmit: (payload: AddUserPayload) => void
}
const UserForm = (props: UserFormProps) => {
  const { t } = useTranslation(['users', 'common'])
  const router = useRouter()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const form = useForm<UserForm>({
    resolver: zodResolver(userFormSchema),
    mode: 'onBlur',
    defaultValues: props.defaultValues
  })

  const {
    proceed,
    reset,
    status: blockerStatus
  } = useBlocker({
    condition: form.formState.isDirty && !isFormSubmitted
  })

  const profile = form.watch('profile')

  const onSubmit = (data: UserForm) => {
    // TODO: Send data to backend in https://panowie.atlassian.net/browse/RAPP-1978
    const payload = {
      email: data.email,
      profile: data.profile,
      sendActivationLink: data.sendActivationLink,
      firstName: data.firstName,
      lastName: data.lastName,
      ahvNumber: data.ahvNumber,
      birthDate: data.birthDate,
      gender: data.gender,
      nationalities: data.nationalities,
      firstLanguage: data.firstLanguage,
      community: data.community,
      phoneNumber: data.phoneNumber,
      emergencyPhoneNumber: data.emergencyPhoneNumber,
      tutor: data.profile.includes(USER_PROFILE.TUTOR)
        ? {
            classes: data.classes
          }
        : null,
      teacher: data.profile.includes(USER_PROFILE.TEACHER)
        ? {
            preferred_rooms: data.preferredRooms
          }
        : null
    }
    props.onSubmit(payload)
    setIsFormSubmitted(true)
  }

  // TODO: Replace options array with options from backend in https://panowie.atlassian.net/browse/RAPP-2014
  const userRolesOptions = [
    { label: 'Super Admin', value: 'Admin' as const },
    { label: 'Tutor', value: 'Tutor' as const },
    { label: 'Teacher', value: 'Teacher' as const }
  ]

  const { data: nationalities, isLoading: isNationalitiesLoading } =
    useNationalities()
  const { data: firstLanguages, isLoading: isFirstLanguagesLoading } =
    useLanguages()
  const { data: communities, isLoading: isCommunitiesLoading } =
    useCommunities()
  const { data: rooms, isLoading: isRoomsLoading } = useRooms()
  const { data: classes, isLoading: isClassesLoading } = useClasses()

  const handleCancel = () => {
    router.history.back()
  }

  const TEACHING_PROFILES: UserProfile[] = [
    USER_PROFILE.TUTOR,
    USER_PROFILE.TEACHER
  ]

  const handleEmailBlur = async () => {
    const emailFieldState = form.getFieldState('email')
    if (emailFieldState.invalid || emailFieldState.isValidating) return

    const response = await httpService.panel.panelUsersCheckEmailTakenCreate({
      email: form.getValues('email')
    })
    if (response.is_taken) {
      form.setError('email', { message: t('help.email-taken') })
    }
  }

  return (
    <Loading spinning={props.loading}>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <div className={styles.formContainer}>
            <FormField
              control={form.control}
              id="email"
              label={t('label.email')}
              name="email"
              render={({ inputProps }) => (
                <Input
                  {...inputProps}
                  placeholder={t('placeholder.type-email')}
                  type="email"
                  onBlur={() => {
                    inputProps.onBlur?.()
                    setTimeout(handleEmailBlur, 0)
                  }}
                />
              )}
            />
            <FormField
              control={form.control}
              id="user-profile"
              required
              label={t('label.user-role')}
              name="profile"
              render={({ inputProps }) => (
                <Select
                  {...inputProps}
                  placeholder={t('placeholder.select-user-role')}
                  multiple
                  id="user-role"
                  options={userRolesOptions}
                  formatOption={option => (
                    <Tag
                      variant="with-background"
                      label={getLabelByProfile(option.value)}
                      color={getColorByProfile(option.value)}
                    />
                  )}
                  selectValue={(option: SelectOption<string>) => option.label}
                />
              )}
            />
            <div className={styles.activationLink}>
              <FormField
                control={form.control}
                id="send-activation-link"
                required
                name="sendActivationLink"
                render={({ inputProps }) => (
                  <Checkbox
                    {...inputProps}
                    disabled={props.userStatus === 'blocked'}
                    label={
                      props.userStatus === 'active'
                        ? t('label.resend-activation-link')
                        : t('label.send-activation-link')
                    }
                  />
                )}
              />
              {!props.userStatus ? (
                <Alert
                  variant="info"
                  withoutBackground
                  message={t('help.activation-link-information')}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.formContainer}>
            <h2 className={styles.sectionHeader}>
              {t('header.basic-information')}
            </h2>
            <FormField
              control={form.control}
              id="first-name"
              required
              label={t('label.first-name')}
              name="firstName"
              render={({ inputProps }) => (
                <Input
                  {...inputProps}
                  placeholder={t('placeholder.type-first-name')}
                />
              )}
            />
            <FormField
              control={form.control}
              id="last-name"
              required
              label={t('label.last-name')}
              name="lastName"
              render={({ inputProps }) => (
                <Input
                  {...inputProps}
                  placeholder={t('placeholder.type-last-name')}
                />
              )}
            />
            <FormField
              control={form.control}
              id="avh-number"
              required
              label={t('label.ahv-number')}
              name="ahvNumber"
              render={({ inputProps }) => (
                <Input
                  {...inputProps}
                  placeholder={t('placeholder.type-ahv-number')}
                />
              )}
            />
            <FormField
              control={form.control}
              id="birth-date"
              label={t('label.birth-date')}
              name="birthDate"
              render={({ inputProps }) => (
                <DatePicker {...inputProps} className={styles.birthDate} />
              )}
            />
            <FormField
              control={form.control}
              id="gender"
              label={t('label.gender')}
              name="gender"
              render={({ inputProps }) => (
                <Select
                  {...inputProps}
                  placeholder={t('placeholder.select-gender')}
                  options={genderOptions}
                />
              )}
            />
            <FormField
              control={form.control}
              id="nationalities"
              label={t('label.nationality')}
              name="nationalities"
              render={({ inputProps }) => (
                <Select
                  {...inputProps}
                  options={nationalities?.options || []}
                  loading={isNationalitiesLoading}
                  multiple
                  placeholder={t('placeholder.select-nationality')}
                />
              )}
            />
            <FormField
              control={form.control}
              id="first-language"
              label={t('label.first-language')}
              name="firstLanguage"
              render={({ inputProps }) => (
                <Select
                  {...inputProps}
                  placeholder={t('placeholder.select-language')}
                  options={firstLanguages?.options || []}
                  loading={isFirstLanguagesLoading}
                />
              )}
            />
            <FormField
              control={form.control}
              id="community"
              label={t('label.community')}
              name="community"
              render={({ inputProps }) => (
                <Select
                  {...inputProps}
                  options={communities?.options || []}
                  loading={isCommunitiesLoading}
                  placeholder={t('placeholder.select-residential-community')}
                />
              )}
            />
          </div>
          <div className={styles.formContainer}>
            <h2 className={styles.sectionHeader}>
              {t('header.contact-details')}
            </h2>
            <FormField
              control={form.control}
              id="phone-number"
              label={t('label.default-contact')}
              name="phoneNumber"
              render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
            />
            <FormField
              control={form.control}
              id="emergency-phone-number"
              label={t('label.emergency-contact')}
              name="emergencyPhoneNumber"
              render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
            />
          </div>

          {profile.some(element => TEACHING_PROFILES.includes(element)) ? (
            <div className={styles.formContainer}>
              <h2 className={styles.sectionHeader}>{t('header.academics')}</h2>
              {profile.includes(USER_PROFILE.TEACHER) ? (
                <FormField
                  control={form.control}
                  id="preferred-rooms"
                  label={t('label.preferred-room')}
                  name="preferredRooms"
                  render={({ inputProps }) => (
                    <Select
                      {...inputProps}
                      options={rooms?.options || []}
                      multiple
                      loading={isRoomsLoading}
                      placeholder={t('placeholder.select-preferred-room')}
                    />
                  )}
                />
              ) : null}

              {profile.includes(USER_PROFILE.TUTOR) ? (
                <FormField
                  control={form.control}
                  id="classes"
                  label={t('label.class')}
                  name="classes"
                  render={({ inputProps }) => (
                    <Select
                      {...inputProps}
                      options={classes?.options || []}
                      multiple
                      loading={isClassesLoading}
                      placeholder={t('placeholder.select-class')}
                    />
                  )}
                />
              ) : null}
            </div>
          ) : null}

          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={handleCancel}>
              {t('button.cancel')}
            </Button>
            <Button type="submit">{t('button.save')}</Button>
          </div>
        </form>
      </FormProvider>

      {blockerStatus === 'blocked' && (
        <DirtyModal isOpen onConfirm={proceed} onCancel={reset} />
      )}
    </Loading>
  )
}

export default UserForm
