import { useTranslation } from 'react-i18next'

import DataBlock from '@/components/DataBlock/DataBlock'
import FileLink from '@/components/FileLink/FileLink'
import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import { downloadFile } from '@/utils/download-file'
import { formatDate } from '@/utils/format-date'

import styles from './AbsenceExpandedRow.module.scss'
import useDownloadAttachment from '../mutations/useDownloadAttachment'
import { type Absence } from '../queries/useAbsences'

type AbsenceExpandedRowProps = {
  absence: Absence
}

const AbsenceExpandedRow = (props: AbsenceExpandedRowProps) => {
  const { t } = useTranslation('absences')

  const { mutate: download } = useDownloadAttachment({
    onSuccess: ({ file, payload }) => {
      downloadFile({ fileData: file, fileName: payload.name })
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <TextWithLabel
            label={t('label.excused-at')}
            text={formatDate(props.absence.excusedAt)}
            variant="horizontal-full-row"
          />
          <TextWithLabel
            label={t('label.excused-by')}
            variant="horizontal-full-row"
            text={props.absence.excusedBy?.name}
          />
        </div>
        <DataBlock
          header={t('label.attachments')}
          noData={t('text.no-attachments-uploaded')}
          infoArray={props.absence.attachment.map(attachment => ({
            value: (
              <TextWithLabel
                text={
                  <FileLink
                    onClick={() => {
                      download({
                        attachmentId: attachment.id,
                        name: attachment.fileName
                      })
                    }}
                  >
                    {attachment.fileName}
                  </FileLink>
                }
                variant="horizontal-full-row"
              />
            )
          }))}
        />
      </div>
      <div>
        <TextWithLabel
          label={t('label.comment')}
          variant="vertical"
          text={props.absence.comment}
        />
      </div>
    </div>
  )
}

export default AbsenceExpandedRow
