import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

export const lessonsFiltersSchema = z.object({
  showPastData: z.boolean().optional(),
  teacherIds: z.array(z.string()).optional(),
  classIds: z.array(z.string()).optional(),
  courseIds: z.array(z.string()).optional(),
  groupIds: z.array(z.string()).optional(),
  dateAfter: z.string().optional(),
  dateBefore: z.string().optional(),
  semesterId: z.string().optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type LessonsFilters = z.infer<typeof lessonsFiltersSchema>
export type LessonsFiltersKey = keyof LessonsFilters
