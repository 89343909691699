import { createFileRoute } from '@tanstack/react-router'

import { UserView, userQueryOptions } from '@/modules/users'

export const Route = createFileRoute('/_auth/users/$userId')({
  component: UserView,
  loader: async ({ context, params }) => {
    const userDetails = await context.queryClient.ensureQueryData(
      userQueryOptions(params.userId)
    )

    return userDetails.name
  },
  meta: ({ loaderData }) => [{ title: loaderData }]
})
