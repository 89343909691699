import { createFileRoute } from '@tanstack/react-router'

import { ClassesView, classesFiltersSchema } from '@/modules/classes'
import { StudentsAndClassesView } from '@/modules/studentsAndClasses'

export const Route = createFileRoute('/_auth/students-and-classes/classes/')({
  validateSearch: search => classesFiltersSchema.parse(search),
  component: () => (
    <StudentsAndClassesView>
      <ClassesView />
    </StudentsAndClassesView>
  )
})
