import * as Popover from '@radix-ui/react-popover'
import { useMask } from '@react-input/mask'
import clsx from 'clsx'
import { isBefore } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarIcon from '@/assets/icons/calendar.svg?react'

import styles from './DatePicker.module.scss'
import { DATE_PLACEHOLDER } from '../../constants/date-format'
import { MAX_DATE } from '../../constants/max-date'
import type { FormFieldType } from '../../types/form-field-type'
import { formatDate } from '../../utils/format-date'
import { parseDate } from '../../utils/parse-date'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Calendar from '../Calendar/Calendar'
import Input from '../Input/Input'

type DatePickerProps = FormFieldType<Date> & {
  disabled?: boolean
  className?: string
  defaultValue?: Date
  onClear?: () => void
  disabledDate?: (date: Date) => boolean
  markedDate?: (date: Date) => boolean
}

const DatePicker = (props: DatePickerProps) => {
  const [dateInputValue, setDateInputValue] = useState<string>(
    formatDate(props.value)
  )

  useEffect(() => {
    if (props.defaultValue) setDateInputValue(formatDate(props.defaultValue))
  }, [props.defaultValue])

  useEffect(() => {
    if (props.value) setDateInputValue(formatDate(props.value))
  }, [props.value])

  const dateInput = useMask({
    mask: DATE_PLACEHOLDER,
    showMask: true,
    replacement: { M: /\d/, D: /\d/, Y: /\d/ }
  })

  const handleOnDateChange = (dateValue?: Date) => {
    setDateInputValue(formatDate(dateValue))
    if (!dateValue) props.onClear?.()
    else {
      props.onChange?.(dateValue)
      dateInput?.current?.focus()
    }
  }

  const setPreviousValidValue = () => {
    setDateInputValue(formatDate(props.value))
    if (!props.value) return

    props.onChange?.(props.value)
    props.onBlur?.(props.value)
  }

  const handleOnInputBlur = (value?: string) => {
    if (!value) {
      setDateInputValue('')
      props.onClear?.()
      props.onBlur?.()
      return
    }

    const date = parseDate(value)

    if (!date || isBefore(MAX_DATE, date)) {
      setPreviousValidValue()
    } else {
      props.onChange?.(date)
      props.onBlur?.(date)
    }
  }

  const { t } = useTranslation('common')

  return (
    <Popover.Root>
      <div className={clsx(styles.inputContainer, props.className)}>
        <Popover.Anchor>
          <Input
            className={styles.input}
            ref={dateInput}
            id={props.id}
            value={dateInputValue}
            onBlur={handleOnInputBlur}
            onChange={setDateInputValue}
            placeholder={DATE_PLACEHOLDER}
            describedby={props.describedby}
            disabled={props.disabled}
            invalid={props.invalid}
            required={props.required}
          />
        </Popover.Anchor>
        <Popover.Trigger asChild>
          <ButtonIcon
            disabled={props.disabled}
            size="small"
            variant="tertiary"
            className={styles.calendarButton}
            ariaLabel={t('button.choose-date')}
          >
            <CalendarIcon />
          </ButtonIcon>
        </Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content className={styles.calendarPopover}>
          <Calendar
            id={props.id}
            disabledDates={props.disabledDate}
            mode="single"
            value={props.value}
            onChange={handleOnDateChange}
            markedDates={props.markedDate}
            initialFocus
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DatePicker
