import { type LinkProps, Link } from '@tanstack/react-router'
import clsx from 'clsx'
import React from 'react'

import type { routeTree } from '@/routeTree.gen'

import styles from './Button.module.scss'
import InlineSpinner from '../InlineSpinner/InlineSpinner'

type ButtonSize = 'small' | 'medium'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'tertiary'
  | 'danger'
  | 'success'

type ButtonElementProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onPointerDown?: () => void
  children: React.ReactNode
  type?: 'submit' | 'button'
  icon?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  ariaChecked?: boolean
  ariaLabel?: string
  ariaControls?: string
  ariaExpanded?: boolean
  role?: 'button' | 'switch'
}

type ButtonOrLinkProps =
  | ({ asLink?: undefined } & ButtonElementProps)
  | ({ asLink: true; icon?: React.ReactNode } & LinkProps<typeof routeTree>)

type CommonProps = {
  variant?: ButtonVariant
  size?: ButtonSize
  block?: boolean
  className?: string
  dataTestId?: string
}

export type ButtonProps = ButtonOrLinkProps & CommonProps

const Button = (
  {
    variant = 'primary',
    size = 'medium',
    block,
    dataTestId,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const className = clsx(
    styles.button,
    block && styles.block,
    styles[variant],
    styles[size],
    props.className
  )

  if (props.asLink) {
    const { asLink: _, icon, ...linkProps } = props
    return (
      <Link
        {...linkProps}
        className={styles.buttonLink}
        data-test-id={dataTestId}
      >
        <div className={className}>
          <span className={styles.buttonText}>
            <>{linkProps.children}</>
          </span>

          {icon ? <span className={styles.icon}>{icon}</span> : null}
        </div>
      </Link>
    )
  }

  const {
    type = 'button',
    disabled,
    icon,
    loading,
    children,
    ariaChecked,
    ariaControls,
    ariaExpanded,
    ariaLabel,
    // rest should be valid button attributes
    // they need to be spread to work with radix tooltip with asChild
    ...buttonProps
  } = props

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={className}
      aria-checked={ariaChecked}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      disabled={disabled || loading}
      data-test-id={dataTestId}
      type={type}
      aria-label={ariaLabel}
    >
      <span className={styles.buttonText}>{children}</span>

      {loading ? (
        <span className={styles.icon}>
          <InlineSpinner />
        </span>
      ) : icon ? (
        <span className={styles.icon}>{icon}</span>
      ) : null}
    </button>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button)
