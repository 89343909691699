import {
  CoveredAndCancelledLessonsView,
  CancelledLessonsView
} from '@/modules/coveredAndCancelledLessons'

import { createCoveredOrCancelledLessonsRoute } from '../covered/route'

export const Route = createCoveredOrCancelledLessonsRoute('cancelled', () => (
  <CoveredAndCancelledLessonsView>
    <CancelledLessonsView />
  </CoveredAndCancelledLessonsView>
))
