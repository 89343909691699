import { createFileRoute, redirect } from '@tanstack/react-router'

import { UserCoursesView, coursesFiltersSchema } from '@/modules/courses'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/users/$userId/courses')({
  validateSearch: search => coursesFiltersSchema.parse(search),
  component: () => <UserCoursesView />,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ deps: { search }, context, params }) => {
    const semesters =
      await context.queryClient.ensureQueryData(semesterQueryOptions)
    if (!search.semester) {
      throw redirect({
        replace: true,
        to: '/users/$userId/courses',
        params,
        search: {
          semester: semesters.currentSemester?.id,
          ...search
        }
      })
    }
  }
})
