import { useEffect, useState } from 'react'

import Navigation from '@/components/common/Navigation/Navigation'
import i18n from '@/i18n'

import styles from './NavigationLayout.module.scss'

const NavigationLayout = ({ children }: { children: React.ReactNode }) => {
  const [, setActiveLanguage] = useState(
    window.localStorage.getItem('language')
  )

  useEffect(() => {
    const handleLanguageChange = (lang: string) => {
      setActiveLanguage(lang)
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <Navigation />
      <div className={styles.content}>{children}</div>
    </div>
  )
}
export default NavigationLayout
