import { uniqueId } from 'lodash'
import { useTranslation } from 'react-i18next'

import FileItem from '@/components/FileItem/FileItem'
import FileUpload from '@/components/FileUpload/FileUpload'
import Label from '@/components/Label'
import useUploadFile from '@/mutations/useUploadFile'
import type {
  ErrorFileParams,
  FilesData,
  UpdateFileParams,
  UploadFileParams
} from '@/types/file-upload'

import styles from './FileUploader.module.scss'

type AttachmentType = 'absence'

type FileUploaderProps = {
  disabled?: boolean
  maxSize: number
  attachmentType: AttachmentType
  label: React.ReactNode
  id: string
  filesData: FilesData
  onChange?: (attachmentsId: string[]) => void
  updateProgress: (params: UpdateFileParams) => void
  setFileUploaded: (params: UploadFileParams) => void
  removeFile: (fileId: string) => void
  setFileError: (params: ErrorFileParams) => void
}

const FileUploader = (props: FileUploaderProps) => {
  const { t } = useTranslation('common')

  const getFileUploadError = (errorCode?: string) => {
    switch (errorCode) {
      case 'file-too-large':
        return t('error.file-too-large')
      case 'file-invalid-type':
        return t('error.file-invalid-type')
      default:
        return t('error.upload-failed')
    }
  }
  const { mutate } = useUploadFile({
    onError: variables => {
      props.setFileError({
        id: variables.id,
        fileName: variables.file.name,
        fileSize: variables.file.size,
        error: getFileUploadError()
      })
    },
    onSuccess: (data, variables) => {
      if (!data) return
      props.setFileUploaded({
        id: variables.id,
        fileName: variables.file.name,
        fileSize: variables.file.size,
        backendId: data.id
      })
    }
  })

  return (
    <div className={styles.container}>
      <div>
        {props.label ? <Label id={props.id} label={props.label} /> : null}
        <FileUpload
          id={props.id}
          disabled={props.disabled}
          maxSize={props.maxSize}
          onFileAccept={file => {
            const id = uniqueId()
            mutate({
              id,
              file,
              attachmentType: props.attachmentType,
              onProgressChange: (progress: number) =>
                props.updateProgress({
                  id,
                  fileName: file.name,
                  fileSize: file.size,
                  progress
                })
            })
          }}
          onFileRejected={(file, errorCodes) =>
            props.setFileError({
              id: uniqueId(),
              fileName: file.name,
              fileSize: file.size,
              error: getFileUploadError(errorCodes[0])
            })
          }
        />
      </div>
      <ul>
        {Object.entries(props.filesData).map(([fileId, fileData]) => (
          <FileItem
            key={fileId}
            onDelete={() => props.removeFile(fileId)}
            fileName={fileData.fileName}
            fileSize={fileData.fileSize}
            fileState={fileData.fileState}
          />
        ))}
      </ul>
    </div>
  )
}

export default FileUploader
