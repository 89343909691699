import { useQuery } from '@tanstack/react-query'
import { compareAsc, isFuture } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString, date } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    start_date: date(),
    end_date: date(),
    group_order: z.number(),
    is_covered: z.boolean(),
    is_cancelled: z.boolean()
  })
)

export type Lesson = {
  id: string
  startDate: Date
  endDate: Date
  groupOrder: number
  isCovered: boolean
  isCancelled: boolean
}

const parseResponse = (response: z.infer<typeof responseSchema>): Lesson[] =>
  response.map(lesson => ({
    id: lesson.id,
    startDate: lesson.start_date,
    endDate: lesson.end_date,
    groupOrder: lesson.group_order,
    isCovered: lesson.is_covered,
    isCancelled: lesson.is_cancelled
  }))

const findClosestLesson = (sortedLessons: Lesson[]) => {
  for (const lesson of sortedLessons) {
    if (isFuture(lesson.endDate)) return lesson
  }
  return sortedLessons[sortedLessons.length - 1]
}

export const lessonListQueryOptions = (groupId: string) => ({
  queryKey: ['panelLessonsList', groupId],
  staleTime: 60 * 100,
  queryFn: async () => {
    const lessonsResponse = await httpService.panel.panelLessonsList({
      group_id: [groupId],
      page_size: 'max',
      fetchKeys: {
        id: true,
        start_date: true,
        end_date: true,
        group_order: true,
        is_covered: true,
        is_cancelled: true
      }
    })

    const sortedLessons = parseResponse(
      responseSchema.parse(lessonsResponse.results)
    ).sort((a, b) => compareAsc(a.startDate, b.startDate))

    return {
      list: sortedLessons,
      closestLesson: findClosestLesson(sortedLessons)
    }
  }
})

const useLessons = (groupId: string) =>
  useQuery(lessonListQueryOptions(groupId))

export default useLessons
