import { useTranslation } from 'react-i18next'

import { type DetailedTeacherAvailability } from '@/types/lesson'

import styles from './TeacherAvailabilityText.module.scss'

type TeacherAvailabilityTextProps = {
  teacherAvailability?: DetailedTeacherAvailability
}

const TeacherAvailabilityText = (props: TeacherAvailabilityTextProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      {props.teacherAvailability?.status === 'unavailable' ? (
        <span>
          {t('help.teacher-is-unavailable', {
            TEACHER: props.teacherAvailability.name
          })}
        </span>
      ) : null}

      {props.teacherAvailability?.status === 'busy'
        ? props.teacherAvailability.lessons.map((lesson, index) => (
            <span key={index}>
              <span className={styles.text}>
                {`${lesson.course.name}, ${lesson.group.name}: `}
              </span>

              {t('help.teacher-is-busy', {
                TEACHER: props.teacherAvailability?.name
              })}
            </span>
          ))
        : null}
    </>
  )
}

export default TeacherAvailabilityText
