import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  classId: string
  semester: string
}

const useCoursesOptions = (params: Params) =>
  useQuery({
    queryKey: ['panelClassesGroupsOptionsCoursesList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelClassesGroupsOptionsCoursesList(
          params.classId,
          params.semester,
          {
            page_size: 'max'
          }
        )

      return arrayOfOptions.parse(response.results)
    },
    enabled: !!params.semester
  })

export default useCoursesOptions
