import { createFileRoute, redirect } from '@tanstack/react-router'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

export const Route = createFileRoute('/_auth/covered-and-cancelled-lessons/')({
  beforeLoad: () => {
    throw redirect({
      replace: true,
      to: '/covered-and-cancelled-lessons/covered',
      search: { pageSize: DEFAULT_PAGE_SIZE, page: 1 }
    })
  }
})
