import { useQueries } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type {
  CoverCancelLesson,
  DetailedTeacherAvailability
} from '@/types/lesson'
import { requiredString, teacherStatus } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  status: teacherStatus,
  lessons: z.array(
    z.object({
      id: requiredString(),
      course: z.object({
        id: requiredString(),
        name: requiredString()
      }),
      group: z.object({
        id: requiredString(),
        name: requiredString()
      })
    })
  )
})

const parseResponse = (
  response: z.infer<typeof responseSchema>
): DetailedTeacherAvailability => ({
  id: response.id,
  name: response.name,
  status: response.status,
  lessons: response.lessons
})

export const useTeacherAvailabilityQueries = (lessons: CoverCancelLesson[]) =>
  useQueries({
    queries: lessons.map(lesson => ({
      queryKey: ['panelTeachersAvailabilityRetrieve', lesson],
      staleTime: 60 * 100,
      queryFn: async () => {
        if (!lesson.teacherCover) return
        const response =
          await httpService.panel.panelTeachersAvailabilityRetrieve(
            formatISO(lesson.endDate),
            formatISO(lesson.startDate),
            lesson.teacherCover.id,
            {
              ignore_lesson_id: [lesson.id]
            }
          )

        return parseResponse(responseSchema.parse(response))
      },
      enabled: !!lesson.teacherCover && lesson.isCovered
    }))
  })

export const useCoTeacherAvailabilityQueries = (lessons: CoverCancelLesson[]) =>
  useQueries({
    queries: lessons.map(lesson => ({
      queryKey: ['panelCoTeachersAvailabilityRetrieve', lesson],
      staleTime: 60 * 100,
      queryFn: async () => {
        if (!lesson.coTeacherCover) return

        const response =
          await httpService.panel.panelTeachersAvailabilityRetrieve(
            formatISO(lesson.endDate),
            formatISO(lesson.startDate),
            lesson.coTeacherCover.id,
            {
              ignore_lesson_id: [lesson.id]
            }
          )

        return parseResponse(responseSchema.parse(response))
      },
      enabled: !!lesson.coTeacherCover && lesson.isCovered
    }))
  })
