import { createFileRoute, redirect } from '@tanstack/react-router'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

export const Route = createFileRoute('/_auth/students-and-classes/')({
  beforeLoad: () => {
    throw redirect({
      replace: true,
      to: '/students-and-classes/students',
      search: { pageSize: DEFAULT_PAGE_SIZE, page: 1 }
    })
  }
})
